import { FileDto } from '@app/core/api/file';

export const SYSTEM_USER_AVATAR_URL = '/assets/icons/logo.svg';

export const SYSTEM_USER_AVATAR_FILE: FileDto = {
  url: SYSTEM_USER_AVATAR_URL,
  type: 'image/svg',
  id: '',
  blurhash: null,
  width: null,
  height: null,
  sizes: [],
};

export const SYSTEM_USER_NAME = 'Платформа "Цели"';
