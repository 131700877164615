import { Directive } from '@angular/core';
import { AbstractControlDirective, NgControl } from '@angular/forms';
import { Observable } from 'rxjs';

@Directive()
export abstract class FormFieldControl<T> {
  value: T | null = null;

  abstract stateChanges: Observable<void>;

  abstract id: string;

  abstract placeholder?: string;

  abstract ngControl: NgControl | AbstractControlDirective | null;

  abstract focused: boolean;

  abstract empty: boolean;

  abstract shouldLabelFloat: boolean;

  abstract required: boolean;

  abstract disabled: boolean;

  abstract errorState: boolean;

  abstract controlType?: string;

  abstract userAriaDescribedBy?: string;

  public abstract setDescribedByIds(ids: string[]): void;

  public abstract onContainerClick(event: MouseEvent): void;
}
