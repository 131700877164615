import { Platform } from '@angular/cdk/platform';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, Optional, PLATFORM_ID } from '@angular/core';
import {
  MEDIA_BREAKPOINTS_PROVIDER,
  MediaBreakpointsProvider,
} from '@app/core/media-observer/media-breakpoints.provider';
import { distinctUntilChanged, EMPTY, filter, fromEvent, map, Observable, shareReplay, startWith, tap } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class MediaObserverService {
  public readonly width$: Observable<number>;

  public readonly breakpoint$: Observable<string>;

  public readonly isMobile$: Observable<boolean>;

  public get isMobile(): boolean {
    const breakpoint = this.matchWidthWithBreakpoint(window.innerWidth);
    return breakpoint ? this.isBreakpointDown(breakpoint, 'md', false) : false;
  }

  constructor(
    @Inject(MEDIA_BREAKPOINTS_PROVIDER) private readonly mediaBreakpoints: MediaBreakpointsProvider,
    @Optional() @Inject(PLATFORM_ID) private readonly platformId: Platform | null,
    @Inject(DOCUMENT) private readonly document: Document,
  ) {
    this.width$ = this.buildWidthObservable();
    this.breakpoint$ = this.buildBreakpointObservable();
    this.isMobile$ = this.buildIsMobileObservable();

    if (this.platformId && isPlatformBrowser(this.platformId)) {
      this.document.body.style.setProperty('--viewport-height', `${window.innerHeight}px`);

      fromEvent(window, 'resize')
        .pipe(tap(() => this.document.body.style.setProperty('--viewport-height', `${window.innerHeight}px`)))
        .subscribe();
    }
  }

  public isBreakpointDown(current: string, target: string, includeEquals = true): boolean {
    return includeEquals
      ? this.mediaBreakpoints[current] <= this.mediaBreakpoints[target]
      : this.mediaBreakpoints[current] < this.mediaBreakpoints[target];
  }

  public isBreakpointUp(current: string, target: string, includeEquals = true): boolean {
    return includeEquals
      ? this.mediaBreakpoints[current] >= this.mediaBreakpoints[target]
      : this.mediaBreakpoints[current] > this.mediaBreakpoints[target];
  }

  public isCurrentBreakpointDown(breakpoint: string, includeEquals = true): boolean {
    const current = this.matchWidthWithBreakpoint(window.innerWidth);
    return !!current && this.isBreakpointDown(current, breakpoint, includeEquals);
  }

  public isCurrentBreakpointUp(breakpoint: string, includeEquals = true): boolean {
    const current = this.matchWidthWithBreakpoint(window.innerWidth);
    return !!current && this.isBreakpointUp(current, breakpoint, includeEquals);
  }

  public isBreakpointEqual(current: string, target: string): boolean {
    return this.mediaBreakpoints[current] === this.mediaBreakpoints[target];
  }

  public matchWidthWithBreakpoint(width: number): string | null {
    let lastBreakpoint = Object.keys(this.mediaBreakpoints)[0];

    if (!lastBreakpoint) {
      return null;
    }

    for (const breakpoint in this.mediaBreakpoints) {
      const maxWidth = this.mediaBreakpoints[breakpoint];

      if (width > maxWidth) {
        lastBreakpoint = breakpoint;
      } else {
        return lastBreakpoint;
      }
    }

    return lastBreakpoint;
  }

  protected buildWidthObservable(): Observable<number> {
    if (this.platformId && isPlatformBrowser(this.platformId)) {
      return fromEvent(window, 'resize').pipe(
        startWith(window),
        map(() => window as Window),
        map((w) => w.innerWidth),
        shareReplay(),
      );
    } else {
      return EMPTY;
    }
  }

  protected buildBreakpointObservable(): Observable<string> {
    return this.width$.pipe(
      map((width) => this.matchWidthWithBreakpoint(width)),
      filter(Boolean),
      distinctUntilChanged(),
      shareReplay(),
    );
  }

  protected buildIsMobileObservable(): Observable<boolean> {
    return this.breakpoint$.pipe(
      map((breakpoint) => this.isBreakpointDown(breakpoint, 'md', false)),
      distinctUntilChanged(),
      shareReplay(),
    );
  }
}
