import { APP_INITIALIZER, ModuleWithProviders, NgModule } from '@angular/core';

import { NavigationBackDirective } from './navigation-back.directive';
import { NavigationHistoryService } from './navigation-history.service';

@NgModule({
  declarations: [NavigationBackDirective],
  exports: [NavigationBackDirective],
})
export class NavigationModule {
  public static forRoot(): ModuleWithProviders<NavigationModule> {
    return {
      ngModule: NavigationModule,
      providers: [
        NavigationHistoryService,
        {
          provide: APP_INITIALIZER,
          useFactory: () => () => {},
          deps: [NavigationHistoryService],
          multi: true,
        },
      ],
    };
  }
}
