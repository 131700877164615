import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { SupportFeedbackRequestDto } from './dtos';

@Injectable({ providedIn: 'root' })
export class SupportApiService {
  constructor(private readonly http: HttpClient) {}

  public sendFeedback(request: SupportFeedbackRequestDto): Observable<unknown> {
    const formData = new FormData();

    Object.entries(request).forEach(([name, value]) => {
      if (value) {
        if (Array.isArray(value)) {
          value.forEach((item) => formData.append(name, item));
        } else {
          formData.append(name, value);
        }
      }
    });

    return this.http.post('/api/support/feedback', formData);
  }
}
