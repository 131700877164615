import { AriaLivePoliteness } from '@angular/cdk/a11y';
import { Direction } from '@angular/cdk/bidi';
import { InjectionToken, ViewContainerRef } from '@angular/core';

export const SNACK_BAR_DATA = new InjectionToken<SnackBarConfig<unknown>>('SnackBarData');

export type SnackBarHorizontalPosition = 'start' | 'center' | 'end' | 'left' | 'right';

export type SnackBarVerticalPosition = 'top' | 'bottom';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export class SnackBarConfig<D = any> {
  politeness?: AriaLivePoliteness = 'assertive';

  announcementMessage?: string = '';

  viewContainerRef?: ViewContainerRef;

  duration?: number = 0;

  panelClass?: string | string[];

  direction?: Direction;

  data?: D | null = null;

  horizontalPosition?: SnackBarHorizontalPosition = 'center';

  verticalPosition?: SnackBarVerticalPosition = 'bottom';
}
