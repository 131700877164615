import { CommonModule } from '@angular/common';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { ButtonsModule } from '@app/shared/components/buttons/buttons.module';
import { SnackBarModule } from '@app/shared/components/snack-bar';
import { SvgIconComponent } from 'angular-svg-icon';

import { UpdaterComponent } from './updater.component';
import { UpdaterService } from './updater.service';

@NgModule({
  declarations: [UpdaterComponent],
  imports: [CommonModule, SnackBarModule, ButtonsModule, SvgIconComponent],
  providers: [
    UpdaterService,
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [UpdaterService],
      multi: true,
    },
  ],
})
export class UpdaterModule {}
