import { Injectable } from '@angular/core';
import { AppNotificationDto, NotificationsApiService } from '@app/core/api/notifications';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { catchError, EMPTY, Observable, tap } from 'rxjs';

import { NotificationsStateActions } from './notifications.state.actions';

interface NotificationsStateModel {
  unread: AppNotificationDto[];
  unreadCount: number;
  initialized: boolean;
}

const EMPTY_STATE: NotificationsStateModel = {
  unread: [],
  unreadCount: 0,
  initialized: false,
};

@State({
  name: 'notifiations',
  defaults: { ...EMPTY_STATE },
})
@Injectable()
export class NotificationsState {
  @Selector()
  public static unread(state: NotificationsStateModel): AppNotificationDto[] {
    return state.unread;
  }

  @Selector()
  public static unreadCount(state: NotificationsStateModel): number {
    return state.unreadCount;
  }

  @Selector()
  public static initialized(state: NotificationsStateModel): boolean {
    return state.initialized;
  }

  constructor(private readonly notificationsApi: NotificationsApiService) {}

  @Action(NotificationsStateActions.LoadUnread, { cancelUncompleted: true })
  public loadUnread({ patchState }: StateContext<NotificationsStateModel>): Observable<unknown> {
    return this.notificationsApi.find({ isSeen: false }).pipe(
      tap((response) =>
        patchState({
          unread: response.data.rows,
          unreadCount: response.data.total,
          initialized: true,
        }),
      ),
      catchError(() => EMPTY),
    );
  }

  @Action(NotificationsStateActions.MarkAsRead)
  public markAsRead(
    { patchState, getState }: StateContext<NotificationsStateModel>,
    { notificationIds }: NotificationsStateActions.MarkAsRead,
  ): void {
    const state = getState();
    const existedNotificationIds = new Set<string>();

    for (const notification of state.unread) {
      if (notificationIds.includes(notification.notificationId) && !notification.isSeen) {
        existedNotificationIds.add(notification.notificationId);
      }
    }

    const unread = state.unread.filter((i) => !existedNotificationIds.has(i.notificationId));
    const unreadCount = state.unreadCount - existedNotificationIds.size;

    patchState({
      unread,
      unreadCount,
    });
  }
}
