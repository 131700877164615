import { Pipe, PipeTransform } from '@angular/core';
import { AuthService } from '@app/core/auth';
import { map, Observable, of } from 'rxjs';

@Pipe({
  name: 'isCurrentUser',
})
export class IsCurrentUserPipe implements PipeTransform {
  constructor(private readonly authService: AuthService) {}

  public transform(value: string | null | undefined): Observable<boolean> {
    if (!value) {
      return of(false);
    }

    return this.authService.profile$.pipe(map((profile) => !!profile && value === profile.userId));
  }
}
