import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LoaderModule } from '@app/core/loader';
import { ButtonsModule } from '@app/shared/components/buttons/buttons.module';
import { SvgIconComponent } from 'angular-svg-icon';

import { ProfileEmailConfirmationDialogComponent } from './profile-email-confirmation-dialog.component';

@NgModule({
  declarations: [ProfileEmailConfirmationDialogComponent],
  imports: [CommonModule, ButtonsModule, SvgIconComponent, LoaderModule],
})
export class ProfileEmailConfirmationDialogModule {}
