import { CommonModule } from '@angular/common';
import { APP_INITIALIZER, ModuleWithProviders, NgModule } from '@angular/core';
import { NgxsModule, Store } from '@ngxs/store';

import { CategoryState } from './category.state';
import { CategoryStateActions } from './category.state.actions';

@NgModule({
  imports: [CommonModule, NgxsModule.forFeature([CategoryState])],
})
export class CategoryStoreModule {
  public static forRoot(): ModuleWithProviders<CategoryStoreModule> {
    return {
      ngModule: CategoryStoreModule,
      providers: [
        {
          provide: APP_INITIALIZER,
          useFactory: (store: Store) => () => store.dispatch(new CategoryStateActions.Load()),
          deps: [Store],
          multi: true,
        },
      ],
    };
  }
}
