import { Injectable, isDevMode } from '@angular/core';
import { AuthProfileModel, AuthService } from '@app/core/auth';
import { filter, map, ReplaySubject, tap } from 'rxjs';

export const PROFILE_TOTAL_STEPS = 4;

@Injectable({ providedIn: 'root' })
export class ProfileWizardProgressService {
  completedSteps$ = new ReplaySubject<number[]>(1);

  continueRoute$ = this.completedSteps$.pipe(map((completedSteps) => this.getContinueRoute(completedSteps)));

  nextStep$ = this.completedSteps$.pipe(map((completedSteps) => this.getNextStep(completedSteps)));

  profileCompleted$ = this.completedSteps$.pipe(map((steps) => steps.length === PROFILE_TOTAL_STEPS));

  profileEmpty$ = new ReplaySubject<boolean>(1);

  constructor(private readonly authService: AuthService) {
    this.authService.profile$
      .pipe(
        filter((profile): profile is AuthProfileModel => !!profile),
        tap((profile) => this.parseCompletedSteps(profile)),
        tap((profile) => this.parseIsProfileEmpty(profile)),
      )
      .subscribe();
  }

  protected parseIsProfileEmpty(profile: AuthProfileModel): void {
    const isEmpty =
      (!profile.firstName || !profile.lastName) &&
      !profile.profession &&
      !profile.contactEmail &&
      !profile.avatar &&
      !profile.categories.length &&
      !profile.business?.title &&
      !profile.business?.content.length &&
      !profile.catalogImage;

    this.profileEmpty$.next(isEmpty);
  }

  protected parseCompletedSteps(profile: AuthProfileModel): void {
    const completedSteps: number[] = [];
    for (let i = 1; i <= PROFILE_TOTAL_STEPS; i++) {
      const isCompleted = this.isStepCompleted(i, profile);

      if (isCompleted) {
        completedSteps.push(i);
      }
    }

    this.completedSteps$.next(completedSteps);
  }

  protected isStepCompleted(step: number, profile: AuthProfileModel): boolean {
    switch (step) {
      case 1:
        return (
          !!profile.firstName && !!profile.lastName && !!profile.profession && !!profile.contactEmail && !!profile.city
        );
      case 2:
        return profile.categories.length > 0;
      case 3:
        return !!profile.business?.title && profile.business?.content?.length > 0;
      case 4:
        return !!profile.catalogImage;
      default:
        if (isDevMode()) {
          console.warn(`Unexpected step num: ${step}`);
        }

        return true;
    }
  }

  protected getContinueRoute(completedSteps: number[]): string[] {
    const step = this.getNextStep(completedSteps);
    return ['/profile', 'wizard', step];
  }

  protected getNextStep(completedSteps: number[]): string {
    for (let i = 1; i <= PROFILE_TOTAL_STEPS; i++) {
      if (!completedSteps.includes(i)) {
        return `${i}`;
      }
    }

    return '1';
  }
}
