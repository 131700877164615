import { FactoryOpts } from 'imask';

export const MAX_LENGTH_MASK_FACTORY = (maxLength: number): FactoryOpts => ({
  mask: /.*/,
  prepare: (appended, masked): string => {
    if (!appended) {
      return '';
    }

    const currentValueLength = masked.value.length;
    const nextValueLength = currentValueLength + appended.length;
    const charsToDelete = nextValueLength > maxLength ? nextValueLength - maxLength : 0;
    const charsEnd = charsToDelete ? appended.length - charsToDelete : appended.length;

    return appended.substring(0, charsEnd);
  },
});
