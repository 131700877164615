import { FileDto } from '@app/core/api/file';

export enum PayerTypeEnum {
  System = 'system',
  User = 'user',
  Operator = 'operator',
}

export interface PayerDto {
  avatar: FileDto | null;
  userId: string;
  firstName?: string | null;
  lastName?: string | null;
  fullName: string | null;
  profession: string | null;
  slug: string | null;
}

export interface GoalSponsorDto {
  payerId: string | null;
  payerType: PayerTypeEnum;
  payer: PayerDto | null;
  amount: number | null;
  anonymous: boolean;
}
