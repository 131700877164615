import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'valueByKey',
})
export class ValueByKeyPipe implements PipeTransform {
  public transform<T extends object, K extends keyof T>(obj: T, key: K): T[K] | undefined;
  public transform<T extends object, K extends keyof T>(obj: T, key: K | null | undefined): T[K] | undefined;
  public transform<T extends object, K extends keyof T>(obj: T, key: K | null | undefined): T[K] | null | undefined {
    return key ? obj?.[key] : null;
  }
}
