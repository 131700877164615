import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'default',
})
export class DefaultPipe implements PipeTransform {
  public transform<T>(value: T | undefined | null, defaultValue: T): T {
    return value || defaultValue;
  }
}
