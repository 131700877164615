import { HttpClient } from '@angular/common/http';
import { makeStateKey, StateKey, TransferState } from '@angular/core';
import { SvgHttpLoader, SvgLoader } from 'angular-svg-icon';
import { Observable } from 'rxjs';

export class SvgBrowserLoader implements SvgLoader {
  constructor(
    private readonly transferState: TransferState,
    private readonly http: HttpClient,
    private readonly svgIconPath: string,
  ) {}

  public getSvg(iconName: string): Observable<string> {
    const url = `${this.svgIconPath}/${iconName}.svg`;
    const key: StateKey<string> = makeStateKey<string>('transfer-svg:' + iconName);
    const data = this.transferState.get(key, null);

    // First we are looking for the translations in transfer-state, if none found, http load as fallback
    if (data) {
      return new Observable((subscriber) => {
        subscriber.next(data);
        subscriber.complete();
      });
    } else {
      return new SvgHttpLoader(this.http).getSvg(url);
    }
  }
}

export function svgBrowserLoaderFactory(
  http: HttpClient,
  transferState: TransferState,
  svgIconPath: string,
): SvgBrowserLoader {
  return new SvgBrowserLoader(transferState, http, svgIconPath);
}
