import { FactoryOpts } from 'imask';

export const CURRENCY_MASK: FactoryOpts = {
  mask: [
    { mask: '' },
    {
      mask: 'num ₽',
      lazy: false,
      blocks: {
        num: {
          mask: Number,
          scale: 0, // digits after point, 0 for integers
          thousandsSeparator: ' ',
          padFractionalZeros: false, // if true, then pads zeros at end to the length of scale
          normalizeZeros: false, // appends or removes zeros at end of decimals
          radix: '.', // fractional delimiter
          min: 0,
        },
      },
    },
  ],
};
