import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, CanMatchFn, Router } from '@angular/router';
import { AuthService } from '@app/core/auth';
import { getResolvedRouteUrl } from '@app/shared/utils';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
class AuthGuard {
  constructor(
    private readonly authService: AuthService,
    private readonly router: Router,
  ) {}

  public checkActivation(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this.authService.authenticated$.pipe(
      map((authenticated) => {
        if (!authenticated) {
          this.router.navigate(['/auth'], {
            queryParams: {
              redirectTo: getResolvedRouteUrl(route),
            },
          });
          return false;
        }

        return authenticated;
      }),
    );
  }

  public checkAuthorization(): Observable<boolean> {
    return this.authService.authenticated$;
  }
}

export const canActivateAuthorized: CanActivateFn = (route) => inject(AuthGuard).checkActivation(route);

export const canMatchAuthorized: CanMatchFn = () => inject(AuthGuard).checkAuthorization();
