import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { buildHttpParams } from '@app/shared/utils';
import { Observable } from 'rxjs';

import { ApiPaginatedResponseDto } from '../common-dtos/api-paginated-response.dto';
import { ApiResponseDto } from '../common-dtos/api-response.dto';
import {
  AppNotificationDto,
  AppNotificationsCountResponseDto,
  AppNotificationsFilterDto,
  AppNotificationsFindRequestDto,
  AppNotificationsReadRequestDto,
  AppNotificationsReadResponseDto,
  NotificationCategoryDto,
  NotificationsSetFcmTokenRequestDto,
  NotificationUserPreferencesDto,
  NotificationUserPreferencesUpdateRequestDto,
} from './dtos';

@Injectable({ providedIn: 'root' })
export class NotificationsApiService {
  constructor(private readonly http: HttpClient) {}

  public setFcmToken(request: NotificationsSetFcmTokenRequestDto): Observable<unknown> {
    return this.http.put('/api/notifications/fcm-token', request);
  }

  public find(
    request: AppNotificationsFindRequestDto,
  ): Observable<ApiResponseDto<ApiPaginatedResponseDto<AppNotificationDto>>> {
    return this.http.get<ApiResponseDto<ApiPaginatedResponseDto<AppNotificationDto>>>('/api/notifications', {
      params: buildHttpParams({
        ...request,
        t: Date.now(),
      }),
    });
  }

  public count(request: AppNotificationsFilterDto): Observable<ApiResponseDto<AppNotificationsCountResponseDto>> {
    return this.http.get<ApiResponseDto<AppNotificationsCountResponseDto>>('/api/notifications/count', {
      params: buildHttpParams(request),
    });
  }

  public read(request: AppNotificationsReadRequestDto): Observable<ApiResponseDto<AppNotificationsReadResponseDto>> {
    return this.http.put<ApiResponseDto<AppNotificationsReadResponseDto>>('/api/notifications/read', request);
  }

  public readAll(): Observable<ApiResponseDto<AppNotificationsReadResponseDto>> {
    return this.http.put<ApiResponseDto<AppNotificationsReadResponseDto>>('/api/notifications/read-all', {});
  }

  public hideNotification(notificationId: string): Observable<unknown> {
    return this.http.delete(`/api/notifications/${notificationId}`);
  }

  public findCategories(): Observable<ApiResponseDto<ApiPaginatedResponseDto<NotificationCategoryDto>>> {
    return this.http.get<ApiResponseDto<ApiPaginatedResponseDto<NotificationCategoryDto>>>(
      '/api/notifications/categories',
    );
  }

  public getUserPreferences(): Observable<ApiResponseDto<NotificationUserPreferencesDto>> {
    return this.http.get<ApiResponseDto<NotificationUserPreferencesDto>>('/api/notifications/preferences');
  }

  public updateUserPreferences(
    request: NotificationUserPreferencesUpdateRequestDto,
  ): Observable<ApiResponseDto<NotificationUserPreferencesDto>> {
    return this.http.put<ApiResponseDto<NotificationUserPreferencesDto>>('/api/notifications/preferences', request);
  }
}
