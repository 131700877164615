import { HttpErrorResponse } from '@angular/common/http';
import { ApiMetaErrorResponseDto, ApiResponseDto } from '@app/core/api';
import { catchError, Observable, OperatorFunction, throwError } from 'rxjs';

export function isApiException(error: unknown): error is ApiResponseDto<unknown, ApiMetaErrorResponseDto> {
  return !!(
    error &&
    typeof error === 'object' &&
    'meta' in error &&
    typeof error.meta === 'object' &&
    error.meta &&
    'success' in error.meta &&
    !error.meta.success
  );
}

export function extractApiException(errorException: unknown): ApiResponseDto<unknown, ApiMetaErrorResponseDto> | null {
  if (errorException instanceof HttpErrorResponse && isApiException(errorException.error)) {
    return errorException.error;
  }

  return null;
}

export function processApiException<T>(
  next: (value: ApiResponseDto<unknown, ApiMetaErrorResponseDto>) => void,
): OperatorFunction<T, T> {
  return (source: Observable<T>): Observable<T> =>
    source.pipe(
      catchError((error) => {
        const apiError = extractApiException(error);

        if (apiError) {
          next(apiError);
        }

        return throwError(error);
      }),
    );
}
