import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ArrayPipesModule } from '@app/shared/pipes/array-pipes/array-pipes.module';
import { LottieComponent } from 'ngx-lottie';

import { IsLoadingPipe } from './is-loading.pipe';
import { LoaderDirective } from './loader.directive';
import { LoaderService } from './loader.service';
import { LoaderHttpErrorInterceptor } from './loader-http-error.interceptor';
import { LoaderSpinnerComponent } from './loader-spinner/loader-spinner.component';

@NgModule({
  declarations: [IsLoadingPipe, LoaderSpinnerComponent, LoaderDirective],
  imports: [CommonModule, ArrayPipesModule, LottieComponent],
  exports: [IsLoadingPipe, LoaderSpinnerComponent, LoaderDirective],
  providers: [
    LoaderService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderHttpErrorInterceptor,
      multi: true,
    },
  ],
})
export class LoaderModule {}
