import { ApiPaginationRequestDto } from '../../common-dtos';

export type TransactionIncomingOrderKey =
  | 'createdAt'
  | '-createdAt'
  | 'paymentDate'
  | '-paymentDate'
  | 'amount'
  | '-amount';

export interface TransactionIncomingRequestDto extends ApiPaginationRequestDto {
  goalId?: string | null;
  order?: TransactionIncomingOrderKey | null;
}
