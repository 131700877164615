import { Directive, HostBinding, InjectionToken, Input } from '@angular/core';

export interface FormFieldError {
  id: string;
}

export const FORM_FIELD_ERROR = new InjectionToken<FormFieldError>('Form field error');

let uniqueId = 0;

@Directive({
  selector: '[appFormFieldError], app-form-field-error',
  exportAs: 'appFormFieldError',
  host: {
    class: 'form-field-error',
  },
  providers: [{ provide: FORM_FIELD_ERROR, useExisting: FormFieldErrorDirective }],
})
export class FormFieldErrorDirective {
  @HostBinding('id') @Input() id = `form-field-error_${++uniqueId}`;
}
