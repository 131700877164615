import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SimpleSnackBarComponent } from './simple-snack-bar/simple-snack-bar.component';
import { SnackBar } from './snack-bar';
import { SnackBarService } from './snack-bar.service';
import { SnackBarContainerComponent } from './snack-bar-container/snack-bar-container.component';
import { SnackBarLabel } from './snack-bar-content';

@NgModule({
  imports: [
    CommonModule,
    PortalModule,
    OverlayModule,
    SnackBarContainerComponent,
    SimpleSnackBarComponent,
    SnackBarLabel,
  ],
  exports: [SnackBarContainerComponent, SnackBarLabel, SnackBarContainerComponent, SnackBarLabel],
  providers: [SnackBar, SnackBarService],
})
export class SnackBarModule {}
