import { inject, Injectable } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from '@app/core/auth';
import { map, Observable } from 'rxjs';

interface AuthRoutingGuardParams {
  authenticatedRoute: string[];
  notAuthenticatedRoute: string[];
}

@Injectable({
  providedIn: 'root',
})
class AuthRoutingGuard {
  constructor(
    private readonly authService: AuthService,
    private readonly router: Router,
  ) {}

  public redirectToHomePage(params: AuthRoutingGuardParams): Observable<boolean> {
    return this.authService.authenticated$.pipe(
      map((authenticated) => {
        this.router.navigate(authenticated ? params.authenticatedRoute : params.notAuthenticatedRoute);
        return true;
      }),
    );
  }
}

export const authRoutingGuardFactory =
  (params: AuthRoutingGuardParams): CanActivateFn =>
  () =>
    inject(AuthRoutingGuard).redirectToHomePage(params);
