import { ApiPaginationRequestDto } from '../../common-dtos';

export interface AppNotificationsFilterDto {
  isSeen?: boolean | null;
  notificationIds?: string[] | null;
}

export type AppNotificationsOrderKey = 'createdAt' | '-createdAt';

export interface AppNotificationsFindRequestDto extends AppNotificationsFilterDto, ApiPaginationRequestDto {
  order?: AppNotificationsOrderKey | null;
}
