export interface HeaderParams {
  hidden: boolean;
  hideShadow: boolean;
  transparent: boolean;
  container: boolean;
  hideCreateGoalButton: boolean;
  fixed: boolean;
}

export const HEADER_DEFAULT_PARAMS: HeaderParams = {
  hideShadow: false,
  hidden: false,
  transparent: false,
  container: false,
  hideCreateGoalButton: false,
  fixed: false,
};

export function mergeHeaderParams<T extends Partial<HeaderParams>>(parent: T, current: Partial<HeaderParams>): T {
  return Object.assign(parent, current);
}

export function provideHeaderData(data: Partial<HeaderParams>): Record<string, unknown> {
  return { header: data };
}
