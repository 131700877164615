export enum AuthExternalProviderEnum {
  Vk = 'vk',
  Ok = 'ok',
  Ya = 'ya',
}

export interface AuthExternalStartRequestDto {
  authRedirectUrl: string;
  appBackUrl?: string | null;
}
