import { Injectable } from '@angular/core';
import { ProfileApiService } from '@app/core/api/profile';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { catchError, map, Observable, of, tap } from 'rxjs';

import { AuthProfileModel } from '../models';
import { AuthStateActions } from './auth.state.actions';

interface AuthStateModel {
  profile: AuthProfileModel | null;
  initialized: boolean;
}

const EMPTY_STATE: AuthStateModel = {
  profile: null,
  initialized: false,
};

@State({
  name: 'auth',
  defaults: { ...EMPTY_STATE },
})
@Injectable()
export class AuthState {
  @Selector()
  public static initialized(state: AuthStateModel): boolean {
    return state.initialized;
  }

  @Selector()
  public static profile(state: AuthStateModel): AuthProfileModel | null {
    return state.profile;
  }

  constructor(private readonly profileApi: ProfileApiService) {}

  @Action(AuthStateActions.LoadProfile, { cancelUncompleted: true })
  public loadProfile({ patchState }: StateContext<AuthStateModel>): Observable<AuthProfileModel | null> {
    return this.profileApi.getProfile().pipe(
      map((response) => response.data),
      catchError(() => of(null)),
      tap((profile) => patchState({ initialized: true, profile })),
    );
  }

  @Action(AuthStateActions.SetProfile)
  public setProfile({ patchState }: StateContext<AuthStateModel>, { profile }: AuthStateActions.SetProfile): void {
    patchState({ profile, initialized: true });
  }

  @Action(AuthStateActions.ClearProfile)
  public clearProfile({ patchState }: StateContext<AuthStateModel>): void {
    patchState({ profile: null });
  }
}
