import { CityDto } from '@app/core/api/catalog';
import { FileDto } from '@app/core/api/file';
import { ProfileDto } from '@app/core/api/profile';

export enum GoalStatusEnum {
  Draft = 'draft',
  WaitModeration = 'wait-moderation',
  WaitReModeration = 'wait-re-moderation',
  NeedsFix = 'needs-fix',
  OnModeration = 'on-moderation',
  Canceled = 'canceled',
  Rejected = 'rejected',
  Active = 'active',
  Completed = 'completed',
  Archived = 'archived',
}

export interface GoalContentDto {
  mainImage: FileDto | null;
  description: string | null;
}

export interface GoalCityDto extends Pick<CityDto, 'id' | 'name'> {
  region: string;
  regionId: string;
}

export type GoalOwnerDto = Pick<ProfileDto, 'avatar' | 'userId' | 'profession' | 'fullName' | 'slug'>;

export interface GoalDto {
  /** ISO */
  activeUntil: string | null;
  content: GoalContentDto;
  gallery: FileDto[];
  catalogImage: FileDto | null;
  goalAmount: number | null;
  id: string;
  status: GoalStatusEnum;
  subtitle: string | null;
  title: string | null;
  userId: string;
  city: GoalCityDto | null;
  collected: number | null;
  owner: GoalOwnerDto;
  wasFixed: boolean;
  scoringId: string | null;
  excessAllowed: boolean;
}
