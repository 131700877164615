import { Directive } from '@angular/core';

@Directive({
  selector: '[appFormFieldHint], app-form-field-hint',
  exportAs: 'appFormFieldHint',
  host: {
    class: 'form-field-hint',
  },
})
export class FormFieldHintDirective {}
