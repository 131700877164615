import { GoalDto } from '@app/core/api/goal';
import { PaymentCardDto } from '@app/core/api/payment';
import { ScoringDto } from '@app/core/api/scoring';

export namespace GoalCurrentStateActions {
  export class Load {
    public static readonly type = '[GoalCurrent] Load';
  }

  export class Set {
    public static readonly type = '[GoalCurrent] Set';

    constructor(public readonly goal: GoalDto) {}
  }

  export class ReloadGoalReports {
    public static readonly type = '[GoalCurrent] Reload goal reports';
  }

  export class SetScoring {
    public static readonly type = '[GoalCurrent] Set Scoring';

    constructor(public readonly scoring: ScoringDto) {}
  }

  export class ReloadPayoutCards {
    public static readonly type = '[GoalCurrent] Reload Payout Cards';
  }

  export class SetPayoutCards {
    public static readonly type = '[GoalCurrent] Set Payout Cards';

    constructor(public readonly cards: PaymentCardDto[]) {}
  }

  export class ReloadGoalAndScoring {
    public static readonly type = '[GoalCurrent] Reload Goal and Scoring';
  }

  export class SetGoalAndScoring {
    public static readonly type = '[GoalCurrent] Set Goal and Scoring';

    constructor(
      public readonly goal: GoalDto,
      public readonly scoring: ScoringDto,
    ) {}
  }
}
