import { APP_INITIALIZER, NgModule } from '@angular/core';

import { MetaInitService } from './meta-init.service';

@NgModule({
  providers: [
    MetaInitService,
    {
      provide: APP_INITIALIZER,
      useFactory: (service: MetaInitService) => () => service.init(),
      deps: [MetaInitService],
      multi: true,
    },
  ],
})
export class MetaModule {}
