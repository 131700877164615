export enum NotificationUserPreferencesTransportValueEnum {
  AllCategories = 'all-categories',
  SelectedCategories = 'selected-categories',
  Disabled = 'disabled',
}

export enum NotificationTransportEnum {
  App = 'app',
  Push = 'push',
  Email = 'email',
}

export interface NotificationUserPreferencesTransportDto {
  value: NotificationUserPreferencesTransportValueEnum;
  categories?: string[];
}

export type NotificationUserPreferencesTransportsDto = Record<
  NotificationTransportEnum,
  NotificationUserPreferencesTransportDto
>;

export interface NotificationUserPreferencesDto {
  transports: NotificationUserPreferencesTransportsDto;
}
