import { AnimationEvent } from '@angular/animations';
import { AriaLivePoliteness } from '@angular/cdk/a11y';
import { Platform } from '@angular/cdk/platform';
import {
  BasePortalOutlet,
  CdkPortalOutlet,
  ComponentPortal,
  DomPortal,
  PortalModule,
  TemplatePortal,
} from '@angular/cdk/portal';
import { DOCUMENT } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentRef,
  ElementRef,
  EmbeddedViewRef,
  inject,
  isDevMode,
  NgZone,
  OnDestroy,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { SnackBarConfig } from '@app/shared/components/snack-bar/snack-bar-config';
import { Observable, Subject } from 'rxjs';
import { take } from 'rxjs/operators';

import { snackBarAnimations } from './snack-bar-animations';

let uniqueId = 0;

@Component({
  selector: 'app-snack-bar-container',
  templateUrl: './snack-bar-container.component.html',
  styleUrls: ['./snack-bar-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
  encapsulation: ViewEncapsulation.None,
  animations: [snackBarAnimations.snackBarState],
  standalone: true,
  imports: [PortalModule],
  host: {
    class: 'snackbar snack-bar-container snackbar--open',
    '[@state]': 'animationState',
    '(@state.done)': 'onAnimationEnd($event)',
  },
})
export class SnackBarContainerComponent extends BasePortalOutlet implements OnDestroy {
  private document = inject(DOCUMENT);

  private trackedModals = new Set<Element>();

  private readonly announceDelay: number = 150;

  private announceTimeoutId?: number;

  private destroyed = false;

  @ViewChild(CdkPortalOutlet, { static: true }) portalOutlet!: CdkPortalOutlet;

  readonly onAnnounce: Subject<void> = new Subject();

  readonly onExit: Subject<void> = new Subject();

  readonly onEnter: Subject<void> = new Subject();

  animationState = 'void';

  live: AriaLivePoliteness;

  @ViewChild('label', { static: true }) label!: ElementRef;

  role?: 'status' | 'alert';

  readonly liveElementId = `snack-bar-container-live-${uniqueId++}`;

  constructor(
    private _ngZone: NgZone,
    private _elementRef: ElementRef<HTMLElement>,
    private _changeDetectorRef: ChangeDetectorRef,
    private _platform: Platform,
    public snackBarConfig: SnackBarConfig,
  ) {
    super();

    if (snackBarConfig.politeness === 'assertive' && !snackBarConfig.announcementMessage) {
      this.live = 'assertive';
    } else if (snackBarConfig.politeness === 'off') {
      this.live = 'off';
    } else {
      this.live = 'polite';
    }

    if (this._platform.FIREFOX) {
      if (this.live === 'polite') {
        this.role = 'status';
      }

      if (this.live === 'assertive') {
        this.role = 'alert';
      }
    }
  }

  public attachComponentPortal<T>(portal: ComponentPortal<T>): ComponentRef<T> {
    this.assertNotAttached();
    const result = this.portalOutlet.attachComponentPortal(portal);
    this.afterPortalAttached();
    return result;
  }

  public attachTemplatePortal<C>(portal: TemplatePortal<C>): EmbeddedViewRef<C> {
    this.assertNotAttached();
    const result = this.portalOutlet.attachTemplatePortal(portal);
    this.afterPortalAttached();
    return result;
  }

  override attachDomPortal = (portal: DomPortal): void => {
    this.assertNotAttached();
    const result = this.portalOutlet.attachDomPortal(portal);
    this.afterPortalAttached();
    return result;
  };

  public onAnimationEnd(event: AnimationEvent): void {
    const { fromState, toState } = event;

    if ((toState === 'void' && fromState !== 'void') || toState === 'hidden') {
      this.completeExit();
    }

    if (toState === 'visible') {
      const onEnter = this.onEnter;

      this._ngZone.run(() => {
        onEnter.next();
        onEnter.complete();
      });
    }
  }

  public enter(): void {
    if (!this.destroyed) {
      this.animationState = 'visible';
      this._changeDetectorRef.detectChanges();
      this.screenReaderAnnounce();
    }
  }

  public exit(): Observable<void> {
    this._ngZone.run(() => {
      this.animationState = 'hidden';
      this._elementRef.nativeElement.setAttribute('mat-exit', '');
      clearTimeout(this.announceTimeoutId);
    });

    return this.onExit;
  }

  public ngOnDestroy(): void {
    this.destroyed = true;
    this.clearFromModals();
    this.completeExit();
  }

  private completeExit(): void {
    this._ngZone.onMicrotaskEmpty.pipe(take(1)).subscribe(() => {
      this._ngZone.run(() => {
        this.onExit.next();
        this.onExit.complete();
      });
    });
  }

  private afterPortalAttached(): void {
    const element: HTMLElement = this._elementRef.nativeElement;
    const panelClasses = this.snackBarConfig.panelClass;

    if (panelClasses) {
      if (Array.isArray(panelClasses)) {
        panelClasses.forEach((cssClass) => element.classList.add(cssClass));
      } else {
        element.classList.add(panelClasses);
      }
    }

    this.exposeToModals();

    const label = this.label.nativeElement;
    const labelClass = 'mdc-snackbar__label';
    label.classList.toggle(labelClass, !label.querySelector(`.${labelClass}`));
  }

  private exposeToModals(): void {
    const id = this.liveElementId;
    const modals = this.document.querySelectorAll('body > .cdk-overlay-container [aria-modal="true"]');

    for (let i = 0; i < modals.length; i++) {
      const modal = modals[i];
      const ariaOwns = modal.getAttribute('aria-owns');
      this.trackedModals.add(modal);

      if (!ariaOwns) {
        modal.setAttribute('aria-owns', id);
      } else if (ariaOwns.indexOf(id) === -1) {
        modal.setAttribute('aria-owns', ariaOwns + ' ' + id);
      }
    }
  }

  private clearFromModals(): void {
    this.trackedModals.forEach((modal) => {
      const ariaOwns = modal.getAttribute('aria-owns');

      if (ariaOwns) {
        const newValue = ariaOwns.replace(this.liveElementId, '').trim();

        if (newValue.length > 0) {
          modal.setAttribute('aria-owns', newValue);
        } else {
          modal.removeAttribute('aria-owns');
        }
      }
    });
    this.trackedModals.clear();
  }

  private assertNotAttached(): void {
    if (this.portalOutlet.hasAttached() && isDevMode()) {
      throw Error('Attempting to attach snack bar content after content is already attached');
    }
  }

  private screenReaderAnnounce(): void {
    if (!this.announceTimeoutId) {
      this._ngZone.runOutsideAngular(() => {
        this.announceTimeoutId = setTimeout(() => {
          const inertElement = this._elementRef.nativeElement.querySelector('[aria-hidden]');
          const liveElement = this._elementRef.nativeElement.querySelector('[aria-live]');

          if (inertElement && liveElement) {
            // If an element in the snack bar content is focused before being moved
            // track it and restore focus after moving to the live region.
            let focusedElement: HTMLElement | null = null;

            if (
              this._platform.isBrowser &&
              document.activeElement instanceof HTMLElement &&
              inertElement.contains(document.activeElement)
            ) {
              focusedElement = document.activeElement;
            }

            inertElement.removeAttribute('aria-hidden');
            liveElement.appendChild(inertElement);
            focusedElement?.focus();

            this.onAnnounce.next();
            this.onAnnounce.complete();
          }
        }, this.announceDelay) as unknown as number;
      });
    }
  }
}
