import { Injectable } from '@angular/core';
import { GoalApiService, GoalDto, GoalReviewCommentDto, GoalStatusEnum } from '@app/core/api/goal';
import { PaymentApiService, PaymentCardDto, PaymentCardTypeEnum } from '@app/core/api/payment';
import { ScoringApiService, ScoringDto } from '@app/core/api/scoring';
import { GoalCurrentStateActions } from '@app/features/goal/goal-current/goal-current.state.actions';
import { GOAL_EDITABLE_STATUSES, GOAL_MODERATION_STATUSES } from '@app/features/goal/goal-current/goal-current.utils';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { catchError, EMPTY, forkJoin, map, Observable, of, switchMap, tap } from 'rxjs';

interface GoalCurrentStateModel {
  currentGoal: GoalDto | null;
  requiredReportGoals: GoalDto[];
  paymentCards: PaymentCardDto[];
  amountLimit: number | null;
  scoring: ScoringDto | null;
  reviewComment: GoalReviewCommentDto | null;
  initialized: boolean;
}

const STATE_EMPTY: GoalCurrentStateModel = {
  currentGoal: null,
  requiredReportGoals: [],
  paymentCards: [],
  amountLimit: null,
  scoring: null,
  reviewComment: null,
  initialized: false,
};

@State<GoalCurrentStateModel>({
  name: 'goal',
  defaults: { ...STATE_EMPTY },
})
@Injectable()
export class GoalCurrentState {
  @Selector()
  public static currentGoal(state: GoalCurrentStateModel): GoalDto | null {
    return state.currentGoal;
  }

  @Selector()
  public static payoutCards(state: GoalCurrentStateModel): PaymentCardDto[] {
    return state.paymentCards;
  }

  @Selector()
  public static amountLimit(state: GoalCurrentStateModel): number | null {
    return state.amountLimit;
  }

  @Selector()
  public static requiredReportGoals(state: GoalCurrentStateModel): GoalDto[] {
    return state.requiredReportGoals;
  }

  @Selector()
  public static initialized(state: GoalCurrentStateModel): boolean {
    return state.initialized;
  }

  @Selector()
  public static scoring(state: GoalCurrentStateModel): ScoringDto | null {
    return state.scoring;
  }

  @Selector()
  public static reviewComment(state: GoalCurrentStateModel): GoalReviewCommentDto | null {
    return state.reviewComment;
  }

  constructor(
    private readonly paymentApi: PaymentApiService,
    private readonly goalApi: GoalApiService,
    private readonly scoringApi: ScoringApiService,
  ) {}

  @Action(GoalCurrentStateActions.Load, { cancelUncompleted: true })
  public load({ patchState }: StateContext<GoalCurrentStateModel>): Observable<unknown> {
    return forkJoin([
      this.loadGoal(),
      this.loadPayoutCards(),
      this.loadAmountLimit(),
      this.loadRequiredReportGoals(),
    ]).pipe(
      tap(([{ goal, scoring, reviewComment }, payoutTools, amountLimit, requiredReportGoals]) =>
        patchState({
          currentGoal: goal,
          paymentCards: payoutTools,
          amountLimit,
          requiredReportGoals,
          scoring: scoring,
          reviewComment,
          initialized: true,
        }),
      ),
      catchError(() => {
        patchState({ initialized: true });
        return EMPTY;
      }),
    );
  }

  @Action(GoalCurrentStateActions.Set)
  public setCurrentGoal(
    { patchState }: StateContext<GoalCurrentStateModel>,
    { goal }: GoalCurrentStateActions.Set,
  ): void {
    patchState({ currentGoal: goal, initialized: true });
  }

  @Action(GoalCurrentStateActions.SetScoring)
  public setCurrentScoring(
    { patchState }: StateContext<GoalCurrentStateModel>,
    { scoring }: GoalCurrentStateActions.SetScoring,
  ): void {
    patchState({ scoring });
  }

  @Action(GoalCurrentStateActions.ReloadGoalReports, { cancelUncompleted: true })
  public reloadGoalReports({ patchState }: StateContext<GoalCurrentStateModel>): Observable<unknown> {
    return forkJoin([this.loadAmountLimit(), this.loadRequiredReportGoals()]).pipe(
      tap(([amountLimit, requiredReportGoals]) =>
        patchState({
          amountLimit,
          requiredReportGoals,
        }),
      ),
      catchError((error) => {
        console.error(error);
        return EMPTY;
      }),
    );
  }

  @Action(GoalCurrentStateActions.ReloadPayoutCards, { cancelUncompleted: true })
  public reloadPayoutTools({ patchState }: StateContext<GoalCurrentStateModel>): Observable<unknown> {
    return this.loadPayoutCards().pipe(
      tap((payoutTools) => patchState({ paymentCards: payoutTools })),
      catchError((error) => {
        console.error(error);
        return EMPTY;
      }),
    );
  }

  @Action(GoalCurrentStateActions.SetPayoutCards)
  public setPayoutCards(
    { patchState }: StateContext<GoalCurrentStateModel>,
    { cards }: GoalCurrentStateActions.SetPayoutCards,
  ): void {
    patchState({ paymentCards: cards.filter((card) => card.type === PaymentCardTypeEnum.Payout) });
  }

  @Action(GoalCurrentStateActions.ReloadGoalAndScoring, { cancelUncompleted: true })
  public reloadGoalAndScoring({ patchState }: StateContext<GoalCurrentStateModel>): Observable<unknown> {
    return this.loadGoal().pipe(
      tap(({ goal, scoring, reviewComment }) => patchState({ currentGoal: goal, scoring, reviewComment })),
      catchError((error) => {
        console.error(error);
        return EMPTY;
      }),
    );
  }

  @Action(GoalCurrentStateActions.SetGoalAndScoring)
  public setGoalAndScoring(
    { patchState }: StateContext<GoalCurrentStateModel>,
    { goal, scoring }: GoalCurrentStateActions.SetGoalAndScoring,
  ): void {
    patchState({ currentGoal: goal, scoring });
  }

  protected loadGoal(): Observable<{
    goal: GoalDto | null;
    scoring: ScoringDto | null;
    reviewComment: GoalReviewCommentDto | null;
  }> {
    return this.goalApi.getMyGoals({}, true).pipe(
      map(
        (response) =>
          response.data.rows.find((i) =>
            [...GOAL_EDITABLE_STATUSES, ...GOAL_MODERATION_STATUSES, GoalStatusEnum.Active].includes(i.status),
          ) || null,
      ),
      catchError(() => of(null)),
      switchMap((goal) =>
        forkJoin([
          this.loadCurrentScoring(goal).pipe(catchError(() => of(null))),
          this.loadGoalReviewComment(goal).pipe(catchError(() => of(null))),
        ]).pipe(map(([scoring, reviewComment]) => ({ goal, scoring, reviewComment }))),
      ),
    );
  }

  protected loadPayoutCards(): Observable<PaymentCardDto[]> {
    return this.paymentApi
      .findCards({
        type: PaymentCardTypeEnum.Payout,
        onPage: 200,
      })
      .pipe(
        map((response) => response.data.rows.filter((card) => card.type === PaymentCardTypeEnum.Payout)),
        catchError(() => of([])),
      );
  }

  protected loadAmountLimit(): Observable<number | null> {
    return this.goalApi.getAmountLimit(true).pipe(
      map((response) => response.data.limit),
      catchError(() => of(null)),
    );
  }

  protected loadRequiredReportGoals(): Observable<GoalDto[]> {
    return this.goalApi
      .getMyGoals(
        {
          completedSuccessfully: true,
          hasReport: false,
        },
        true,
      )
      .pipe(
        map((response) => response.data.rows),
        catchError(() => of([])),
      );
  }

  protected loadCurrentScoring(goal: GoalDto | null): Observable<ScoringDto | null> {
    if (goal) {
      return this.goalApi.getGoalScoring(goal.id).pipe(
        map((response) => response.data),
        catchError((error) => {
          console.warn(error);
          return of(null);
        }),
      );
    }

    return this.scoringApi
      .find({
        onPage: 1,
        page: 0,
        order: '-createdAt',
      })
      .pipe(
        map((response) => response.data.rows[0] || null),
        catchError(() => of(null)),
      );
  }

  protected loadGoalReviewComment(goal: GoalDto | null): Observable<GoalReviewCommentDto | null> {
    if (goal?.status !== GoalStatusEnum.NeedsFix) {
      return of(null);
    }

    return this.goalApi.findGoalReviewComments(goal.id, { onPage: 1, page: 0 }).pipe(
      map((response) => response.data.rows[0]),
      catchError(() => of(null)),
    );
  }
}
