import { ApiPaginationRequestDto } from '../../common-dtos';
import { GoalStatusEnum } from './goal.dto';

export interface GoalsFindFiltersDto {
  status?: GoalStatusEnum | null;
  id?: string | null;
  userId?: string | null;
  activeUntilGte?: string | Date | null;
  activeUntilLte?: string | Date | null;
  activeUntil?: boolean;
}

export interface GoalsFindRequestDto extends GoalsFindFiltersDto, ApiPaginationRequestDto {}
