export enum ScoringDocumentTypeEnum {
  PassportMain = 'passportMain',
  PlaceRegistration = 'placeRegistration',
  SelfieWithPassport = 'selfieWithPassport',
}

export interface ScoringDocumentDto {
  id: string;
  scoringId: string;
  userId: string;
  fileId: string;
  type: ScoringDocumentTypeEnum;
  createdAt: Date;
}
