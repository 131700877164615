import { Component } from '@angular/core';
import { PreloaderService } from '@app/core/preloader/preloader.service';
import { SupportChatWidgetService } from '@app/features/support/support-chat-widget/support-chat-widget.service';

@Component({
  selector: 'app-root',
  template: '<app-route-handler></app-route-handler>',
})
export class AppComponent {
  constructor(
    private readonly preloaderService: PreloaderService,
    private readonly supportChatWidgetService: SupportChatWidgetService,
  ) {}
}
