/* eslint-disable @typescript-eslint/no-explicit-any */
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { ComponentType, Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal, TemplatePortal } from '@angular/cdk/portal';
import {
  ComponentRef,
  EmbeddedViewRef,
  Injectable,
  Injector,
  OnDestroy,
  Optional,
  SkipSelf,
  TemplateRef,
} from '@angular/core';
import { SNACK_BAR_DATA, SnackBarConfig } from '@app/shared/components/snack-bar/snack-bar-config';
import { takeUntil } from 'rxjs/operators';

import { SimpleSnackBarComponent } from './simple-snack-bar/simple-snack-bar.component';
import { SnackBarContainerComponent } from './snack-bar-container/snack-bar-container.component';
import { SnackBarRef } from './snack-bar-ref';

@Injectable({ providedIn: 'root' })
export class SnackBar implements OnDestroy {
  private snackBarRefAtThisLevel: SnackBarRef<any> | null = null;

  simpleSnackBarComponent = SimpleSnackBarComponent;

  snackBarContainerComponent = SnackBarContainerComponent;

  handsetCssClass = 'snack-bar-handset';

  public get openedSnackBarRef(): SnackBarRef<any> | null {
    const parent = this.parentSnackBar;
    return parent ? parent.openedSnackBarRef : this.snackBarRefAtThisLevel;
  }

  public set openedSnackBarRef(value: SnackBarRef<any> | null) {
    if (this.parentSnackBar) {
      this.parentSnackBar.openedSnackBarRef = value;
    } else {
      this.snackBarRefAtThisLevel = value;
    }
  }

  constructor(
    private overlay: Overlay,
    private live: LiveAnnouncer,
    private injector: Injector,
    private breakpointObserver: BreakpointObserver,
    @Optional() @SkipSelf() private parentSnackBar: SnackBar,
  ) {}

  public openFromComponent<T, D = any>(component: ComponentType<T>, config?: SnackBarConfig<D>): SnackBarRef<T> {
    return this.attach(component, config) as SnackBarRef<T>;
  }

  public openFromTemplate(template: TemplateRef<any>, config?: SnackBarConfig): SnackBarRef<EmbeddedViewRef<any>> {
    return this.attach(template, config);
  }

  public open(message: string, config?: SnackBarConfig): SnackBarRef<SimpleSnackBarComponent> {
    const _config = { ...config };

    _config.data = { message };

    if (_config.announcementMessage === message) {
      _config.announcementMessage = undefined;
    }

    return this.openFromComponent(this.simpleSnackBarComponent, _config);
  }

  public dismiss(): void {
    if (this.openedSnackBarRef) {
      this.openedSnackBarRef.dismiss();
    }
  }

  public ngOnDestroy(): void {
    if (this.snackBarRefAtThisLevel) {
      this.snackBarRefAtThisLevel.dismiss();
    }
  }

  private attachSnackBarContainer(overlayRef: OverlayRef, config: SnackBarConfig): SnackBarContainerComponent {
    const userInjector = config && config.viewContainerRef && config.viewContainerRef.injector;

    const injector = Injector.create({
      parent: userInjector || this.injector,
      providers: [{ provide: SnackBarConfig, useValue: config }],
    });

    const containerPortal = new ComponentPortal(this.snackBarContainerComponent, config.viewContainerRef, injector);
    const containerRef: ComponentRef<SnackBarContainerComponent> = overlayRef.attach(containerPortal);
    containerRef.instance.snackBarConfig = config;
    return containerRef.instance;
  }

  private attach<T>(
    content: ComponentType<T> | TemplateRef<T>,
    userConfig?: SnackBarConfig,
  ): SnackBarRef<T | EmbeddedViewRef<any>> {
    const config = { ...new SnackBarConfig(), ...userConfig };
    const overlayRef = this.createOverlay(config);
    const container = this.attachSnackBarContainer(overlayRef, config);
    const snackBarRef = new SnackBarRef<T | EmbeddedViewRef<any>>(container, overlayRef);

    if (content instanceof TemplateRef) {
      const portal = new TemplatePortal(content, null!, {
        $implicit: config.data,
        snackBarRef,
      } as any);

      snackBarRef.instance = container.attachTemplatePortal(portal);
    } else {
      const injector = this.createInjector(config, snackBarRef);
      const portal = new ComponentPortal(content, undefined, injector);
      const contentRef = container.attachComponentPortal<T>(portal);

      snackBarRef.instance = contentRef.instance;
    }

    this.breakpointObserver
      .observe(Breakpoints.HandsetPortrait)
      .pipe(takeUntil(overlayRef.detachments()))
      .subscribe((state) => {
        overlayRef.overlayElement.classList.toggle(this.handsetCssClass, state.matches);
      });

    if (config.announcementMessage) {
      // Wait until the snack bar contents have been announced then deliver this message.
      container.onAnnounce.subscribe(() => {
        this.live.announce(config.announcementMessage!, config.politeness);
      });
    }

    this.animateSnackBar(snackBarRef, config);
    this.openedSnackBarRef = snackBarRef;
    return this.openedSnackBarRef;
  }

  private animateSnackBar(snackBarRef: SnackBarRef<any>, config: SnackBarConfig): void {
    snackBarRef.afterDismissed().subscribe(() => {
      if (this.openedSnackBarRef == snackBarRef) {
        this.openedSnackBarRef = null;
      }

      if (config.announcementMessage) {
        this.live.clear();
      }
    });

    if (this.openedSnackBarRef) {
      this.openedSnackBarRef.afterDismissed().subscribe(() => {
        snackBarRef.containerInstance.enter();
      });
      this.openedSnackBarRef.dismiss();
    } else {
      snackBarRef.containerInstance.enter();
    }

    if (config.duration && config.duration > 0) {
      snackBarRef.afterOpened().subscribe(() => snackBarRef.dismissAfter(config.duration!));
    }
  }

  private createOverlay(config: SnackBarConfig): OverlayRef {
    const overlayConfig = new OverlayConfig();
    overlayConfig.direction = config.direction;

    const positionStrategy = this.overlay.position().global();

    const isRtl = config.direction === 'rtl';
    const isLeft =
      config.horizontalPosition === 'left' ||
      (config.horizontalPosition === 'start' && !isRtl) ||
      (config.horizontalPosition === 'end' && isRtl);

    const isRight = !isLeft && config.horizontalPosition !== 'center';

    if (isLeft) {
      positionStrategy.left('0');
    } else if (isRight) {
      positionStrategy.right('0');
    } else {
      positionStrategy.centerHorizontally();
    }

    if (config.verticalPosition === 'top') {
      positionStrategy.top('0');
    } else {
      positionStrategy.bottom('0');
    }

    overlayConfig.positionStrategy = positionStrategy;
    return this.overlay.create(overlayConfig);
  }

  private createInjector<T>(config: SnackBarConfig, snackBarRef: SnackBarRef<T>): Injector {
    const userInjector = config && config.viewContainerRef && config.viewContainerRef.injector;

    return Injector.create({
      parent: userInjector || this.injector,
      providers: [
        { provide: SnackBarRef, useValue: snackBarRef },
        { provide: SNACK_BAR_DATA, useValue: config.data },
      ],
    });
  }
}
