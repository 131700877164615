import { PaymentMethodEnum } from '../../payment';

export interface GoalDonateRequestDto {
  amount: number;
  anonymous: boolean;
  returnUrl?: string | null;
  backButtonReturnUrl?: string | null;
  rememberCard?: boolean | null;
  cardId?: string | null;
  paymentMethod?: PaymentMethodEnum | null;
}
