import { HttpErrorResponse } from '@angular/common/http';
import { omitEmpty } from '@app/shared/utils';
import { catchError, Observable, tap, throwError } from 'rxjs';

import { SnackBarService } from './snack-bar.service';

export class IgnoreSnackbarException extends HttpErrorResponse {
  constructor(parent: HttpErrorResponse) {
    super(omitEmpty(parent));
  }
}

/**
 * Выводит сообщение в случае непоказанной ранее ошибки запроса
 * @param snackBar
 * @param message
 */
export function snackBarApiErrorFallback<T>(snackBar: SnackBarService, message: string) {
  return (source: Observable<T>): Observable<T> =>
    source.pipe(
      catchError((error) => {
        if (error instanceof IgnoreSnackbarException) {
          return throwError(error);
        }

        snackBar.showDangerMessage(message);

        return throwError(error);
      }),
    );
}

/**
 * Отображает сообщение в случае успешного запроса
 * @param snackBar
 * @param message
 */
export function snackBarSuccessMessage<T>(snackBar: SnackBarService, message = 'Данные успешно сохранены') {
  return (source: Observable<T>): Observable<T> => source.pipe(tap(() => snackBar.showSuccessMessage(message)));
}
