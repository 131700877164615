import { DialogModule } from '@angular/cdk/dialog';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterLink } from '@angular/router';
import { SupportFeedbackDialogModule } from '@app/features/support/support-feedback-dialog/support-feedback-dialog.module';
import { ButtonsModule } from '@app/shared/components/buttons/buttons.module';
import { ArrayPipesModule } from '@app/shared/pipes/array-pipes/array-pipes.module';
import { SvgIconComponent } from 'angular-svg-icon';

import { FooterComponent } from './footer.component';

@NgModule({
  declarations: [FooterComponent],
  imports: [
    CommonModule,
    ArrayPipesModule,
    ButtonsModule,
    RouterLink,
    SvgIconComponent,
    SupportFeedbackDialogModule,
    DialogModule,
  ],
  exports: [FooterComponent],
})
export class FooterModule {}
