import { Injectable, NgZone } from '@angular/core';
import { ActivatedRoute, Params, QueryParamsHandling, Router } from '@angular/router';

/**
 * Управление query параметрами.
 */
@Injectable({
  providedIn: 'root',
})
export class NavigationQueryService {
  /**
   * Работает аналогично методу navigate.
   * При вызове откладывает переадресацию на небольшой таймаут.
   * При параллельных вызовах собирает все объекты data в один объект.
   *
   * Подробнее о проблеме см. описание метода navigate.
   */
  public navigateDebounced = this.debounceNavigate(this.navigate.bind(this));

  constructor(
    private readonly ngZone: NgZone,
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
  ) {}

  /**
   * Изменить query параметры.
   *
   * В случае параллельных вызовов метода (например, сброс пагинации
   * при изменении фильтра), переадресация может сработать только один раз
   * с параметрами одного из вызовов.
   * Для решения проблемы был добавлен метод navigateDebounced.
   *
   * @param data
   */
  public navigate(data: Params): void {
    this.ngZone.run(() =>
      this.router.navigate([], {
        relativeTo: this.activatedRoute,
        queryParams: data,
        queryParamsHandling: 'merge',
      }),
    );
  }

  protected debounceNavigate<F extends (data: Params, queryParamsHandling: QueryParamsHandling) => void>(
    fn: F,
    delay = 10,
  ): (data: Params) => void {
    let combinedData = {};
    const timer = 0;

    return (data: Params): void => {
      clearTimeout(timer);
      combinedData = {
        ...combinedData,
        ...data,
      };
      setTimeout(() => {
        fn(combinedData, 'merge');
        combinedData = {};
      }, delay);
    };
  }
}
