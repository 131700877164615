import { Inject, Injectable, Optional } from '@angular/core';
import { REQUEST, RESPONSE } from '@nguniversal/express-engine/tokens';
import { Request, Response } from 'express';

import { CookieRepository } from './cookie.repository';

@Injectable({ providedIn: 'root' })
export class CookieRepositoryServer implements CookieRepository {
  public get cookie(): string {
    return this.request?.headers.cookie || '';
  }

  public set cookie(value: string) {
    this.cookiesToSet.push(value);
    this.response?.setHeader('Set-Cookie', this.cookiesToSet);
  }

  protected cookiesToSet: string[] = [];

  constructor(
    @Optional() @Inject(REQUEST) private request: Request | null,
    @Optional() @Inject(RESPONSE) private response: Response | null,
  ) {}
}
