import { ApiPaginationRequestDto } from '../../common-dtos';
import { GoalStatusEnum } from '../../goal';

export interface SupportedGoalsFiltersDto {
  statuses?: GoalStatusEnum[] | null;
  search?: string | null;
}

export interface SupportedGoalsRequestDto extends ApiPaginationRequestDto, SupportedGoalsFiltersDto {
  userId?: string | null;
}
