import { ApiContentBlockContainerDto } from '@app/core/api/misc';

import { CityIdDto } from '../../catalog';
import { ProfileDto, ProfileMemberDto } from './profile.dto';

export interface UpdateImageDto {
  id: string;
}

export interface ProfileUpdateCategoryDto {
  id: string;
}

export interface ProfileUpdateBusinessDto {
  title: string | null;
  description: string | null;
  members: ProfileMemberDto[];
  gallery: UpdateImageDto[];
  content: ApiContentBlockContainerDto[];
}

export interface ProfileUpdateRequestDto
  extends Partial<
    Omit<
      ProfileDto,
      | 'catalogImage'
      | 'cover'
      | 'avatar'
      | 'categories'
      | 'business'
      | 'createdAt'
      | 'updatedAt'
      | 'subscriptions'
      | 'followers'
      | 'city'
    >
  > {
  avatar?: UpdateImageDto | null;
  categories?: ProfileUpdateCategoryDto[];
  business?: Partial<ProfileUpdateBusinessDto>;
  cover?: UpdateImageDto | null;
  catalogImage?: UpdateImageDto | null;
  city?: CityIdDto | null;
}
