import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'includes',
})
export class IncludesPipe implements PipeTransform {
  public transform<T>(target: T[] | null | undefined, value: unknown): boolean {
    return target ? target.includes(value as T) : false;
  }
}
