import { HttpClient, HttpEvent, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiResponseDto } from '@app/core/api';
import { buildHttpParams } from '@app/shared/utils';
import { Observable } from 'rxjs';

import {
  FileGetReadUrlRequestDto,
  FileGetReadUrlResponseDto,
  FileImageResizedRequestDto,
  FileImageSizeDto,
  FileUploadMediaRequestDto,
  FileUploadMediaResponseDto,
} from './dtos';

@Injectable({ providedIn: 'root' })
export class FileApiService {
  constructor(private readonly http: HttpClient) {}

  public uploadImage(
    request: FileUploadMediaRequestDto,
    withProgress?: false,
  ): Observable<ApiResponseDto<FileUploadMediaResponseDto>>;
  public uploadImage(
    request: FileUploadMediaRequestDto,
    withProgress: true,
  ): Observable<HttpEvent<ApiResponseDto<FileUploadMediaResponseDto>>>;
  public uploadImage(
    request: FileUploadMediaRequestDto,
    withProgress?: boolean,
  ): Observable<ApiResponseDto<FileUploadMediaResponseDto> | HttpEvent<ApiResponseDto<FileUploadMediaResponseDto>>> {
    const params = buildHttpParams({ private: request.private });
    const formData = new FormData();

    formData.append('file', request.file, request.filename);

    if (withProgress) {
      return this.http.post<ApiResponseDto<FileUploadMediaResponseDto>>('/api/files/upload-image', formData, {
        params,
        reportProgress: true,
        observe: 'events',
        headers: new HttpHeaders({ 'ngsw-bypass': 'true' }),
      });
    } else {
      return this.http.post<ApiResponseDto<FileUploadMediaResponseDto>>('/api/files/upload-image', formData, {
        params,
      });
    }
  }

  public uploadVideo(
    request: FileUploadMediaRequestDto,
    withProgress?: false,
  ): Observable<ApiResponseDto<FileUploadMediaResponseDto>>;
  public uploadVideo(
    request: FileUploadMediaRequestDto,
    withProgress: true,
  ): Observable<HttpEvent<ApiResponseDto<FileUploadMediaResponseDto>>>;
  public uploadVideo(
    request: FileUploadMediaRequestDto,
    withProgress?: boolean,
  ): Observable<ApiResponseDto<FileUploadMediaResponseDto> | HttpEvent<ApiResponseDto<FileUploadMediaResponseDto>>> {
    const params = buildHttpParams({ private: request.private });
    const formData = new FormData();

    formData.append('file', request.file, request.filename);

    if (withProgress) {
      return this.http.post<ApiResponseDto<FileUploadMediaResponseDto>>('/api/files/upload-video', formData, {
        params,
        reportProgress: true,
        observe: 'events',
        headers: new HttpHeaders({ 'ngsw-bypass': 'true' }),
      });
    } else {
      return this.http.post<ApiResponseDto<FileUploadMediaResponseDto>>('/api/files/upload-video', formData, {
        params,
      });
    }
  }

  public uploadStoryImage(request: FileUploadMediaRequestDto): Observable<ApiResponseDto<FileUploadMediaResponseDto>> {
    const formData = new FormData();

    formData.append('file', request.file, request.filename);

    return this.http.post<ApiResponseDto<FileUploadMediaResponseDto>>('/api/files/stories/upload-image', formData);
  }

  public uploadStoryVideo(request: FileUploadMediaRequestDto): Observable<ApiResponseDto<FileUploadMediaResponseDto>> {
    const formData = new FormData();

    formData.append('file', request.file, request.filename);

    return this.http.post<ApiResponseDto<FileUploadMediaResponseDto>>('/api/files/stories/upload-video', formData);
  }

  public getResizedImage(
    fileId: string,
    request: FileImageResizedRequestDto,
  ): Observable<ApiResponseDto<FileImageSizeDto | null>> {
    return this.http.get<ApiResponseDto<FileImageSizeDto>>(`/api/files/${fileId}/resized`, {
      params: buildHttpParams(request),
    });
  }

  public getSignedReadUrl(
    fileId: string,
    request: FileGetReadUrlRequestDto,
  ): Observable<ApiResponseDto<FileGetReadUrlResponseDto>> {
    return this.http.get<ApiResponseDto<FileGetReadUrlResponseDto>>(`/api/files/${fileId}/read-url`, {
      params: buildHttpParams(request),
    });
  }
}
