import { ConnectedPosition } from '@angular/cdk/overlay';
import { ChangeDetectionStrategy, Component, OnInit, Self } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AppNotificationDto } from '@app/core/api/notifications';
import { NotificationsService } from '@app/core/notifications/notifications.service';
import { DestroyService } from '@app/shared/utils';
import { filter, takeUntil, tap } from 'rxjs';

@Component({
  selector: 'app-header-notifications',
  templateUrl: './header-notifications.component.html',
  styleUrls: ['./header-notifications.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DestroyService],
})
export class HeaderNotificationsComponent implements OnInit {
  unread$ = this.notificationsService.unread$;

  unreadCount$ = this.notificationsService.unreadCount$;

  panelOpen = false;

  positions: ConnectedPosition[] = [
    {
      originX: 'end',
      originY: 'bottom',
      overlayX: 'end',
      overlayY: 'top',
    },
  ];

  constructor(
    private readonly notificationsService: NotificationsService,
    private readonly router: Router,
    @Self() private readonly destroy$: DestroyService,
  ) {}

  public ngOnInit(): void {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        tap(() => this.close()),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  public toggle(): void {
    if (this.panelOpen) {
      this.close();
    } else {
      this.open();
    }
  }

  public open(): void {
    if (!this.panelOpen) {
      this.panelOpen = true;
    }
  }

  public close(): void {
    if (this.panelOpen) {
      this.panelOpen = false;
    }
  }

  protected trackNotification(index: number, item: AppNotificationDto): string {
    return item.id;
  }

  protected read(notiifcations: AppNotificationDto[]): void {
    this.notificationsService.read(notiifcations.map((i) => i.notificationId));
  }
}
