import { TextFieldModule } from '@angular/cdk/text-field';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { FormSharedModule } from '@app/shared/components/forms/form-shared/form-shared.module';
import { BasePipesModule } from '@app/shared/pipes/base-pipes/base-pipes.module';

import { FormFieldComponent } from './form-field.component';

@NgModule({
  declarations: [FormFieldComponent],
  imports: [CommonModule, BasePipesModule, ReactiveFormsModule, TextFieldModule, FormSharedModule],
  exports: [FormSharedModule, FormFieldComponent],
})
export class FormFieldModule {}
