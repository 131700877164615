<div class="not-found">
  <div class="not-found__icon">
    <img src="/assets/images/404.svg" alt="404" />
  </div>
  <div class="not-found__description">
    Кажется, что-то пошло не так. <br class="d-none d-md-block" />
    Данная страница не существует или была удалена. Пожалуйста, вернитесь на главную.
  </div>
  <div class="not-found__footer">
    <a app-flat-button color="primary" [routerLink]="['/']">Перейти на главную</a>
  </div>
</div>
