import { booleanAttribute, Directive, InjectionToken, Input } from '@angular/core';

export interface FormFieldLabel {
  placeholderText?: string | null;
  hasBackground: boolean;
}

export const FORM_FIELD_LABEL = new InjectionToken<FormFieldLabel>('Form field label');

@Directive({
  selector: '[appFormFieldLabel], app-form-field-label',
  exportAs: 'appFormFieldLabel',
  providers: [{ provide: FORM_FIELD_LABEL, useExisting: FormFieldLabelDirective }],
})
export class FormFieldLabelDirective implements FormFieldLabel {
  @Input({ transform: booleanAttribute }) hasBackground = true;

  @Input() placeholderText: string | null = null;
}
