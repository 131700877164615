import { isPlatformServer } from '@angular/common';
import { Inject, Injectable, InjectionToken, Optional, PLATFORM_ID } from '@angular/core';

export const APP_HOST_URL = new InjectionToken<string>('Host URL for SSR');

@Injectable({ providedIn: 'root' })
export class AppHostUrlProvider {
  constructor(
    @Optional() @Inject(APP_HOST_URL) private readonly hostUrl: string | null,
    @Inject(PLATFORM_ID) private readonly platformId: string,
  ) {}

  public getHostUrl(): string {
    if (isPlatformServer(this.platformId)) {
      return this.hostUrl || '';
    } else {
      return window.location.origin;
    }
  }

  public getFullRouteUrl(route: string[], queryParams?: URLSearchParams): string {
    const url = new URL(route.join('/'), this.getHostUrl());

    if (queryParams) {
      queryParams.forEach((value, key) => url.searchParams.append(key, value));
    }

    return url.toString();
  }

  public isHostUrl(url: string): boolean {
    const host = this.getHostUrl();
    return (url.startsWith('/') || url.startsWith(host.replace(/\/$/, ''))) && !this.isAssetsUrl(url);
  }

  public isAssetsUrl(url: string): boolean {
    const host = this.getHostUrl();
    return url.startsWith('/assets/') || url.startsWith(host.replace(/\/?$/, '/assets'));
  }

  public fullHostUrlToRelative(url: string): string {
    const host = this.getHostUrl().replace(/\/$/, '');
    return url.replace(host, '');
  }
}
