/* eslint-disable @typescript-eslint/dot-notation */
import { Inject, Injectable, InjectionToken, OnDestroy, Provider } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { PaginationEvent } from '@app/shared/components/pagination/pagination.component';
import { Sort } from '@app/shared/interfaces';
import { Pagination } from '@app/shared/interfaces/pagination.interface';
import { isDefined } from '@app/shared/utils/types.utils';
import { BehaviorSubject, combineLatest, distinctUntilChanged, filter, map, Observable, ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { NavigationQueryService } from './navigation-query.service';

export type PaginationLoadType = 'reset' | 'more';

export const TABLE_NAVIGATION_DEFAULT = new InjectionToken('Table navigation');

export interface TableNavigationDefault<FILTER extends object = object> {
  pagination: Pagination;
  sorting: Sort | null;
  filters: FILTER;
}

@Injectable()
export class TableNavigationQueryService<FILTER extends object = object> implements OnDestroy {
  pagination$: Observable<Pagination>;

  onPage$: Observable<number | undefined>;

  page$: Observable<number | undefined>;

  filters$: Observable<FILTER>;

  sorting$: Observable<Sort | null>;

  total$: Observable<number>;

  hasMore$: Observable<boolean>;

  paramsChanged$: Observable<unknown>;

  loadType: PaginationLoadType = 'reset';

  loadingMore = false;

  protected totalSubject = new BehaviorSubject<number | null>(null);

  protected paginationSubject = new BehaviorSubject<Pagination | null>(null);

  protected sortingSubject = new ReplaySubject<Sort | null>(1);

  protected filtersSubject = new BehaviorSubject<FILTER | null>(null);

  protected destroy$ = new ReplaySubject<void>(1);

  public get pagination(): Pagination | null {
    return this.paginationSubject.value;
  }

  public set pagination(pagination: Pagination) {
    this.navigationQueryService.navigateDebounced({
      onPage: pagination.onPage,
      page: pagination.page,
    });
  }

  public get filters(): FILTER | null {
    return this.filtersSubject.value;
  }

  public get total(): number | null {
    return this.totalSubject.value;
  }

  constructor(
    @Inject(TABLE_NAVIGATION_DEFAULT) protected defaultParams: TableNavigationDefault<FILTER>,
    private readonly activatedRoute: ActivatedRoute,
    private readonly navigationQueryService: NavigationQueryService,
  ) {
    this.activatedRoute.queryParams.pipe(takeUntil(this.destroy$)).subscribe((query) => this.parseQueryParams(query));

    this.pagination$ = this.paginationSubject.pipe(
      filter(Boolean),
      distinctUntilChanged((previous, current) => previous.page === current.page && previous.onPage === current.onPage),
    );

    this.onPage$ = this.pagination$.pipe(map((pagination) => pagination.onPage));

    this.page$ = this.pagination$.pipe(map((pagination) => pagination.page));

    this.sorting$ = this.sortingSubject.pipe(
      distinctUntilChanged(
        (previous, current) => previous?.active === current?.active && previous?.direction === current?.direction,
      ),
    );

    this.filters$ = this.filtersSubject
      .asObservable()
      .pipe(filter((value): value is NonNullable<typeof value> => isDefined(value)));

    this.total$ = this.totalSubject
      .asObservable()
      .pipe(filter((total): total is NonNullable<typeof total> => isDefined(total)));

    this.hasMore$ = combineLatest([this.pagination$, this.total$]).pipe(
      map(([{ page, onPage }, total]) => total > ((page || 0) + 1) * (onPage || 0)),
    );

    this.paramsChanged$ = combineLatest([this.pagination$, this.filters$, this.sorting$]);
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public handlePageEvent(event: PaginationEvent): void {
    this.loadType = 'reset';
    this.loadingMore = false;

    this.navigationQueryService.navigateDebounced({
      onPage: event.pageSize,
      page: event.pageIndex,
    });
  }

  public setPagination(pagination: Pagination): void {
    this.loadType = 'reset';
    this.loadingMore = false;

    this.navigationQueryService.navigateDebounced(pagination);
  }

  public handleFilterEvent(filters: FILTER): void {
    this.loadingMore = false;
    this.loadType = 'reset';
    let filtersQuery: string | null = null;

    if (filters && Object.values(filters).filter(isDefined).length) {
      filtersQuery = JSON.stringify(filters);
    }

    this.navigationQueryService.navigateDebounced({
      filters: filtersQuery,
      page: 0,
    });
  }

  public handleSortEvent(sorting: Sort): void {
    if (sorting.active && sorting.direction) {
      this.navigationQueryService.navigateDebounced({
        sortField: sorting.active,
        sortDirection: sorting.direction,
      });
    } else {
      this.navigationQueryService.navigateDebounced({
        sortField: null,
        sortDirection: null,
      });
    }
  }

  public handleTotalChanged(total: number): void {
    this.totalSubject.next(total);
  }

  public loadMore(): void {
    if (this.loadingMore) {
      return;
    }

    this.loadType = 'more';
    this.loadingMore = true;
    this.navigationQueryService.navigateDebounced({
      page: (this.pagination?.page || 0) + 1,
    });
  }

  public handleLoadedMore(): void {
    this.loadType = 'reset';
    this.loadingMore = false;
  }

  protected parseQueryParams(query: Params): void {
    this.paginationSubject.next({
      onPage: this.parseQueryNumber(query['onPage'], this.defaultParams.pagination.onPage),
      page: this.parseQueryNumber(query['page'], this.defaultParams.pagination.page),
    });

    const sort: Sort = {
      active: query['sortField'] || this.defaultParams.sorting?.active,
      direction: query['sortDirection'] || this.defaultParams.sorting?.direction,
    };

    this.sortingSubject.next(sort.direction && sort.active ? sort : null);
    this.filtersSubject.next(this.parseQueryObject(query['filters'], this.defaultParams.filters) as FILTER);
  }

  protected parseQueryNumber(value: unknown, defaultValue: number | undefined): number | undefined {
    if (typeof value === 'string' && !isNaN(+value)) {
      return +value;
    } else if (typeof value === 'number') {
      return value;
    }

    return defaultValue;
  }

  protected parseQueryObject(value: unknown, defaultValue: object): object {
    if (typeof value === 'string' && value) {
      try {
        return JSON.parse(value);
      } catch (error) {
        return {};
      }
    }

    return defaultValue;
  }
}

export function provideTableNavigation<FILTER extends object = object>(
  defaultValue: TableNavigationDefault<FILTER> = {
    pagination: {
      onPage: 10,
      page: 0,
    },
    sorting: null,
    filters: {} as FILTER,
  },
): Provider {
  return {
    provide: TableNavigationQueryService,
    useFactory: (activatedRoute: ActivatedRoute, navigationQueryService: NavigationQueryService) =>
      new TableNavigationQueryService<FILTER>(defaultValue, activatedRoute, navigationQueryService),
    deps: [ActivatedRoute, NavigationQueryService],
  };
}
