import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiResponseDto } from '@app/core/api';
import { buildHttpParams } from '@app/shared/utils';
import { Observable } from 'rxjs';

import { ApiPaginatedResponseDto } from '../common-dtos/api-paginated-response.dto';
import {
  PaymentCardCreateRequestDto,
  PaymentCardDto,
  PaymentCardsFindRequestDto,
  PaymentDto,
  PaymentFormDto,
  PaymentRememberCardRequestDto,
  TransactionIncomingDto,
  TransactionIncomingRequestDto,
  TransactionOutgoingDto,
  TransactionOutgoingRequestDto,
} from './dtos';

@Injectable({ providedIn: 'root' })
export class PaymentApiService {
  constructor(private readonly http: HttpClient) {}

  public addCard(request: PaymentCardCreateRequestDto): Observable<ApiResponseDto<PaymentFormDto>> {
    return this.http.post<ApiResponseDto<PaymentFormDto>>('/api/profile/payment-cards', request);
  }

  public findCards(
    request: PaymentCardsFindRequestDto,
    bypassCache?: boolean,
  ): Observable<ApiResponseDto<ApiPaginatedResponseDto<PaymentCardDto>>> {
    let params = buildHttpParams(request);

    if (bypassCache) {
      params = params?.append('t', Date.now());
    }

    return this.http.get<ApiResponseDto<ApiPaginatedResponseDto<PaymentCardDto>>>('/api/profile/payment-cards', {
      params,
    });
  }

  public setMainCard(id: string): Observable<ApiResponseDto<PaymentCardDto>> {
    return this.http.put<ApiResponseDto<PaymentCardDto>>(`/api/profile/payment-cards/${id}/set-main`, {});
  }

  public deleteCard(id: string): Observable<unknown> {
    return this.http.delete(`/api/profile/payment-cards/${id}`);
  }

  public checkPayment(paymentId: string): Observable<ApiResponseDto<PaymentDto | null>> {
    return this.http.get<ApiResponseDto<PaymentDto>>('/api/payments/check', {
      params: {
        paymentId,
        t: Date.now(),
      },
    });
  }

  public rememberPaymentCard(request: PaymentRememberCardRequestDto): Observable<unknown> {
    return this.http.post<unknown>('/api/payments/remember-card', request);
  }

  public findIncomingTransactions(
    request: TransactionIncomingRequestDto,
  ): Observable<ApiResponseDto<ApiPaginatedResponseDto<TransactionIncomingDto>>> {
    return this.http.get<ApiResponseDto<ApiPaginatedResponseDto<TransactionIncomingDto>>>(
      '/api/profile/transactions/incoming',
      {
        params: buildHttpParams(request),
      },
    );
  }

  public findOutgoingTransactions(
    request: TransactionOutgoingRequestDto,
  ): Observable<ApiResponseDto<ApiPaginatedResponseDto<TransactionOutgoingDto>>> {
    return this.http.get<ApiResponseDto<ApiPaginatedResponseDto<TransactionOutgoingDto>>>(
      '/api/profile/transactions/outgoing',
      {
        params: buildHttpParams(request),
      },
    );
  }
}
