import firebase from 'firebase/compat/app';
import { Observable } from 'rxjs';

export type NotificationMessagePush = firebase.messaging.MessagePayload;

export abstract class NotificationsMessagingProvider {
  abstract foregroundMessage$: Observable<NotificationMessagePush>;

  abstract backgroundMessage$: Observable<NotificationMessagePush>;

  public abstract deleteToken(): Observable<void>;

  public abstract getToken(): Observable<string | null>;
}
