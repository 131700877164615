import { Directive, EventEmitter, HostListener, Input, Output, Self } from '@angular/core';
import { DestroyService, validateAccept } from '@app/shared/utils';
import { debounceTime, fromEvent, merge } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Directive({
  selector: '[appFileDrop]',
  exportAs: 'appFileDrop',
  providers: [DestroyService],
})
export class FileDropDirective {
  @Input() accept: string | string[] | null = null;

  @Output() filesDropped = new EventEmitter<FileList>();

  @Output() draggedIn = new EventEmitter<void>();

  @Output() draggedOut = new EventEmitter<void>();

  constructor(@Self() private readonly destroy$: DestroyService) {}

  @HostListener('dragover', ['$event'])
  public handleDragOver(event: DragEvent): void {
    if (!event.target || !event.currentTarget) {
      return;
    }

    event.preventDefault();
    event.stopPropagation();

    this.draggedIn.emit();

    const unsubscribe$ = merge(this.draggedIn, this.draggedOut, this.destroy$);

    fromEvent<DragEvent>(event.currentTarget, 'dragleave')
      .pipe(debounceTime(100), takeUntil(unsubscribe$))
      .subscribe((dragleaveEvent) => {
        dragleaveEvent.stopPropagation();
        dragleaveEvent.preventDefault();

        this.draggedOut.emit();
      });
  }

  @HostListener('drop', ['$event'])
  public handleDrop(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();

    const files = event.dataTransfer?.files ? this.filterAcceptFiles(event.dataTransfer.files) : null;

    if (files && files.length > 0) {
      this.filesDropped.next(files);
    }

    this.draggedOut.emit();
  }

  protected filterAcceptFiles(files: FileList): FileList {
    const validFiles = new DataTransfer();

    for (let i = 0; i < files.length; i++) {
      const file = files.item(i);

      if (file && validateAccept(file, this.accept)) {
        validFiles.items.add(file);
      }
    }

    return validFiles.files;
  }
}
