import { FileDto } from '../../file/dtos';

export enum NotificationAuthorTypeEnum {
  System = 'system',
  User = 'user',
}

export interface AppNotificationAuthorDto {
  userId: string;
  slug: string;
  name: string | null;
  profession: string | null;
  avatar: FileDto | null;
}

export interface AppNotificationDto {
  id: string;
  notificationId: string;
  authorType: NotificationAuthorTypeEnum;
  authorId: string | null;
  author: AppNotificationAuthorDto | null;
  authorAvatar: FileDto | null;
  title: string;
  text: string;
  textHtml: string;
  deliveryDate: string | null;
  link: string | null;
  payload: object;
  isSeen: boolean;
  seenAt: string | null;
  createdAt: string;
  updatedAt: string;
}
