/* eslint-disable @typescript-eslint/no-explicit-any */
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { environment } from '@app/environment';
import { fromEvent, Observable, tap } from 'rxjs';

type Ym = (...args: any[]) => void;

interface YmParams {
  order_price?: number;
  currency?: string;
}

interface YmHitOptions {
  callback?: () => void;
  ctx?: object;
  params?: YmParams;
  referer?: string;
  title?: string;
}

@Injectable({ providedIn: 'root' })
export class YaMetrikaAgentService {
  ym?: Ym;

  protected ymReady = false;

  constructor(
    @Inject(PLATFORM_ID) private readonly platformId: string,
    @Inject(DOCUMENT) document: Document,
  ) {
    if (isPlatformBrowser(platformId) && !!(window as any).ym && typeof (window as any).ym === 'function') {
      this.ym = (window as any).ym;

      if ((window as any).ymReady) {
        this.ymReady = true;
      } else {
        fromEvent(document, 'yacounter97451434inited', { once: true })
          .pipe(tap(() => (this.ymReady = true)))
          .subscribe();
      }
    }
  }

  public hit(url?: string, options?: YmHitOptions): void {
    if (this.ym) {
      try {
        this.ym(environment.ymCounterId, 'hit', url, options);
      } catch (error) {
        console.error(error);

        if (typeof options?.callback === 'function') {
          options.callback();
        }
      }
    } else {
      if (typeof options?.callback === 'function') {
        options.callback();
      }
    }
  }

  public hitAsObservable(url?: string, options?: Omit<YmHitOptions, 'callback'>): Observable<void> {
    return new Observable<void>((subscriber) => {
      if (this.ymReady) {
        this.hit(url, {
          ...options,
          callback: () => {
            subscriber.next();
            subscriber.complete();
          },
        });
      } else {
        this.hit(url, options);
        subscriber.next();
        subscriber.complete();
      }
    });
  }

  public reachGoal(targetName: string, params?: YmParams, callback?: () => void): void {
    if (this.ym) {
      try {
        this.ym(environment.ymCounterId, 'reachGoal', targetName, params, callback);
      } catch (error) {
        console.error(error);

        if (typeof callback === 'function') {
          callback();
        }
      }
    } else {
      if (typeof callback === 'function') {
        callback();
      }
    }
  }

  public reachGoalAsObservable(targetName: string, params?: YmParams): Observable<void> {
    return new Observable<void>((subscriber) => {
      if (this.ymReady) {
        this.reachGoal(targetName, params, () => {
          subscriber.next();
          subscriber.complete();
        });
      } else {
        this.reachGoal(targetName, params);
        subscriber.next();
        subscriber.complete();
      }
    });
  }
}
