import { Dialog } from '@angular/cdk/dialog';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthApiExceptionCodeEnum, extractApiException } from '@app/core/api/exceptions';
import { ProfileEmailConfirmationDialogComponent } from '@app/features/profile/profile-email-confirmation-dialog/profile-email-confirmation-dialog.component';
import { IgnoreSnackbarException } from '@app/shared/components/snack-bar';
import { catchError, Observable, throwError } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ApiAuthErrorInterceptor implements HttpInterceptor {
  constructor(private readonly dialog: Dialog) {}

  public intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(req).pipe(
      catchError((error) => {
        const apiException = extractApiException(error);

        if (apiException && apiException.meta.code === AuthApiExceptionCodeEnum.EmailNotConfirmed) {
          ProfileEmailConfirmationDialogComponent.open(this.dialog);
          return throwError(() => new IgnoreSnackbarException(error));
        }

        return throwError(error);
      }),
    );
  }
}
