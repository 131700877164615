import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { canActivateAuthorized } from '@app/core/auth';
import { authRoutingGuardFactory } from '@app/core/auth/guards/auth-routing.guard';
import { provideFooterData } from '@app/core/layout/footer/footer-params.provider';
import { provideHeaderData } from '@app/core/layout/header/header-params.provider';
import { canActivateProfileCompleted } from '@app/features/profile/guards';
import { NotFoundPageComponent } from '@app/pages/not-found-page/not-found-page.component';

const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () => import('@app/pages/auth-pages/auth-pages.module').then((m) => m.AuthPagesModule),
    data: {
      ...provideHeaderData({ hidden: true }),
      ...provideFooterData({ hidden: true }),
    },
  },
  {
    path: 'oauth',
    loadChildren: () => import('@app/pages/oauth-pages/oauth-pages.module').then((m) => m.OauthPagesModule),
    data: {
      ...provideHeaderData({ hidden: true }),
      ...provideFooterData({ hidden: true }),
    },
  },
  {
    path: 'about',
    loadChildren: () => import('@app/pages/landing-page/landing-page.module').then((m) => m.LandingPageModule),
    data: {
      ...provideHeaderData({ container: true }),
      ...provideFooterData({ container: true }),
    },
    title: '«Цели» — социально-инвестиционная платформа | О платформе',
  },
  {
    path: '',
    pathMatch: 'full',
    loadChildren: () => import('@app/pages/landing-page/landing-page.module').then((m) => m.LandingPageModule),
    canActivate: [
      authRoutingGuardFactory({
        authenticatedRoute: ['/catalog'],
        notAuthenticatedRoute: ['/about'],
      }),
    ],
    title: '«Цели» — социально-инвестиционная платформа | Главная',
  },
  {
    path: 'profile/wizard',
    loadChildren: () =>
      import('@app/pages/profile-wizard-pages/profile-wizard-pages.module').then((m) => m.ProfileWizardPagesModule),
    canActivate: [canActivateAuthorized],
    data: {
      ...provideFooterData({ hidden: true }),
    },
    title: '«Цели» — социально-инвестиционная платформа | Заполнить профиль',
  },
  {
    path: 'profile',
    loadChildren: () => import('@app/pages/profile-page/profile-page.module').then((m) => m.ProfilePageModule),
    canActivate: [canActivateAuthorized],
    data: {
      ...provideHeaderData({ container: true }),
      ...provideFooterData({ container: true }),
    },
  },
  {
    path: 'goal/wizard',
    loadChildren: () =>
      import('@app/pages/goal-wizard-pages/goal-wizard-pages.module').then((m) => m.GoalWizardPagesModule),
    canActivate: [canActivateAuthorized, canActivateProfileCompleted],
    data: {
      ...provideHeaderData({ hideCreateGoalButton: true }),
      ...provideFooterData({ hidden: true }),
    },
    title: '«Цели» — социально-инвестиционная платформа | Создать цель',
  },
  {
    path: 'catalog',
    loadChildren: () => import('@app/pages/catalog-page/catalog-page.module').then((m) => m.CatalogPageModule),
    data: {
      ...provideHeaderData({ container: true }),
      ...provideFooterData({ container: true }),
    },
  },
  {
    path: 'docs',
    loadChildren: () => import('@app/pages/docs-pages/docs-pages.module').then((m) => m.DocsPagesModule),
    data: {
      ...provideHeaderData({ container: true }),
      ...provideFooterData({ container: true }),
    },
  },
  {
    path: 'notifications',
    loadChildren: () =>
      import('@app/pages/notifications-pages/notifications-pages.module').then((m) => m.NotificationsPagesModule),
    canActivate: [canActivateAuthorized],
    data: {
      ...provideHeaderData({ container: true }),
      ...provideFooterData({ container: true }),
    },
  },
  {
    path: 'faq',
    loadChildren: () => import('@app/pages/faq-pages/faq-pages.module').then((m) => m.FaqPagesModule),
    data: {
      ...provideHeaderData({ container: true }),
      ...provideFooterData({ container: true }),
    },
  },
  {
    path: 'finance',
    loadChildren: () => import('@app/pages/finance-pages/finance-pages.module').then((m) => m.FinancePagesModule),
    canActivate: [canActivateAuthorized],
    data: {
      ...provideHeaderData({ container: true }),
      ...provideFooterData({ hidden: true }),
    },
  },
  {
    path: 'goals',
    loadChildren: () => import('@app/pages/goals-pages/goals-pages.module').then((m) => m.GoalsPagesModule),
    data: {
      ...provideHeaderData({ container: true }),
      ...provideFooterData({ container: true }),
    },
  },
  {
    path: 'news',
    loadChildren: () => import('@app/pages/news-pages/news-pages.module').then((m) => m.NewsPagesModule),
    data: {
      ...provideHeaderData({ container: true }),
      ...provideFooterData({ container: true }),
    },
  },
  {
    path: 'stories',
    loadChildren: () => import('@app/pages/stories-pages/stories-pages.module').then((m) => m.StoriesPagesModule),
    data: {
      ...provideHeaderData({ container: true }),
      ...provideHeaderData({ container: true }),
    },
  },
  {
    path: '404',
    component: NotFoundPageComponent,
    data: {
      ...provideFooterData({ hidden: true }),
    },
  },
  {
    path: 'settings',
    loadChildren: () => import('@app/pages/settings-pages/settings-pages.module').then((m) => m.SettingsPagesModule),
    canActivate: [canActivateAuthorized],
    data: {
      ...provideHeaderData({ container: true }),
      ...provideFooterData({ hidden: true }),
    },
  },
  {
    path: '**',
    component: NotFoundPageComponent,
    data: {
      ...provideFooterData({ hidden: true }),
    },
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabledBlocking',
      paramsInheritanceStrategy: 'always',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
