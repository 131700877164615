import { Injectable, OnDestroy } from '@angular/core';

import { BodyOverflowStateService } from './body-overflow-state.service';

let uniqueId = 0;

@Injectable()
export class BodyOverflowItemService implements OnDestroy {
  id = uniqueId++;

  constructor(private readonly state: BodyOverflowStateService) {}

  public ngOnDestroy(): void {
    this.unblock();
  }

  public block(): void {
    this.state.block(this.id);
  }

  public unblock(): void {
    this.state.unblock(this.id);
  }
}
