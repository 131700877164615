import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiResponseDto } from '@app/core/api/common-dtos';
import { buildHttpParams } from '@app/shared/utils';
import { Observable } from 'rxjs';

import {
  AuthCheckRegisteredRequestDto,
  AuthCheckRegisteredResponseDto,
  AuthExternalLoginResponseDto,
  AuthExternalProviderEnum,
  AuthExternalStartRequestDto,
  AuthExternalStartResponseDto,
  ChangePasswordTokenRequestDto,
  ConfirmEmailRequestDto,
  ConfirmEmailResendRequestDto,
  ImpersonateRequestDto,
  LoginRequestDto,
  QuickRegisterRequestDto,
  RegisterRequestDto,
  RequestPasswordRestoreRequestDto,
} from './dtos';

@Injectable({ providedIn: 'root' })
export class AuthApiService {
  constructor(private readonly http: HttpClient) {}

  public login(request: LoginRequestDto): Observable<unknown> {
    return this.http.post('/api/auth/login', request);
  }

  public register(request: RegisterRequestDto): Observable<unknown> {
    return this.http.post('/api/auth/register', request);
  }

  public logout(): Observable<unknown> {
    return this.http.post('/api/auth/logout', {});
  }

  public confirmEmail(request: ConfirmEmailRequestDto): Observable<unknown> {
    return this.http.post('/api/auth/email-confirm', request);
  }

  public requestPasswordRestore(request: RequestPasswordRestoreRequestDto): Observable<unknown> {
    return this.http.post('/api/auth/request-password-restore', request);
  }

  public changePasswordToken(request: ChangePasswordTokenRequestDto): Observable<unknown> {
    return this.http.post('/api/auth/change-password-token', request);
  }

  public impersonate(request: ImpersonateRequestDto): Observable<unknown> {
    return this.http.get('/api/auth/impersonate', {
      params: buildHttpParams(request),
    });
  }

  public emailConfirmResend(request: ConfirmEmailResendRequestDto): Observable<unknown> {
    return this.http.post('/api/auth/email-confirm-resend', request);
  }

  public checkRegistered(
    request: AuthCheckRegisteredRequestDto,
  ): Observable<ApiResponseDto<AuthCheckRegisteredResponseDto>> {
    return this.http.get<ApiResponseDto<AuthCheckRegisteredResponseDto>>('/api/auth/check-registered', {
      params: buildHttpParams(request),
    });
  }

  public quickRegister(request: QuickRegisterRequestDto): Observable<unknown> {
    return this.http.post(`/api/auth/quick-register`, request);
  }

  public externalAuthStart(
    provider: AuthExternalProviderEnum,
    request: AuthExternalStartRequestDto,
  ): Observable<ApiResponseDto<AuthExternalStartResponseDto>> {
    return this.http.post<ApiResponseDto<AuthExternalStartResponseDto>>(
      `/api/auth/external/${provider}/start`,
      request,
    );
  }

  public externalLogin(
    provider: AuthExternalProviderEnum,
    redirectQuery: object,
  ): Observable<ApiResponseDto<AuthExternalLoginResponseDto>> {
    return this.http.post<ApiResponseDto<AuthExternalLoginResponseDto>>(
      `/api/auth/external/${provider}/login`,
      redirectQuery,
    );
  }

  public externalRegister(
    provider: AuthExternalProviderEnum,
    redirectQuery: object,
  ): Observable<ApiResponseDto<AuthExternalLoginResponseDto>> {
    return this.http.post<ApiResponseDto<AuthExternalLoginResponseDto>>(
      `/api/auth/external/${provider}/register`,
      redirectQuery,
    );
  }
}
