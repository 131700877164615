import { HttpParams } from '@angular/common/http';

export function buildHttpParams<T extends object>(obj?: T, bypassCache?: boolean): HttpParams | undefined {
  let params = new HttpParams();

  if (obj === null || obj === undefined) {
    return;
  }

  if (typeof obj !== 'object') {
    return undefined;
  }

  Object.keys(obj).forEach((key: string) => {
    const value =
      typeof (<Record<string, unknown>>obj)[key] === 'string'
        ? ((<Record<string, unknown>>obj)[key] as string).trim()
        : (<Record<string, unknown>>obj)[key];

    if (typeof value !== 'undefined' && value !== null) {
      if (Array.isArray(value)) {
        value.forEach((data) => (params = params.append(`${key}`, data)));
      } else {
        params = params.append(`${key}`, value as string);
      }
    }
  });

  if (bypassCache) {
    params = params.append('_t', Date.now());
  }

  return params;
}
