import { DialogModule } from '@angular/cdk/dialog';
import { CommonModule } from '@angular/common';
import { DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule } from '@angular/core';
import { CategoryStoreModule } from '@app/features/category/category-store';
import { GoalCurrentModule } from '@app/features/goal/goal-current';
import { SnackBarModule } from '@app/shared/components/snack-bar';
import { register as registerSwiper } from 'swiper/element/bundle';

import { AnalyticsModule } from './analytics/analytics.module';
import { ApiModule } from './api';
import { AuthModule } from './auth';
import { LoaderModule } from './loader';
import { MediaObserverModule } from './media-observer';
import { MetaModule } from './meta';
import { NavigationModule } from './navigation';
import { APP_SVG_ICON_PATH } from './tokens';
import { UpdaterModule } from './updater/updater.module';

registerSwiper();

@NgModule({
  imports: [
    CommonModule,
    SnackBarModule,
    UpdaterModule,
    MediaObserverModule.forRoot({
      xs: 0,
      sm: 575.99,
      md: 767.99,
      lg: 1023.99,
      xl: 1279.99,
      xxl: 1299.99,
      xxxl: 1599.99,
    }),
    NavigationModule.forRoot(),
    MetaModule,
    AuthModule.forRoot(),
    ApiModule.forRoot(),
    LoaderModule,
    GoalCurrentModule,
    CategoryStoreModule.forRoot(),
    DialogModule,
    AnalyticsModule.forRoot(),
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'ru' },
    {
      provide: DEFAULT_CURRENCY_CODE,
      useValue: 'RUB',
    },
    {
      provide: APP_SVG_ICON_PATH,
      useValue: '/assets/icons/svg',
    },
  ],
})
export class CoreModule {}
