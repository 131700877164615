import { Directive } from '@angular/core';

@Directive({
  selector: `[snackBarLabel]`,
  standalone: true,
  host: {
    class: 'snack-bar-label snackbar__label',
  },
})
export class SnackBarLabel {}
