import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter, Observable } from 'rxjs';

import { YaMetrikaAgentService } from './ya-metrika-agent.service';

@Injectable({ providedIn: 'root' })
export class AnalyticsService {
  constructor(
    private readonly yaMetrika: YaMetrikaAgentService,
    private readonly router: Router,
    @Inject(PLATFORM_ID) private readonly platformId: string,
  ) {}

  public init(): void {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }

    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
      this.yaMetrika.hit();
    });
  }

  public donateStart(amount: number): Observable<void> {
    return this.yaMetrika.reachGoalAsObservable('DONATE_START', { order_price: amount, currency: 'RUB' });
  }
}
