export interface CookieOptions {
  expires?: string | Date | null;
  path?: string | null;
  domain?: string | null;
  secure?: boolean | null;
  maxAge?: number | null;
  sameSite?: 'strict' | 'lax' | 'none' | null;
}

export class CookieOptionsBuilder {
  protected params: string[] = [];

  public setString(key: string, value: string | null | undefined): CookieOptionsBuilder {
    if (value) {
      this.params.push(`${key}=${value}`);
    }

    return this;
  }

  public setBoolean(key: string, value: boolean | null | undefined): CookieOptionsBuilder {
    if (value) {
      this.params.push(key);
    }

    return this;
  }

  public setDate(key: string, value: string | Date | null | undefined): CookieOptionsBuilder {
    if (value) {
      switch (typeof value) {
        case 'string':
          return this.setString(key, value);
        case 'object':
          return this.setString(key, value.toISOString());
      }
    }

    return this;
  }

  public setNumber(key: string, value: number | null | undefined): CookieOptionsBuilder {
    if (value !== null || typeof value !== 'undefined') {
      this.setString(key, `${value}`);
    }

    return this;
  }

  public build(): string {
    return this.params.filter(Boolean).join('; ');
  }
}
