import { CategoryDto } from '@app/core/api/category/dtos';
import { ApiPaginatedResponseDto } from '@app/core/api/common-dtos/api-paginated-response.dto';
import { FileDto } from '@app/core/api/file';
import { ApiContentBlockContainerDto } from '@app/core/api/misc';

import { CityDto } from '../../catalog';

export enum ProfileContactType {
  Vk = 'vk',
  Telegram = 'telegram',
  Viber = 'viber',
  Whatsapp = 'whatsapp',
  Site = 'site',
  Email = 'email',
  Address = 'address',
  Ok = 'ok',
  Youtube = 'youtube',
}

export enum ContactPrivacyEnum {
  All = 'all',
  Subscribers = 'subscribers',
  None = 'none',
}

export interface ProfileContactDto {
  link: string;
  name: string;
  type: ProfileContactType;
  privacy?: ContactPrivacyEnum;
}

export interface ProfileMemberDto {
  name: string | null;
  position: string | null;
}

export interface SubscriptionDto {
  userId: string;
  slug: string;
  firstName: string | null;
  lastName: string | null;
  profession: string | null;
  avatar: FileDto | null;
}

export interface ProfileBusinessDto {
  /** @deprecated */
  description?: string | null;
  /** @deprecated */
  gallery?: FileDto[];
  members?: ProfileMemberDto[];
  title?: string | null;
  userId: string;
  content: ApiContentBlockContainerDto[];
}

export interface ProfileDto {
  userId: string;
  slug: string;
  firstName: string | null;
  lastName: string | null;
  /** ISO */
  birthDate: string | null;
  contactEmail: string | null;
  contactPhone: string | null;
  contacts: ProfileContactDto[];
  categories: CategoryDto[];
  avatar: FileDto | null;
  cover: FileDto | null;
  catalogImage: FileDto | null;
  business?: ProfileBusinessDto;
  profession: string | null;
  followers: { count: number };
  subscriptions: { count: number };
  subscribedTo?: ApiPaginatedResponseDto<SubscriptionDto>;
  followedBy?: ApiPaginatedResponseDto<SubscriptionDto>;
  fullName?: string | null;
  contactEmailPrivacy: ContactPrivacyEnum;
  city?: CityDto | null;
  createdAt: string;
  updatedAt: string;
}

export interface ProfileCurrentDto extends ProfileDto {
  email: string;
  emailVerified: boolean;
}
