import { Dialog, DialogRef } from '@angular/cdk/dialog';
import { ChangeDetectionStrategy, Component, Self } from '@angular/core';
import { AuthApiService } from '@app/core/api/auth';
import { AuthService } from '@app/core/auth';
import { LoaderService } from '@app/core/loader';
import { DestroyService } from '@app/shared/utils';
import { BehaviorSubject, tap } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-profile-email-confirmation-dialog',
  templateUrl: './profile-email-confirmation-dialog.component.html',
  styleUrls: ['./profile-email-confirmation-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DestroyService],
})
export class ProfileEmailConfirmationDialogComponent {
  sent$ = new BehaviorSubject<boolean>(false);

  email?: string;

  constructor(
    private readonly authService: AuthService,
    private readonly authApi: AuthApiService,
    private readonly dialogRef: DialogRef,
    private readonly loaderService: LoaderService,
    @Self() private readonly destroy$: DestroyService,
  ) {}

  public static open(dialog: Dialog): DialogRef<void> {
    return dialog.open<void>(ProfileEmailConfirmationDialogComponent, {});
  }

  public close(): void {
    this.dialogRef.close();
  }

  public resend(): void {
    const login = this.authService.profile?.email;

    if (login) {
      this.email = login;
      const source$ = this.authApi.emailConfirmResend({ login }).pipe(tap(() => this.sent$.next(true)));

      this.loaderService.doLoading(source$, this, 'RESEND_REQUEST').pipe(takeUntil(this.destroy$)).subscribe();
    } else {
      console.warn(`email is not defined`);
    }
  }
}
