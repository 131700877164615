<button
  class="btn-header-notification"
  [class._active]="panelOpen"
  type="button"
  app-icon-button
  cdkOverlayOrigin
  #origin="cdkOverlayOrigin"
  (click)="toggle()"
>
  <svg-icon src="notification"></svg-icon>
  <span class="btn-header-notification__badge" *ngIf="unreadCount$ | async as unreadCount">
    {{ unreadCount > 999 ? 999 : unreadCount }}
  </span>
</button>

<ng-template
  cdk-connected-overlay
  cdkConnectedOverlayLockPosition
  cdkConnectedOverlayHasBackdrop
  cdkConnectedOverlayBackdropClass="cdk-overlay-transparent-backdrop"
  [cdkConnectedOverlayOrigin]="origin"
  [cdkConnectedOverlayOpen]="panelOpen"
  [cdkConnectedOverlayPositions]="positions"
  (backdropClick)="close()"
  (detach)="close()"
>
  <ng-container *appLet="unreadCount$ | async as unreadCount">
    <div tabindex="-1" class="notifications-menu _opened" *appLet="unread$ | async as unread">
      <div class="notifications-menu-header">
        <div class="notifications-menu-header__title">
          <span>Уведомления</span>
          <span class="_count">{{ unreadCount }}</span>
        </div>
        <div class="notifications-menu-header__actions" *ngIf="unread && unread.length">
          <button type="button" app-link (click)="read(unread)">Прочитать</button>
        </div>
      </div>
      <ng-container *ngIf="unreadCount; else emptyNotifications">
        <app-notification-preview-item
          *ngFor="let item of unread; trackBy: trackNotification"
          [notification]="item"
        ></app-notification-preview-item>
      </ng-container>
      <div class="notifications-menu-footer">
        <a type="button" app-link [routerLink]="['/notifications']">
          {{ unreadCount ? 'Показать все' : 'Показать более ранние' }}
        </a>
      </div>
    </div>
  </ng-container>
</ng-template>

<ng-template #emptyNotifications>
  <div class="notifications-menu-empty">Новых уведомлений нет</div>
</ng-template>
