import { Injectable } from '@angular/core';

import { SnackBar } from './snack-bar';

@Injectable({ providedIn: 'root' })
export class SnackBarService {
  constructor(private snackBar: SnackBar) {}

  public showInfoMessage(message: string, duration = 4000): void {
    this.snackBar.open(message, {
      panelClass: ['_info'],
      duration,
      verticalPosition: 'bottom',
      horizontalPosition: 'end',
    });
  }

  public showSuccessMessage(message: string, duration = 4000): void {
    this.snackBar.open(message, {
      panelClass: ['_success'],
      duration,
      verticalPosition: 'bottom',
      horizontalPosition: 'end',
    });
  }

  public showWarningMessage(message: string, duration = 5000): void {
    this.snackBar.open(message, {
      panelClass: ['_warning'],
      duration,
      verticalPosition: 'bottom',
      horizontalPosition: 'end',
    });
  }

  public showDangerMessage(message: string, duration = 5000): void {
    this.snackBar.open(message, {
      panelClass: ['_danger'],
      duration,
      verticalPosition: 'bottom',
      horizontalPosition: 'end',
    });
  }
}
