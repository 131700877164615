import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiResponseDto } from '@app/core/api';
import { ApiPaginatedResponseDto } from '@app/core/api/common-dtos/api-paginated-response.dto';
import { extractApiException, GoalApiExceptionCodeEnum } from '@app/core/api/exceptions';
import { buildHttpParams } from '@app/shared/utils';
import { catchError, Observable, of, throwError } from 'rxjs';

import { ScoringCreateRequestDto, ScoringDto } from '../scoring';
import {
  GoalAmountLimitDto,
  GoalBatchIdRequestDto,
  GoalBatchSponsorsRequestDto,
  GoalBatchSponsorsResponseDto,
  GoalCreateRequestDto,
  GoalDonateRequestDto,
  GoalDonateResponseDto,
  GoalDto,
  GoalMyRequestDto,
  GoalMyResponseDto,
  GoalPayoutDto,
  GoalPayoutInfoDto,
  GoalReportCreateRequestDto,
  GoalReportDto,
  GoalReportsFindRequestDto,
  GoalReportUpdateRequestDto,
  GoalRequestPayoutRequestDto,
  GoalReviewCommentDto,
  GoalReviewCommentsFindRequestDto,
  GoalsFindRequestDto,
  GoalSponsorDto,
  GoalSponsorsRequestDto,
  GoalStatsDto,
  GoalUpdateRequestDto,
} from './dtos';
import { GoalUpsertScoringResponseDto } from './dtos/goal-upsert-scoring.response.dto';

@Injectable({ providedIn: 'root' })
export class GoalApiService {
  constructor(private readonly http: HttpClient) {}

  public getMyGoals(request: GoalMyRequestDto, bypassCache?: boolean): Observable<ApiResponseDto<GoalMyResponseDto>> {
    return this.http.get<ApiResponseDto<GoalMyResponseDto>>('/api/goals/my', {
      params: buildHttpParams(request, bypassCache),
    });
  }

  public findGoals(request: GoalsFindRequestDto): Observable<ApiResponseDto<ApiPaginatedResponseDto<GoalDto>>> {
    return this.http.get<ApiResponseDto<ApiPaginatedResponseDto<GoalDto>>>('/api/goals', {
      params: buildHttpParams(request),
    });
  }

  public getGoal(id: string): Observable<ApiResponseDto<GoalDto | null>> {
    return this.http.get<ApiResponseDto<GoalDto | null>>(`/api/goals/${id}`);
  }

  public createGoal(request: GoalCreateRequestDto): Observable<ApiResponseDto<GoalDto>> {
    return this.http.post<ApiResponseDto<GoalDto>>('/api/goals', request);
  }

  public updateGoal(id: string, request: GoalUpdateRequestDto): Observable<ApiResponseDto<GoalDto>> {
    return this.http.put<ApiResponseDto<GoalDto>>(`/api/goals/${id}`, request);
  }

  public finishGoalCreation(id: string): Observable<ApiResponseDto<GoalDto>> {
    return this.http.post<ApiResponseDto<GoalDto>>(`/api/goals/${id}/finish`, {});
  }

  public getStats(id: string): Observable<ApiResponseDto<GoalStatsDto>> {
    return this.http.get<ApiResponseDto<GoalStatsDto>>(`/api/goals/${id}/stat`);
  }

  public getSponsors(
    id: string,
    request: GoalSponsorsRequestDto,
  ): Observable<ApiResponseDto<ApiPaginatedResponseDto<GoalSponsorDto>>> {
    return this.http.get<ApiResponseDto<ApiPaginatedResponseDto<GoalSponsorDto>>>(`/api/goals/${id}/sponsors`, {
      params: buildHttpParams(request),
    });
  }

  public donate(goalId: string, request: GoalDonateRequestDto): Observable<ApiResponseDto<GoalDonateResponseDto>> {
    return this.http.post<ApiResponseDto<GoalDonateResponseDto>>(`/api/goals/${goalId}/donate`, request);
  }

  public getAmountLimit(bypassCache?: boolean): Observable<ApiResponseDto<GoalAmountLimitDto>> {
    return this.http.get<ApiResponseDto<GoalAmountLimitDto>>('/api/goals/amount-limit', {
      params: buildHttpParams({}, bypassCache),
    });
  }

  public getPayoutInfo(goalId: string): Observable<ApiResponseDto<GoalPayoutInfoDto>> {
    return this.http.get<ApiResponseDto<GoalPayoutInfoDto>>(`/api/goals/${goalId}/request-payout`);
  }

  public requestPayout(
    goalId: string,
    request: GoalRequestPayoutRequestDto,
  ): Observable<ApiResponseDto<GoalPayoutDto>> {
    return this.http.post<ApiResponseDto<GoalPayoutDto>>(`/api/goals/${goalId}/request-payout`, request);
  }

  public getActivePayout(goalId: string): Observable<ApiResponseDto<GoalPayoutDto | null>> {
    return this.http.get<ApiResponseDto<GoalPayoutDto>>(`/api/goals/${goalId}/active-payout`).pipe(
      catchError((error) => {
        const apiException = extractApiException(error);

        if (apiException && apiException.meta.code === GoalApiExceptionCodeEnum.PayoutRequestNotFound) {
          return of(<ApiResponseDto<null>>{
            data: null,
            meta: {
              success: true,
            },
          });
        }

        return throwError(error);
      }),
    );
  }

  public findGoalReports(
    goalId: string,
    request: GoalReportsFindRequestDto,
  ): Observable<ApiResponseDto<ApiPaginatedResponseDto<GoalReportDto>>> {
    return this.http.get<ApiResponseDto<ApiPaginatedResponseDto<GoalReportDto>>>(`/api/goals/${goalId}/reports`, {
      params: buildHttpParams(request),
    });
  }

  public createReport(goalId: string, request: GoalReportCreateRequestDto): Observable<ApiResponseDto<GoalReportDto>> {
    return this.http.post<ApiResponseDto<GoalReportDto>>(`/api/goals/${goalId}/reports`, request);
  }

  public updateReport(
    goalId: string,
    reportId: string,
    request: GoalReportUpdateRequestDto,
  ): Observable<ApiResponseDto<GoalReportDto>> {
    return this.http.put<ApiResponseDto<GoalReportDto>>(`/api/goals/${goalId}/reports/${reportId}`, request);
  }

  public findGoalReviewComments(
    goalId: string,
    request: GoalReviewCommentsFindRequestDto,
  ): Observable<ApiResponseDto<ApiPaginatedResponseDto<GoalReviewCommentDto>>> {
    return this.http.get<ApiResponseDto<ApiPaginatedResponseDto<GoalReviewCommentDto>>>(
      `/api/goals/${goalId}/review-comments`,
      {
        params: buildHttpParams(request),
      },
    );
  }

  public getGoalScoring(goalId: string): Observable<ApiResponseDto<ScoringDto | null>> {
    return this.http.get<ApiResponseDto<ScoringDto | null>>(`/api/goals/${goalId}/scoring`);
  }

  public upsertScoring(
    goalId: string,
    request: ScoringCreateRequestDto,
  ): Observable<ApiResponseDto<GoalUpsertScoringResponseDto>> {
    return this.http.put<ApiResponseDto<GoalUpsertScoringResponseDto>>(`/api/goals/${goalId}/scoring`, request);
  }

  public getBatchPayoutRequests(
    request: GoalBatchIdRequestDto,
  ): Observable<ApiResponseDto<ApiPaginatedResponseDto<GoalPayoutDto>>> {
    return this.http.get<ApiResponseDto<ApiPaginatedResponseDto<GoalPayoutDto>>>('/api/goals/batch/payout-requests', {
      params: buildHttpParams(request),
    });
  }

  public getBatchReports(
    request: GoalBatchIdRequestDto,
  ): Observable<ApiResponseDto<ApiPaginatedResponseDto<GoalReportDto>>> {
    return this.http.get<ApiResponseDto<ApiPaginatedResponseDto<GoalReportDto>>>('/api/goals/batch/reports', {
      params: buildHttpParams(request),
    });
  }

  public getBatchGoalStats(
    request: GoalBatchIdRequestDto,
  ): Observable<ApiResponseDto<ApiPaginatedResponseDto<GoalStatsDto>>> {
    return this.http.get<ApiResponseDto<ApiPaginatedResponseDto<GoalStatsDto>>>('/api/goals/batch/stats', {
      params: buildHttpParams(request),
    });
  }

  public getBatchSponsors(
    request: GoalBatchSponsorsRequestDto,
  ): Observable<ApiResponseDto<GoalBatchSponsorsResponseDto[]>> {
    return this.http.get<ApiResponseDto<GoalBatchSponsorsResponseDto[]>>('/api/goals/batch/sponsors', {
      params: buildHttpParams(request),
    });
  }
}
