export interface PageParams {
  restrictPageHeight: boolean;
  pageClasses: string[];
}

export const PAGE_DEFAULT_PARAMS: PageParams = {
  restrictPageHeight: false,
  pageClasses: [],
};

export function mergePageParams<T extends Partial<PageParams>>(parent: T, current: Partial<PageParams>): T {
  return Object.assign(parent, current);
}

export function providePageData(data: Partial<PageParams>): Record<string, unknown> {
  return { page: data };
}
