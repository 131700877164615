<div class="notification-preview-item" (click)="handleNotificationClick()">
  <div
    *ngIf="notification.authorAvatar; else authorAvatarTemplate"
    class="notification-preview-item__icon"
    appUserAvatar
    [avatar]="notification.authorAvatar"
  ></div>
  <div class="notification-preview-item__content">
    <div class="notification-preview-item__title heading">
      {{
        notification.title
          ? notification.title
          : notification.authorType === authorTypeEnum.User
            ? notification.author?.name
            : systemName
      }}
    </div>

    <div class="notification-preview-item__body">
      {{ notification.text }}
    </div>
  </div>
  <div class="notification-preview-item__actions">
    <div class="notification-preview-item__date">
      {{ notification.createdAt | date: 'shortDate' }}
    </div>
    <div>‎</div>
  </div>
</div>

<ng-template #authorAvatarTemplate>
  <ng-container *ngIf="notification.authorType === authorTypeEnum.User; else authorSystemAvatarTemplate">
    <a
      *ngIf="notification.author as author; else authorSystemAvatarTemplate"
      appUserAvatar
      [avatar]="author.avatar"
      class="notification-list-item__icon"
      [routerLink]="author.slug | userRoute"
      (click)="read(); $event.stopPropagation()"
    ></a>
  </ng-container>
</ng-template>

<ng-template #authorSystemAvatarTemplate>
  <div appUserAvatar [avatar]="systemAvatar" class="notification-list-item__icon"></div>
</ng-template>
