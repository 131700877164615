import { ChangeDetectionStrategy, Component } from '@angular/core';
import { SnackBar, SnackBarRef } from '@app/shared/components/snack-bar';

@Component({
  templateUrl: './updater.component.html',
  styleUrls: ['./updater.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UpdaterComponent {
  constructor(private readonly snackBarRef: SnackBarRef<UpdaterComponent>) {}

  public static open(snackBar: SnackBar): SnackBarRef<UpdaterComponent> {
    return snackBar.openFromComponent(UpdaterComponent, {
      panelClass: 'snack-bar-update',
      verticalPosition: 'bottom',
      horizontalPosition: 'start',
    });
  }

  public update(): void {
    document.location.reload();
  }

  public close(): void {
    this.snackBarRef.dismiss();
  }
}
