import { GoalDto, GoalStatusEnum } from '@app/core/api/goal';

export const GOAL_MODERATION_STATUSES: GoalStatusEnum[] = [
  GoalStatusEnum.WaitModeration,
  GoalStatusEnum.OnModeration,
  GoalStatusEnum.WaitReModeration,
];

export const GOAL_EDITABLE_STATUSES: GoalStatusEnum[] = [GoalStatusEnum.Draft, GoalStatusEnum.NeedsFix];

export function isGoalConstructorAvailable(currentGoal: GoalDto | null): boolean {
  return !currentGoal || GOAL_EDITABLE_STATUSES.includes(currentGoal.status);
}
