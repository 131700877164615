<div class="form-field-wrapper">
  <div
    class="form-field-box"
    [class._error]="control.errorState"
    [class._disabled]="control.disabled"
    tabindex="-1"
    (click)="control.onContainerClick($event); $event.preventDefault(); $event.stopPropagation()"
  >
    <div
      *ngIf="label"
      class="form-field-label-wrapper"
      [class._floating]="shouldLabelFloat"
      [class._has-bg]="label.hasBackground"
    >
      <label [for]="control.id" [attr.id]="labelId" class="form-field-label">
        <ng-container *ngIf="shouldLabelFloat || !label.placeholderText; else placeholderLabel">
          <ng-content select="app-form-field-label, [appFormFieldLabel]"></ng-content>
        </ng-container>
        <span *ngIf="control.required" aria-hidden="true">*</span>

        <ng-template #placeholderLabel>{{ label.placeholderText }}</ng-template>
      </label>
    </div>

    <ng-content select="app-form-field-prefix, [appFormFieldPrefix]"></ng-content>

    <div class="form-field-infix" [class._no-padding-bottom]="infixNoPaddingBottom">
      <ng-content></ng-content>
    </div>

    <ng-content select="app-form-field-suffix, [appFormFieldSuffix]"></ng-content>
  </div>

  <ng-content select="app-form-field-hint, [appFormFieldHint]"></ng-content>

  <ng-container *ngIf="shouldShowErrors()">
    <ng-content select="app-form-field-error, [appFormFieldError]"></ng-content>
  </ng-container>
</div>
