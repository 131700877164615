import { isPlatformBrowser } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  HostListener,
  Inject,
  OnInit,
  PLATFORM_ID,
  Self,
  ViewEncapsulation,
} from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { FOOTER_DEFAULT_PARAMS, FooterParams, mergeFooterParams } from '@app/core/layout/footer/footer-params.provider';
import { HEADER_DEFAULT_PARAMS, HeaderParams, mergeHeaderParams } from '@app/core/layout/header/header-params.provider';
import { mergePageParams, PAGE_DEFAULT_PARAMS } from '@app/core/layout/route-handler/page-params.provider';
import { DestroyService } from '@app/shared/utils';
import { BehaviorSubject, filter, tap } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-route-handler',
  templateUrl: './route-handler.component.html',
  styleUrls: ['./route-handler.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [DestroyService],
})
export class RouteHandlerComponent implements OnInit {
  headerParams$ = new BehaviorSubject<HeaderParams>(HEADER_DEFAULT_PARAMS);

  footerParams$ = new BehaviorSubject<FooterParams>(FOOTER_DEFAULT_PARAMS);

  pageParams = PAGE_DEFAULT_PARAMS;

  scrolled = false;

  @HostBinding('class')
  public get classes(): string[] {
    return [this.pageParams.restrictPageHeight ? '_restrict-height' : '', ...this.pageParams.pageClasses];
  }

  constructor(
    private readonly router: Router,
    @Inject(PLATFORM_ID) private readonly platformId: string,
    private readonly changeDetectorRef: ChangeDetectorRef,
    @Self() private readonly destroy$: DestroyService,
  ) {}

  public ngOnInit(): void {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        tap(() => {
          let route: ActivatedRoute | null = this.router.routerState.root;
          let headerParams = { ...HEADER_DEFAULT_PARAMS };
          let footerParams = { ...FOOTER_DEFAULT_PARAMS };
          let pageParams = { ...PAGE_DEFAULT_PARAMS };

          do {
            headerParams = mergeHeaderParams(headerParams, route.snapshot.data['header'] || {});
            footerParams = mergeFooterParams(footerParams, route.snapshot.data['footer'] || {});
            pageParams = mergePageParams(pageParams, route.snapshot.data['page'] || {});

            route = route.firstChild;
          } while (route);

          this.headerParams$.next(headerParams);
          this.footerParams$.next(footerParams);
          this.pageParams = pageParams;
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  @HostListener('document:scroll')
  public handleScroll(): void {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }

    const scrolled = window.scrollY > 80;

    if (scrolled !== this.scrolled) {
      this.scrolled = scrolled;
      this.changeDetectorRef.markForCheck();
    }
  }
}
