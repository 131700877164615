import { CdkOverlayOrigin, ConnectedPosition, ViewportRuler } from '@angular/cdk/overlay';
import { isPlatformBrowser } from '@angular/common';
import {
  booleanAttribute,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  Output,
  PLATFORM_ID,
  Self,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AuthProfileModel } from '@app/core/auth';
import { BodyOverflowItemService } from '@app/core/layout/body-overflow-item.service';
import { HeaderMenuItems } from '@app/core/layout/header/header.component';
import { NotificationsService } from '@app/core/notifications/notifications.service';
import { DestroyService } from '@app/shared/utils';
import { filter, tap } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-header-mobile-menu',
  templateUrl: './header-mobile-menu.component.html',
  styleUrls: ['./header-mobile-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DestroyService, BodyOverflowItemService],
})
export class HeaderMobileMenuComponent {
  @Input({ required: true }) profile?: AuthProfileModel | null;

  @Input({ required: true }) cdkOverlayOrigin!: CdkOverlayOrigin;

  @Input({ transform: booleanAttribute }) hideCreateGoalButton = false;

  @Input() menuItems: HeaderMenuItems[] = [];

  @Output() logoutSelected = new EventEmitter<void>();

  positions: ConnectedPosition[] = [
    {
      originX: 'end',
      originY: 'bottom',
      overlayX: 'end',
      overlayY: 'top',
    },
  ];

  protected panelOpen = false;

  protected overlayWidth?: string;

  protected unreadCount$ = this.notificationsService.unreadCount$;

  constructor(
    private readonly viewportRuler: ViewportRuler,
    @Inject(PLATFORM_ID) private readonly platformId: string,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly bodyOverflowService: BodyOverflowItemService,
    private readonly router: Router,
    private readonly notificationsService: NotificationsService,
    @Self() private readonly destroy$: DestroyService,
  ) {}

  public ngOnInit(): void {
    this.viewportRuler
      .change()
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        if (this.panelOpen) {
          this.overlayWidth = this.getOverlayWidth();
          this.changeDetectorRef.detectChanges();
        }
      });

    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        tap(() => this.close()),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  public toggle(): void {
    if (this.panelOpen) {
      this.close();
    } else {
      this.open();
    }
  }

  public open(): void {
    this.overlayWidth = this.getOverlayWidth();

    this.panelOpen = true;
    this.changeDetectorRef.markForCheck();
    this.bodyOverflowService.block();
  }

  public close(): void {
    if (this.panelOpen) {
      this.panelOpen = false;
      this.changeDetectorRef.markForCheck();
      this.bodyOverflowService.unblock();
    }
  }

  protected getOverlayWidth(): string {
    if (!isPlatformBrowser(this.platformId)) {
      return 'auto';
    }

    const originElement = this.cdkOverlayOrigin.elementRef.nativeElement as HTMLElement;
    return `${originElement.clientWidth}px`;
  }
}
