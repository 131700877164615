import { Directive, HostListener, Input, OnInit, Self } from '@angular/core';

import { NavigationBackService } from './navigation-back.service';

@Directive({
  selector: '[appNavigationBack]',
  providers: [NavigationBackService],
})
export class NavigationBackDirective implements OnInit {
  @Input('appNavigationBack') defaultRoute!: string[] | string;

  constructor(@Self() private readonly navigationBackService: NavigationBackService) {}

  public ngOnInit(): void {
    this.navigationBackService.remember();
  }

  @HostListener('click', ['$event'])
  public handleClick(event: Event): void {
    event.stopPropagation();
    event.preventDefault();

    this.navigationBackService.back(Array.isArray(this.defaultRoute) ? this.defaultRoute : [this.defaultRoute]);
  }
}
