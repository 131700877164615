import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'iterateRange',
})
export class IterateRangePipe implements PipeTransform {
  public transform(length: number, start = 0): number[] {
    return new Array(length).fill(0).map((_, i) => start + i);
  }
}
