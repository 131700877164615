import { Injectable, isDevMode } from '@angular/core';
import { GoalApiService, GoalCreateRequestDto, GoalDto, GoalUpdateRequestDto } from '@app/core/api/goal';
import { GoalUpsertScoringResponseDto } from '@app/core/api/goal/dtos/goal-upsert-scoring.response.dto';
import { PaymentApiService, PaymentCardDto, PaymentCardTypeEnum } from '@app/core/api/payment';
import { ScoringCreateRequestDto, ScoringDto } from '@app/core/api/scoring';
import { AuthService } from '@app/core/auth';
import { GoalCurrentState } from '@app/features/goal/goal-current/goal-current.state';
import { GoalCurrentStateActions } from '@app/features/goal/goal-current/goal-current.state.actions';
import { isGoalConstructorAvailable } from '@app/features/goal/goal-current/goal-current.utils';
import { Store } from '@ngxs/store';
import { distinctUntilChanged, EMPTY, filter, map, Observable, shareReplay, switchMap, tap } from 'rxjs';
import { concatMap, take } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class GoalCurrentService {
  currentGoal$: Observable<GoalDto | null>;

  payoutCards$: Observable<PaymentCardDto[]>;

  initialized$: Observable<true>;

  constructorAvailable$: Observable<boolean>;

  requiredReportGoal$: Observable<GoalDto | null>;

  amountLimit$: Observable<number | null>;

  scoring$: Observable<ScoringDto | null>;

  reviewComment$: Observable<string>;

  constructor(
    private readonly goalApi: GoalApiService,
    private readonly paymentApi: PaymentApiService,
    private readonly authService: AuthService,
    private readonly store: Store,
  ) {
    this.authService.profile$
      .pipe(
        map((profile) => profile?.userId),
        distinctUntilChanged(),
        tap(() => this.store.dispatch(new GoalCurrentStateActions.Load())),
      )
      .subscribe();

    this.initialized$ = this.store.select(GoalCurrentState.initialized).pipe(filter(Boolean), take(1), shareReplay());

    this.currentGoal$ = this.initialized$.pipe(switchMap(() => this.store.select(GoalCurrentState.currentGoal)));

    this.payoutCards$ = this.initialized$.pipe(switchMap(() => this.store.select(GoalCurrentState.payoutCards)));

    this.constructorAvailable$ = this.currentGoal$.pipe(map((goal) => isGoalConstructorAvailable(goal)));

    this.requiredReportGoal$ = this.initialized$.pipe(
      switchMap(() => this.store.select(GoalCurrentState.requiredReportGoals)),
      map((goals) => goals[0] || null),
    );

    this.amountLimit$ = this.initialized$.pipe(switchMap(() => this.store.select(GoalCurrentState.amountLimit)));

    this.scoring$ = this.initialized$.pipe(switchMap(() => this.store.select(GoalCurrentState.scoring)));

    this.reviewComment$ = this.initialized$.pipe(
      switchMap(() => this.store.select(GoalCurrentState.reviewComment)),
      map(
        (comment) => comment?.comment || 'Пожалуйста, внесите необходимые изменения и отправьте на повторную модерацию',
      ),
    );
  }

  public save(request: GoalUpdateRequestDto): Observable<GoalDto> {
    const goal = this.store.selectSnapshot(GoalCurrentState.currentGoal);
    return goal ? this.update(request) : this.create(request as GoalCreateRequestDto);
  }

  public create(request: GoalCreateRequestDto): Observable<GoalDto> {
    return this.goalApi.createGoal(request).pipe(
      map((response) => response.data),
      tap((goal) => this.store.dispatch(new GoalCurrentStateActions.Set(goal))),
    );
  }

  public update(request: GoalUpdateRequestDto): Observable<GoalDto> {
    const goal = this.store.selectSnapshot(GoalCurrentState.currentGoal);

    if (!goal) {
      if (isDevMode()) {
        console.warn('goal is not defined');
      }

      return EMPTY;
    }

    return this.goalApi.updateGoal(goal.id, request).pipe(
      map((response) => response.data),
      tap((updatedGoal) => this.store.dispatch(new GoalCurrentStateActions.Set(updatedGoal))),
    );
  }

  public saveScoring(request: ScoringCreateRequestDto): Observable<GoalUpsertScoringResponseDto> {
    const goal = this.store.selectSnapshot(GoalCurrentState.currentGoal);

    if (!goal) {
      if (isDevMode()) {
        console.warn('goal is not defined');
      }

      return EMPTY;
    }

    return this.goalApi.upsertScoring(goal.id, request).pipe(
      map((response) => response.data),
      tap(({ scoring, ...updatedGoal }) =>
        this.store.dispatch(new GoalCurrentStateActions.SetGoalAndScoring(updatedGoal, scoring)),
      ),
    );
  }

  public finishCreation(): Observable<GoalDto> {
    const goal = this.store.selectSnapshot(GoalCurrentState.currentGoal);

    if (!goal) {
      if (isDevMode()) {
        console.warn('goal is not defined');
      }

      return EMPTY;
    }

    return this.goalApi.finishGoalCreation(goal.id).pipe(
      map((response) => response.data),
      tap((updatedGoal) => this.store.dispatch(new GoalCurrentStateActions.Set(updatedGoal))),
    );
  }

  public deleteCard(cardId: string): Observable<PaymentCardDto[]> {
    return this.paymentApi.deleteCard(cardId).pipe(
      concatMap(() =>
        this.paymentApi.findCards({
          type: PaymentCardTypeEnum.Payout,
          onPage: 200,
        }),
      ),
      map((response) => response.data.rows),
      tap((tools) => this.store.dispatch(new GoalCurrentStateActions.SetPayoutCards(tools))),
    );
  }

  public reloadPayoutCards(): void {
    this.store.dispatch(new GoalCurrentStateActions.ReloadPayoutCards());
  }

  public setPayoutCards(cards: PaymentCardDto[]): void {
    this.store.dispatch(new GoalCurrentStateActions.SetPayoutCards(cards));
  }
}
