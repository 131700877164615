import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AnimationOptions } from 'ngx-lottie';

@Component({
  selector: 'app-loader-spinner',
  templateUrl: './loader-spinner.component.html',
  styleUrls: ['./loader-spinner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoaderSpinnerComponent {
  loaderAnimationOptions: AnimationOptions = {
    path: '/assets/animations/loader.json',
  };
}
