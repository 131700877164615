import { FileImageSizeDto } from './file-image-size.dto';

export interface FileDto {
  id: string;
  url: string;
  type: string;
  blurhash?: string | null;
  width?: number | null;
  height?: number | null;
  sizes?: FileImageSizeDto[];
}
