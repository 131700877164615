import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class BodyOverflowStateService {
  protected blockedBy = new Set<number>();

  protected bodyClass = '_overflow';

  protected restoreScrollTop = 0;

  constructor(
    @Inject(DOCUMENT) private readonly document: Document,
    @Inject(PLATFORM_ID) private readonly platformId: string,
  ) {}

  public block(target: number): void {
    this.blockedBy.add(target);
    this.update();
  }

  public unblock(target: number): void {
    this.blockedBy.delete(target);
    this.update();
  }

  protected update(): void {
    const blocked = this.blockedBy.size > 0;
    const wasBlocked = this.document.body.classList.contains(this.bodyClass);

    if (isPlatformBrowser(this.platformId)) {
      if (!wasBlocked && blocked) {
        this.restoreScrollTop = window.scrollY;
      }
    }

    if (blocked) {
      this.document.body.classList.add(this.bodyClass);
      this.document.body.parentElement?.classList.add(this.bodyClass);
    } else {
      this.document.body.classList.remove(this.bodyClass);
      this.document.body.parentElement?.classList.remove(this.bodyClass);
    }

    if (isPlatformBrowser(this.platformId)) {
      if (wasBlocked && !blocked) {
        window.scrollTo({
          top: this.restoreScrollTop,
          behavior: 'instant',
        });
      }
    }
  }
}
