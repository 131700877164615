import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ButtonsModule } from '@app/shared/components/buttons/buttons.module';

import { NotFoundComponent } from './not-found.component';

@NgModule({
  declarations: [NotFoundComponent],
  imports: [CommonModule, ButtonsModule, RouterModule],
  exports: [NotFoundComponent],
})
export class NotFoundModule {}
