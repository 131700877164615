import { Dialog } from '@angular/cdk/dialog';
import { booleanAttribute, ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SupportFeedbackDialogComponent } from '@app/features/support/support-feedback-dialog/support-feedback-dialog.component';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent {
  @Input({ transform: booleanAttribute }) container = false;

  phones = ['+7 (499) 408-25-89', '+7 (800) 250-55-27'];

  year = new Date().getFullYear();

  constructor(private readonly dialog: Dialog) {}

  protected openFeedbackDialog(): void {
    SupportFeedbackDialogComponent.open(this.dialog);
  }
}
