import {
  booleanAttribute,
  ChangeDetectionStrategy,
  Component,
  ContentChildren,
  HostBinding,
  Input,
  QueryList,
  ViewEncapsulation,
} from '@angular/core';
import { SvgIconComponent } from 'angular-svg-icon';

export type FlatButtonColor = 'primary' | 'secondary' | 'white' | 'transparent' | 'secondary-outlined';
export type FlatButtonSize = 'small' | 'medium' | 'large';
export type FlatButtonAlign = 'start' | 'center' | 'end';

@Component({
  selector: 'button[app-flat-button]',
  templateUrl: './flat-button.component.html',
  styleUrls: ['./flat-button.component.scss'],
  exportAs: 'appFlatButton',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'app-flat-button',
  },
})
export class FlatButtonComponent {
  @Input() color: FlatButtonColor = 'primary';

  @Input() size: FlatButtonSize = 'medium';

  @Input({ transform: booleanAttribute }) fixedWidth = false;

  @ContentChildren(SvgIconComponent, { descendants: true }) iconComponents!: QueryList<SvgIconComponent>;

  @HostBinding('class')
  public get classes(): string[] {
    return [
      `_${this.color}`,
      `_${this.size}`,
      this.fixedWidth ? '_fixed-width' : '',
      this.iconComponents.length ? '_has-icon' : '',
    ];
  }
}

@Component({
  selector: 'a[app-flat-button]',
  templateUrl: './flat-button.component.html',
  styleUrls: ['./flat-button.component.scss'],
  exportAs: 'appFlatButton',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'app-flat-button',
  },
})
export class FlatButtonAnchorComponent {
  @Input() color: FlatButtonColor = 'primary';

  @Input() size: FlatButtonSize = 'medium';

  @Input({ transform: booleanAttribute }) fixedWidth = false;

  @ContentChildren(SvgIconComponent, { descendants: true }) iconComponents!: QueryList<SvgIconComponent>;

  @HostBinding('class')
  public get classes(): string[] {
    return [
      `_${this.color}`,
      `_${this.size}`,
      this.fixedWidth ? '_fixed-width' : '',
      this.iconComponents.length ? '_has-icon' : '',
    ];
  }
}
