import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'arrayParts',
})
export class ArrayPartsPipe implements PipeTransform {
  public transform<T>(value: T[] | null | undefined, parts = 2, balanced = true): T[][] {
    if (!Array.isArray(value)) {
      return [];
    }

    const result: T[][] = [];
    const len = value.length;
    let n = parts;

    if (len % parts === 0) {
      let i = 0;
      const partSize = Math.floor(len / parts);

      while (len > i) {
        result.push(value.slice(i, (i += partSize)));
      }
    } else if (balanced) {
      let i = 0;
      while (len > i) {
        const partSize = Math.ceil((len - i) / n);
        n -= 1;
        result.push(value.slice(i, (i += partSize)));
      }
    } else {
      n -= 1;
      let i = 0;
      let partSize = Math.floor(len / n);

      if (len % partSize === 0) {
        partSize -= 1;
      }

      while (i < partSize * n) {
        result.push(value.slice(i, (i += partSize)));
      }

      result.push(value.slice(partSize * n));
    }

    return result;
  }
}
