import { DialogModule } from '@angular/cdk/dialog';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, ModuleWithProviders, NgModule } from '@angular/core';
import { ProfileEmailConfirmationDialogModule } from '@app/features/profile/profile-email-confirmation-dialog/profile-email-confirmation-dialog.module';
import { NgxsModule } from '@ngxs/store';

import { ApiAuthErrorInterceptor, AuthService, UnauthorizedInterceptor } from './services';
import { AuthState } from './states';

@NgModule({
  imports: [NgxsModule.forFeature([AuthState]), DialogModule, ProfileEmailConfirmationDialogModule],
})
export class AuthModule {
  public static forRoot(): ModuleWithProviders<AuthModule> {
    return {
      ngModule: AuthModule,
      providers: [
        AuthService,
        {
          provide: APP_INITIALIZER,
          useFactory: (authService: AuthService) => () => authService.init(),
          deps: [AuthService],
          multi: true,
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: UnauthorizedInterceptor,
          multi: true,
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: ApiAuthErrorInterceptor,
          multi: true,
        },
      ],
    };
  }
}
