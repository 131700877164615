import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { buildHttpParams } from '@app/shared/utils';
import { Observable } from 'rxjs';

import { ApiPaginatedResponseDto, ApiResponseDto } from '../common-dtos';
import { CategoriesFindRequestDto, CategoryDto } from './dtos';

@Injectable({ providedIn: 'root' })
export class CategoryApiService {
  constructor(private readonly http: HttpClient) {}

  public find(request: CategoriesFindRequestDto): Observable<ApiResponseDto<ApiPaginatedResponseDto<CategoryDto>>> {
    return this.http.get<ApiResponseDto<ApiPaginatedResponseDto<CategoryDto>>>('/api/categories', {
      params: buildHttpParams(request),
    });
  }
}
