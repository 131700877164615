import { format as dateFnsFormat, format, set as dateFnsSet } from 'date-fns';
import formatDate from 'date-fns/format';

export function getIsoDayWithCurrentTimezone(date: Date | string | number): string {
  return format(new Date(date), 'yyyy-MM-dd');
}

export function getTimezoneMinutes(): number {
  const offset = new Date().getTimezoneOffset();
  return -offset;
}

export function separateDateAndTime(value: Date | string): { date: Date; time: string };
export function separateDateAndTime(value: Date | string | null): { date?: Date; time?: string };
export function separateDateAndTime(value: Date | string | null): { date?: Date; time?: string } {
  if (!value) {
    return {};
  }

  const dateObj = new Date(value);

  const date = dateFnsSet(dateObj, {
    hours: 0,
    minutes: 0,
    seconds: 0,
    milliseconds: 0,
  });

  const time = dateFnsFormat(dateObj, 'HH:mm');

  return { date, time };
}

export function buildDateTime(date: Date, time: string): Date;
export function buildDateTime(date?: Date | null, time?: string | null): Date | null;
export function buildDateTime(date?: Date | null, time?: string | null): Date | null {
  if (!date || !time) {
    return null;
  }

  const [hours, minutes] = time.split(':') || ['0', '0'];
  const dateObj = new Date(date);

  return dateFnsSet(dateObj, {
    hours: +hours,
    minutes: +minutes,
    seconds: 0,
    milliseconds: 0,
  });
}

export function getIsoDate(date: string | Date): string;
export function getIsoDate(date: string | Date | null): string | null;
export function getIsoDate(date: string | Date | null): string | null {
  return date ? formatDate(new Date(date), 'yyyy-MM-dd') : null;
}

export function compareDatesByISO(d1: string | Date | null, d2: string | Date | null): boolean {
  if (!d1 && !d2) {
    return true;
  }

  if (!d1 || !d2) {
    return false;
  }

  return getIsoDate(d1) === getIsoDate(d2);
}
