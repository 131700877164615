import { DOCUMENT, registerLocaleData } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import ruLocale from '@angular/common/locales/ru';
import { NgModule, PLATFORM_ID, TransferState } from '@angular/core';
import { provideClientHydration } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { CookieModule, CookieRepository, CookieRepositoryBrowser } from '@app/core/cookie';
import { CoreModule } from '@app/core/core.module';
import { RouteHandlerModule } from '@app/core/layout/route-handler/route-handler.module';
import { svgBrowserLoaderFactory } from '@app/core/svg-icons/svg-browser-loader.service';
import { APP_SVG_ICON_PATH } from '@app/core/tokens';
import { environment } from '@app/environment';
import { NotFoundPageModule } from '@app/pages/not-found-page/not-found-page.module';
import { NgxsModule } from '@ngxs/store';
import { AngularSvgIconModule, SvgLoader } from 'angular-svg-icon';
import dateFnsRu from 'date-fns/locale/ru';
import dateFnsSetDefaultOptions from 'date-fns/setDefaultOptions';
import { LottieModule } from 'ngx-lottie';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { NotificationsMessagingModule } from './core/notifications/notifications-messaging.module';
import { provideNotificationMessagingFirebase } from './core/notifications/notifications-messaging-firebase.adapter';

dateFnsSetDefaultOptions({ locale: dateFnsRu });
registerLocaleData(ruLocale);

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserAnimationsModule,
    AppRoutingModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !environment.isLocal,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
    RouterModule,
    HttpClientModule,
    AngularSvgIconModule.forRoot({
      loader: {
        provide: SvgLoader,
        useFactory: svgBrowserLoaderFactory,
        deps: [HttpClient, TransferState, APP_SVG_ICON_PATH],
      },
    }),
    CoreModule,
    RouteHandlerModule,
    NotFoundPageModule,
    CookieModule.forRoot({
      repository: {
        provide: CookieRepository,
        useFactory: (platformId: string, document: Document) => new CookieRepositoryBrowser(platformId, document),
        deps: [PLATFORM_ID, DOCUMENT],
      },
    }),
    NgxsModule.forRoot([], {
      developmentMode: !environment.production,
    }),
    LottieModule.forRoot({
      player: () => import('lottie-web'),
    }),
    NotificationsMessagingModule.forRoot({
      messagingProvider: provideNotificationMessagingFirebase(),
    }),
  ],
  providers: [provideClientHydration()],
  bootstrap: [AppComponent],
})
export class AppModule {}
