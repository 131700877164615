import 'firebase/compat/messaging';

import { Provider } from '@angular/core';
import { environment } from '@app/environment';
import firebase from 'firebase/compat/app';
import { EMPTY, filter, fromEvent, map, Observable, of, share, Subject, tap } from 'rxjs';

import { NotificationMessagePush, NotificationsMessagingProvider } from './notifications-messaging.provider';

interface NotificationServiceWorkerBackgroundMessagePayload {
  type: 'background-message-recieved';
  payload: NotificationMessagePush;
}

function isServiceWorkerBackgroundMessage(data: unknown): data is NotificationServiceWorkerBackgroundMessagePayload {
  return (
    !!data &&
    typeof data === 'object' &&
    'type' in data &&
    'payload' in data &&
    !!data.payload &&
    typeof data.payload === 'object' &&
    data.type === 'background-message-recieved'
  );
}

export class NotificationsMessagingFirebaseAdapter implements NotificationsMessagingProvider {
  private readonly messaging?: firebase.messaging.Messaging;

  constructor() {
    if (environment.firebaseConfig) {
      firebase.initializeApp(environment.firebaseConfig);
      this.messaging = firebase.messaging();
    }

    this.foregroundMessage$ = this.onForegoundMessageAsObservable().pipe(share());

    if (navigator?.serviceWorker) {
      fromEvent<MessageEvent>(navigator.serviceWorker, 'message')
        .pipe(
          map((event) => event.data),
          filter(isServiceWorkerBackgroundMessage),
          tap((message) => this.backgroundMessageSubject.next(message.payload)),
        )
        .subscribe();
    }
  }

  foregroundMessage$: Observable<NotificationMessagePush> = EMPTY;

  protected backgroundMessageSubject = new Subject<NotificationMessagePush>();

  backgroundMessage$ = this.backgroundMessageSubject.asObservable().pipe(share());

  public deleteToken(): Observable<void> {
    const messaging = this.messaging;

    if (!messaging) {
      return of();
    }

    return new Observable<void>((subscriber) => {
      messaging
        .deleteToken()
        .then(() => {
          subscriber.next();
          subscriber.complete();
        })
        .catch((error) => {
          subscriber.error(error);
        });
    });
  }

  public getToken(): Observable<string | null> {
    const messaging = this.messaging;

    if (!messaging) {
      return of(null);
    }

    return new Observable<string | null>((subscriber) => {
      messaging
        .getToken({
          vapidKey: environment.firebaseConfig?.vapidKey,
        })
        .then((token) => {
          subscriber.next(token);
          subscriber.complete();
        })
        .catch((error) => {
          subscriber.error(error);
        });
    });
  }

  protected onForegoundMessageAsObservable(): Observable<NotificationMessagePush> {
    if (!this.messaging) {
      return EMPTY;
    }

    const messaging = this.messaging;

    return new Observable((subscriber) => {
      return messaging.onMessage({
        next: (message) => subscriber.next(message),
        error: (error) => subscriber.error(error),
        complete: () => subscriber.complete,
      });
    });
  }
}

export const provideNotificationMessagingFirebase = (): Provider => ({
  provide: NotificationsMessagingProvider,
  useClass: NotificationsMessagingFirebaseAdapter,
});
