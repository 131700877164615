export enum PaymentStatusEnum {
  /** Сделка зарегистрирована в системе */
  Created = 'created',
  /** Производится оплата сделки заказчиком */
  WaitPayment = 'wait-payment',
  /** Сделка успешно оплачена заказчиком */
  Paid = 'paid',
  /** Сделка отклонена */
  Declined = 'declined',
  /** Ошибка платежа */
  Error = 'error',
  /** Ожидание возврата */
  WaitRefund = 'wait-refund',
  /** Выполнен возврат */
  Refunded = 'refunded',
  /** Ошибка возврата */
  RefundError = 'refund-error',
}

export enum PaymentMethodEnum {
  Card = 'card',
  Sbp = 'sbp',
}

export interface PaymentDto {
  id: string;
  amount: number;
  status: PaymentStatusEnum;
  userId: string;
  cardId: string | null;
  externalId: string;
  paymentMethod: PaymentMethodEnum | null;
}
