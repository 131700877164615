import { PayerDto, PayerTypeEnum } from '../../goal';

export interface TransactionIncomingDto {
  transactionId: string;
  amount: number;
  goalId: string;
  paymentDate: string;
  anonymous: boolean;
  payerId: string | null;
  payerType: PayerTypeEnum;
  payer: PayerDto | null;
}
