import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'arrayNestedField',
})
export class ArrayNestedFieldPipe implements PipeTransform {
  public transform<T extends object, K extends keyof T>(value: T[], field: K): T[K][] {
    return value.map((i) => i[field]);
  }
}
