import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FooterModule } from '@app/core/layout/footer/footer.module';
import { HeaderModule } from '@app/core/layout/header/header.module';

import { RouteHandlerComponent } from './route-handler.component';

@NgModule({
  declarations: [RouteHandlerComponent],
  imports: [CommonModule, HeaderModule, RouterModule, FooterModule],
  exports: [RouteHandlerComponent],
})
export class RouteHandlerModule {}
