export enum PaymentCardTypeEnum {
  Payment = 'payment',
  Payout = 'payout',
}

export interface PaymentCardDto {
  id: string;
  type: PaymentCardTypeEnum;
  userId: string;
  pan: string | null;
  expirationYear: string | null;
  expirationMonth: string | null;
  paymentSystem: string | null;
  isMain: boolean;
  createdAt: Date;
  updatedAt: Date;
}
