import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import {
  catchError,
  distinctUntilChanged,
  EMPTY,
  filter,
  map,
  Observable,
  shareReplay,
  Subject,
  switchMap,
  take,
  tap,
} from 'rxjs';

import { AppNotificationDto } from '../api/notifications';
import { NotificationsApiService } from '../api/notifications/notifications-api.service';
import { AuthService } from '../auth';
import { NotificationMessagePush, NotificationsMessagingProvider } from './notifications-messaging.provider';
import { NotificationsState } from './states/notifications.state';
import { NotificationsStateActions } from './states/notifications.state.actions';

@Injectable({ providedIn: 'root' })
export class NotificationsService {
  storeInitialized$: Observable<boolean>;

  unread$: Observable<AppNotificationDto[]>;

  unreadCount$: Observable<number>;

  notificationReadEvent$ = new Subject<string>();

  notificationHiddenEvent$ = new Subject<string>();

  protected inited = false;

  constructor(
    private readonly authService: AuthService,
    private readonly messagingProvider: NotificationsMessagingProvider,
    private readonly notificationsApi: NotificationsApiService,
    private readonly router: Router,
    private readonly ngZone: NgZone,
    private readonly store: Store,
  ) {
    this.storeInitialized$ = this.store.select(NotificationsState.initialized).pipe(filter(Boolean), take(1));

    this.unread$ = this.storeInitialized$.pipe(
      switchMap(() => this.store.select(NotificationsState.unread)),
      shareReplay(1),
    );

    this.unreadCount$ = this.storeInitialized$.pipe(
      switchMap(() => this.store.select(NotificationsState.unreadCount)),
      shareReplay(1),
    );
  }

  public init(): void {
    if (this.inited) {
      return;
    }

    this.inited = true;

    this.authService.profile$
      .pipe(
        filter(Boolean),
        map((profile) => profile.userId),
        distinctUntilChanged(),
        tap(() => this.store.dispatch(new NotificationsStateActions.LoadUnread())),
        switchMap(() =>
          this.messagingProvider.getToken().pipe(
            switchMap((token) => this.notificationsApi.setFcmToken({ token })),
            catchError(() => EMPTY),
          ),
        ),
      )
      .subscribe();

    this.messagingProvider.foregroundMessage$
      .pipe(
        tap(() => this.reloadUnread()),
        tap((message) => this.showForegroundMessage(message)),
      )
      .subscribe();

    this.messagingProvider.backgroundMessage$.pipe(tap(() => this.reloadUnread())).subscribe();
  }

  public read(notificationIds: string[]): void {
    this.notificationsApi
      .read({ notificationIds })
      .pipe(
        tap(() => this.reloadUnread()),
        tap(() => notificationIds.forEach((i) => this.notificationReadEvent$.next(i))),
        catchError(() => EMPTY),
      )
      .subscribe();
  }

  public readAll(): void {
    this.notificationsApi
      .readAll()
      .pipe(
        map((response) => response.data.notificationIds),
        tap((notificationIds) => {
          this.store.dispatch(new NotificationsStateActions.MarkAsRead(notificationIds));
          notificationIds.forEach((i) => this.notificationReadEvent$.next(i));
          this.store.dispatch(new NotificationsStateActions.LoadUnread());
        }),
      )
      .subscribe();
  }

  public reloadUnread(): void {
    this.store.dispatch(new NotificationsStateActions.LoadUnread());
  }

  public hideNotification(notificationId: string): void {
    this.notificationsApi
      .hideNotification(notificationId)
      .pipe(
        tap(() => this.notificationHiddenEvent$.next(notificationId)),
        tap(() => this.reloadUnread()),
        catchError((error) => {
          console.error(error);
          return EMPTY;
        }),
      )
      .subscribe();
  }

  protected showForegroundMessage(message: NotificationMessagePush): void {
    if ('Notification' in window && Notification.permission !== 'denied' && !message.data?.['hidden']) {
      const title = message.notification?.title || message.data?.['title'] || 'Платформа ЦЕЛИ';
      const body = message.notification?.body || message.data?.['body'];
      const icon = (message.notification as Record<string, string>)?.['icon'] || message.data?.['icon'];
      const link = message.fcmOptions?.link || message.data?.['link'];
      const notification = new Notification(title, {
        body,
        icon,
        data: message.data,
      });

      notification.onclick = (event) => {
        event.preventDefault();

        if (link) {
          this.ngZone.run(() => {
            this.router.navigateByUrl(link);
          });
        }

        notification.close();
      };
    }
  }

  protected sendReadRequest(notificationIds: string[]): Observable<unknown> {
    return this.notificationsApi.read({ notificationIds }).pipe(
      catchError(() => EMPTY),
      tap(() => this.reloadUnread()),
    );
  }
}
