import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { SnackBarLabel } from '@app/shared/components/snack-bar/snack-bar-content';

import { SNACK_BAR_DATA } from '../snack-bar-config';
import { SnackBarRef } from '../snack-bar-ref';

interface SimpleSnackBarComponentData {
  message: string;
}

@Component({
  selector: 'app-simple-snack-bar',
  templateUrl: './simple-snack-bar.component.html',
  styleUrls: ['./simple-snack-bar.component.scss'],
  exportAs: 'snackBar',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  host: {
    class: 'simple-snack-bar',
  },
  imports: [SnackBarLabel],
})
export class SimpleSnackBarComponent {
  constructor(
    public snackBarRef: SnackBarRef<SimpleSnackBarComponent>,
    @Inject(SNACK_BAR_DATA) public data: SimpleSnackBarComponentData,
  ) {}
}
