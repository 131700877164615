import {
  AfterContentChecked,
  AfterContentInit,
  booleanAttribute,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ContentChildren,
  ElementRef,
  Input,
  isDevMode,
  QueryList,
  Self,
} from '@angular/core';
import { AbstractControlDirective } from '@angular/forms';
import {
  FORM_FIELD_ERROR,
  FORM_FIELD_LABEL,
  FormFieldControl,
  FormFieldError,
  FormFieldLabel,
} from '@app/shared/components/forms/form-shared';
import { DestroyService } from '@app/shared/utils';
import { takeUntil } from 'rxjs/operators';

let uniqueId = 0;

@Component({
  selector: 'app-form-field',
  templateUrl: './form-field.component.html',
  styleUrls: ['./form-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'form-field',
    '[class._error]': 'control.errorState',
    '[class._valid]': '!control.errorState',
    '[class._disabled]': 'control.disabled',
    '[class._hide-placeholder]': '!!label && !shouldLabelFloat',
    '[class._focused]': 'control.focused',
    '[class.ng-untouched]': 'shouldForward("untouched")',
    '[class.ng-touched]': 'shouldForward("touched")',
    '[class.ng-pristine]': 'shouldForward("pristine")',
    '[class.ng-dirty]': 'shouldForward("dirty")',
    '[class.ng-valid]': 'shouldForward("valid")',
    '[class.ng-invalid]': 'shouldForward("invalid")',
    '[class.ng-pending]': 'shouldForward("pending")',
  },
  providers: [DestroyService],
})
export class FormFieldComponent implements AfterContentInit, AfterContentChecked {
  @Input({ transform: booleanAttribute }) infixNoPaddingBottom = false;

  @ContentChild(FormFieldControl) control!: FormFieldControl<unknown>;

  @ContentChild(FORM_FIELD_LABEL) label?: FormFieldLabel;

  @ContentChildren(FORM_FIELD_ERROR, { descendants: true }) errorChildren!: QueryList<FormFieldError>;

  protected get shouldLabelFloat(): boolean {
    return this.control.shouldLabelFloat;
  }

  readonly labelId = `form-field-label_${++uniqueId}`;

  protected shouldShowErrors(): boolean {
    return !!(this.errorChildren && this.errorChildren.length && this.control.errorState);
  }

  protected isFocused = false;

  constructor(
    private readonly elementRef: ElementRef,
    private readonly changeDetectorRef: ChangeDetectorRef,
    @Self() private readonly destroy$: DestroyService,
  ) {}

  public ngAfterContentInit(): void {
    this.assertFormFieldControl();
    this.initializeControl();
    this.initializeSubscript();
  }

  public ngAfterContentChecked(): void {
    this.assertFormFieldControl();
  }

  public getConnectedOverlayOrigin(): ElementRef {
    return this.elementRef;
  }

  protected shouldForward(prop: keyof AbstractControlDirective): boolean {
    const control = this.control ? this.control.ngControl : null;
    return control && control[prop];
  }

  private initializeControl(): void {
    if (this.control.controlType) {
      this.elementRef.nativeElement.classList.add(`form-field-type-${this.control.controlType}`);
    }

    this.control.stateChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.syncDescribedByIds();
      this.changeDetectorRef.markForCheck();
    });

    if (this.control.ngControl && this.control.ngControl.valueChanges) {
      this.control.ngControl.valueChanges
        .pipe(takeUntil(this.destroy$))
        .subscribe(() => this.changeDetectorRef.markForCheck());
    }
  }

  private initializeSubscript(): void {
    this.errorChildren.changes.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.syncDescribedByIds();
      this.changeDetectorRef.markForCheck();
    });

    this.syncDescribedByIds();
  }

  private assertFormFieldControl(): void {
    if (!this.control && isDevMode()) {
      throw new Error(`Control for form-field not found`);
    }
  }

  private syncDescribedByIds(): void {
    if (this.control) {
      const ids: string[] = [];

      if (this.control.userAriaDescribedBy && typeof this.control.userAriaDescribedBy === 'string') {
        ids.push(...this.control.userAriaDescribedBy.split(' '));
      }

      if (this.errorChildren) {
        ids.push(...this.errorChildren.map((error) => error.id));
      }

      this.control.setDescribedByIds(ids);
    }
  }
}
