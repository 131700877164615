<div [class]="['checkbox', '_' + verticalAlign]" (click)="stopPropagation($event)">
  <div class="checkbox__box">
    <input
      type="checkbox"
      [id]="id"
      [checked]="checked"
      [indeterminate]="indeterminate"
      [disabled]="disabled"
      (change)="change($event)"
    />
    <div class="checkbox__background">
      <svg-icon class="_empty" src="checkbox-empty"></svg-icon>
      <svg-icon class="_checked" src="checkbox-checked"></svg-icon>
    </div>
  </div>
  <label class="checkbox__label" [for]="id">
    <ng-content></ng-content>
  </label>
</div>
