import { GoalStatusEnum } from '@app/core/api/goal';

import { ApiPaginationRequestDto } from '../../common-dtos';

export interface GoalMyFiltersDto {
  completedSuccessfully?: boolean | null;
  hasReport?: boolean | null;
  status?: GoalStatusEnum | null;
  statuses?: GoalStatusEnum[] | null;
  collectedGte?: number | null;
  collectedLte?: number | null;
}

export type GoalMyOrderKey =
  | 'createdAt'
  | '-createdAt'
  | 'updatedAt'
  | '-updatedAt'
  | 'activeUntil'
  | '-activeUntil'
  | 'startedAt'
  | '-startedAt'
  | 'collected'
  | '-collected';

export interface GoalMyRequestDto extends GoalMyFiltersDto, ApiPaginationRequestDto {
  order?: GoalMyOrderKey | null;
}
