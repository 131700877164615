import { GoalDto, PayerTypeEnum } from '../../goal';

export type TransactionOutgoingGoalDto = Pick<GoalDto, 'id' | 'title' | 'status' | 'owner' | 'userId'>;

export enum TransactionTypeEnum {
  Deposit = 'deposit',
  RefundedDeposit = 'refunded-deposit',
  Withdraw = 'withdraw',
}

export interface TransactionOutgoingDto {
  transactionId: string;
  amount: number;
  goalId: string;
  goal: TransactionOutgoingGoalDto | null;
  paymentDate: string;
  anonymous: boolean;
  payerId: string | null;
  payerType: PayerTypeEnum;
  transactionType: TransactionTypeEnum;
  refundDate: string | null;
}
