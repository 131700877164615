export namespace NotificationsStateActions {
  export class LoadUnread {
    public static readonly type = '[Notifications] Load';
  }

  export class MarkAsRead {
    public static readonly type = '[Notifications] Mark As Read';

    constructor(public readonly notificationIds: string[]) {}
  }
}
