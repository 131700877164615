import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { buildHttpParams } from '@app/shared/utils';
import { Observable } from 'rxjs';

import { ApiPaginatedResponseDto } from '../common-dtos/api-paginated-response.dto';
import { ApiResponseDto } from '../common-dtos/api-response.dto';
import { ScoringDto, ScoringsFindRequestDto } from './dtos';

@Injectable({ providedIn: 'root' })
export class ScoringApiService {
  constructor(private readonly http: HttpClient) {}

  public find(request: ScoringsFindRequestDto): Observable<ApiResponseDto<ApiPaginatedResponseDto<ScoringDto>>> {
    return this.http.get<ApiResponseDto<ApiPaginatedResponseDto<ScoringDto>>>('/api/scoring', {
      params: buildHttpParams(request),
    });
  }

  public get(scoringId: string): Observable<ApiResponseDto<ScoringDto>> {
    return this.http.get<ApiResponseDto<ScoringDto>>(`/api/scoring/${scoringId}`);
  }
}
