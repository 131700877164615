import { ModuleWithProviders, NgModule, Provider } from '@angular/core';

import { CookieManager } from './cookie.manager';

interface CookieModuleOptions {
  repository: Provider;
}

@NgModule({
  providers: [CookieManager],
})
export class CookieModule {
  public static forRoot(options: CookieModuleOptions): ModuleWithProviders<CookieModule> {
    return {
      ngModule: CookieModule,
      providers: [options.repository],
    };
  }
}
