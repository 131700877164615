import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { FlatButtonAnchorComponent, FlatButtonComponent } from './flat-button/flat-button.component';
import { IconButtonAnchorComponent, IconButtonComponent } from './icon-button/icon-button.component';
import { LinkAnchorComponent, LinkComponent } from './link/link.component';
import { LinkButtonAnchorComponent, LinkButtonComponent } from './link-button/link-button.component';
import { TabButtonAnchorComponent, TabButtonComponent } from './tab-button/tab-button.component';

@NgModule({
  declarations: [
    IconButtonComponent,
    IconButtonAnchorComponent,
    FlatButtonComponent,
    FlatButtonAnchorComponent,
    LinkButtonComponent,
    LinkButtonAnchorComponent,
    LinkComponent,
    LinkAnchorComponent,
    TabButtonComponent,
    TabButtonAnchorComponent,
  ],
  imports: [CommonModule],
  exports: [
    IconButtonComponent,
    IconButtonAnchorComponent,
    FlatButtonComponent,
    FlatButtonAnchorComponent,
    LinkButtonComponent,
    LinkButtonAnchorComponent,
    LinkComponent,
    LinkAnchorComponent,
    TabButtonComponent,
    TabButtonAnchorComponent,
  ],
})
export class ButtonsModule {}
