import { ProfileDto } from '@app/core/api/profile';

export interface ProfileStatisticsProfessionalDto
  extends Pick<ProfileDto, 'userId' | 'fullName' | 'profession' | 'slug' | 'avatar'> {
  subscribed: boolean;
}

export interface ProfileStatisticsDto {
  userId: string;
  goalCount: number;
  transactionsCount: number;
  maxPayment: number;
  totalAmount: number;
  professionals: ProfileStatisticsProfessionalDto[];
}
