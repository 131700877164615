import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'arrayEnum',
})
export class ArrayEnumPipe implements PipeTransform {
  public transform<K extends string, T extends Record<K, unknown>>(value: K[], dict: T): T[K][];
  public transform<K extends string, T extends Partial<Record<K, unknown>>>(
    value: K[] | null | undefined,
    dict: T,
  ): T[K][];
  public transform<K extends string, T extends Partial<Record<K, unknown>>>(
    value: K[] | null | undefined,
    dict: T,
  ): T[K][] {
    if (!Array.isArray(value)) {
      return [];
    }

    return value.map((i) => dict[i]);
  }
}
