import { Pipe, PipeTransform } from '@angular/core';
import { FileApiService, FileDto } from '@app/core/api/file';
import { catchError, map, Observable, of } from 'rxjs';

@Pipe({
  name: 'mediaResizedImage',
})
export class MediaResizedImagePipe implements PipeTransform {
  constructor(private readonly fileApi: FileApiService) {}

  public transform(file: FileDto, width: number, height: number): Observable<string> {
    if (!file.id) {
      return of(file.url);
    }

    if (file.width && file.height && file.width <= width && file.height <= height) {
      return of(file.url);
    }

    const sizeCode = `${width}x${height}`;
    const size = Array.isArray(file.sizes) ? file.sizes.find((i) => i.size === sizeCode) : null;

    return size
      ? of(size.url)
      : file.id
        ? this.fileApi.getResizedImage(file.id, { width, height }).pipe(
            map((response) => response.data?.url || file.url),
            catchError(() => of(file.url)),
          )
        : of(file.url);
  }
}
