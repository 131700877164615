import { ApiPaginationRequestDto } from '../../common-dtos';
import { GoalStatusEnum } from '../../goal';

export type TransactionOutgoingOrderKey =
  | 'createdAt'
  | '-createdAt'
  | 'paymentDate'
  | '-paymentDate'
  | 'amount'
  | '-amount';

export interface TransactionOutgoingRequestDto extends ApiPaginationRequestDto {
  goalId?: string | null;
  goalStatuses?: GoalStatusEnum[] | null;
  order?: TransactionOutgoingOrderKey | null;
}
