import { OverlayRef } from '@angular/cdk/overlay';
import { Observable, Subject } from 'rxjs';

import { SnackBarContainerComponent } from './snack-bar-container/snack-bar-container.component';

const MAX_TIMEOUT = Math.pow(2, 31) - 1;

export class SnackBarRef<T> {
  public instance!: T;

  public containerInstance: SnackBarContainerComponent;

  private readonly afterDismissedSubject = new Subject<void>();

  private readonly afterOpenedSubject = new Subject<void>();

  private durationTimeoutId?: number;

  constructor(
    containerInstance: SnackBarContainerComponent,
    private _overlayRef: OverlayRef,
  ) {
    this.containerInstance = containerInstance;
    containerInstance.onExit.subscribe(() => this.finishDismiss());
  }

  public dismiss(): void {
    if (!this.afterDismissedSubject.closed) {
      this.containerInstance.exit();
    }

    clearTimeout(this.durationTimeoutId);
  }

  public dismissAfter(duration: number): void {
    this.durationTimeoutId = setTimeout(() => this.dismiss(), Math.min(duration, MAX_TIMEOUT)) as unknown as number;
  }

  public open(): void {
    if (!this.afterOpenedSubject.closed) {
      this.afterOpenedSubject.next();
      this.afterOpenedSubject.complete();
    }
  }

  private finishDismiss(): void {
    this._overlayRef.dispose();

    this.afterDismissedSubject.next();
    this.afterDismissedSubject.complete();
  }

  public afterDismissed(): Observable<void> {
    return this.afterDismissedSubject;
  }

  public afterOpened(): Observable<void> {
    return this.containerInstance.onEnter;
  }
}
