import { GoalDto } from '@app/core/api/goal/dtos/goal.dto';
import { PayerDto, PayerTypeEnum } from '@app/core/api/goal/dtos/goal-sponsor.dto';

export interface SupportedGoalDto {
  payerId: string | null;
  payerType: PayerTypeEnum;
  payer: PayerDto | null;
  amount: number | null;
  goalId: string;
  donateCount: number | null;
  goal: GoalDto | null;
}
