import { ContextWithImplicit } from '@app/shared/interfaces/context-with-implicit.interface';

import type { LetDirective } from './let.directive';

/**
 * @internal
 */
export class LetContext<T> implements ContextWithImplicit<T> {
  constructor(private readonly internalDirectiveInstance: LetDirective<T>) {}

  public get $implicit(): T {
    return this.internalDirectiveInstance.appLet;
  }

  public get appLet(): T | null {
    return this.internalDirectiveInstance.appLet;
  }
}
