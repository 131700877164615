<div class="header-profile" [class._opened]="panelOpen" #trigger (click)="toggle()">
  <a
    class="avatar"
    [routerLink]="['/profile']"
    appUserAvatar
    [avatar]="profile.avatar"
    (click)="$event.stopPropagation()"
  ></a>
  <div class="name">
    <ng-container *ngIf="profile.lastName || profile.firstName; else email">
      {{ profile.firstName }} {{ profile.lastName }}
    </ng-container>
    <ng-template #email>{{ profile.contactEmail }}</ng-template>
  </div>
  <div class="dropdown-btn">
    <svg-icon src="dropdown-arrow"></svg-icon>
  </div>
</div>

<ng-template
  cdk-connected-overlay
  cdkConnectedOverlayLockPosition
  cdkConnectedOverlayHasBackdrop
  cdkConnectedOverlayBackdropClass="cdk-overlay-transparent-backdrop"
  [cdkConnectedOverlayOrigin]="cdkOverlayOrigin"
  [cdkConnectedOverlayOpen]="panelOpen"
  [cdkConnectedOverlayPositions]="positions"
  [cdkConnectedOverlayWidth]="overlayWidth | default: 'auto'"
  [cdkConnectedOverlayMinWidth]="200"
  (backdropClick)="close()"
  (detach)="close()"
>
  <div role="menu" tabindex="-1" class="profile-menu _opened">
    <a class="profile-menu__item _clickable" role="menuitem" [routerLink]="['/profile']" (click)="close()">
      <svg-icon src="user"></svg-icon>
      <span>Профиль</span>
    </a>
    <a class="profile-menu__item _clickable" role="menuitem" [routerLink]="['/finance']" (click)="close()">
      <svg-icon src="documents"></svg-icon>
      <span>Финансы</span>
    </a>
    <div class="profile-menu__item _divider-top">
      <div class="divider"></div>
    </div>
    <a class="profile-menu__item _clickable" role="menuitem" [routerLink]="['/settings']" (click)="close()">
      <svg-icon src="settings"></svg-icon>
      <span>Настройки</span>
    </a>
    <div class="profile-menu__item _divider-bottom">
      <div class="divider"></div>
    </div>
    <button class="profile-menu__item _clickable" role="menuitem" (click)="logoutSelected.emit(); close()">
      Выход
    </button>
  </div>
</ng-template>
