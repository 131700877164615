import { Injectable, isDevMode } from '@angular/core';
import { GoalDto } from '@app/core/api/goal';
import { PaymentCardDto } from '@app/core/api/payment';
import { ScoringDocumentDto, ScoringDocumentTypeEnum, ScoringDto, ScoringPassportDto } from '@app/core/api/scoring';
import { GoalCurrentService } from '@app/features/goal/goal-current/goal-current.service';
import { combineLatest, map, ReplaySubject, tap } from 'rxjs';

export const GOAL_TOTAL_STEPS = 5;

function checkPassportCompleted(passport?: ScoringPassportDto | null): boolean {
  return (
    !!passport?.lastName &&
    !!passport.firstName &&
    !!passport.patronymic &&
    !!passport.birthdate &&
    !!passport.registrationAddress
  );
}

function checkScoringDocumentsCompleted(documents?: ScoringDocumentDto[]): boolean {
  return (
    !!documents &&
    [
      ScoringDocumentTypeEnum.PassportMain,
      ScoringDocumentTypeEnum.PlaceRegistration,
      ScoringDocumentTypeEnum.SelfieWithPassport,
    ].every((requiredType) => documents.find((doc) => doc.type === requiredType))
  );
}

@Injectable({ providedIn: 'root' })
export class GoalCurrentProgressService {
  completedSteps$ = new ReplaySubject<number[]>(1);

  continueRoute$ = this.completedSteps$.pipe(map((completedSteps) => this.getContinueRoute(completedSteps)));

  goalCompleted$ = this.completedSteps$.pipe(map((steps) => steps.length === GOAL_TOTAL_STEPS));

  goalEmpty$ = new ReplaySubject<boolean>(1);

  nextStep$ = this.completedSteps$.pipe(map((completedSteps) => this.getNextStep(completedSteps)));

  constructor(private readonly goalCurrentService: GoalCurrentService) {
    combineLatest([
      this.goalCurrentService.currentGoal$,
      this.goalCurrentService.payoutCards$,
      this.goalCurrentService.scoring$,
    ])
      .pipe(
        tap(([goal, payoutCards, scoring]) => this.parseCompletedSteps(goal, payoutCards, scoring)),
        tap(([goal]) => this.parseGoalEmpty(goal)),
      )
      .subscribe();
  }

  protected parseGoalEmpty(goal: GoalDto | null): void {
    const isEmpty =
      !goal ||
      (!goal.title && !goal.content.description && !goal.content.mainImage && !goal.goalAmount && !goal.activeUntil);
    this.goalEmpty$.next(isEmpty);
  }

  protected parseCompletedSteps(goal: GoalDto | null, payoutCards: PaymentCardDto[], scoring: ScoringDto | null): void {
    const completedSteps: number[] = [];

    if (goal) {
      for (let i = 1; i <= GOAL_TOTAL_STEPS; i++) {
        const isCompleted = this.isStepCompleted(i, goal, payoutCards, scoring);

        if (isCompleted) {
          completedSteps.push(i);
        }
      }
    }

    this.completedSteps$.next(completedSteps);
  }

  protected isStepCompleted(
    step: number,
    goal: GoalDto,
    payoutCards: PaymentCardDto[],
    scoring: ScoringDto | null,
  ): boolean {
    switch (step) {
      case 1:
        return !!goal.title && !!goal.content?.description;
      case 2:
        return !!goal.content?.mainImage;
      case 3:
        return !!goal.goalAmount && !!goal.activeUntil;
      case 4:
        return (
          !!goal.scoringId &&
          checkPassportCompleted(scoring?.passport) &&
          checkScoringDocumentsCompleted(scoring?.documents)
        );
      case 5:
        return !!payoutCards.length;
      default:
        if (isDevMode()) {
          console.warn(`Unexpected step num: ${step}`);
        }

        return true;
    }
  }

  protected getContinueRoute(completedSteps: number[]): string[] {
    const step = this.getNextStep(completedSteps);
    return ['/goal', 'wizard', step];
  }

  protected getNextStep(completedSteps: number[]): string {
    for (let i = 1; i <= GOAL_TOTAL_STEPS; i++) {
      if (!completedSteps.includes(i)) {
        return `${i}`;
      }
    }

    return '1';
  }
}
