import { Platform } from '@angular/cdk/platform';
import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { environment } from '@app/environment';

@Injectable({ providedIn: 'root' })
export class MetaInitService {
  constructor(
    private readonly meta: Meta,
    @Inject(PLATFORM_ID) private readonly platformId: string,
    private readonly platform: Platform,
  ) {}

  public init(): void {
    if (!environment.production) {
      this.meta.addTag({ name: 'robots', content: 'noindex' });
    }

    if (isPlatformBrowser(this.platformId)) {
      if (this.platform.IOS) {
        this.meta.removeTag('name=viewport');
        this.meta.addTag({
          name: 'viewport',
          content: 'width=device-width, initial-scale=1, maximum-scale=1',
        });
      }
    }
  }
}
