import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { GuardsCheckEnd, NavigationCancel, NavigationEnd, Router } from '@angular/router';
import { AuthService } from '@app/core/auth';
import { debounceTime, filter, forkJoin } from 'rxjs';
import { finalize, take } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class PreloaderService {
  constructor(
    private readonly router: Router,
    private readonly authService: AuthService,
    @Inject(PLATFORM_ID) private readonly platformId: string,
    @Inject(DOCUMENT) private readonly document: Document,
  ) {
    if (isPlatformBrowser(this.platformId)) {
      const navigationEnd$ = this.router.events.pipe(
        filter(
          (event) =>
            event instanceof GuardsCheckEnd || event instanceof NavigationCancel || event instanceof NavigationEnd,
        ),
        take(1),
      );

      const profileLoaded$ = this.authService.initialized$.pipe(filter(Boolean), take(1));

      forkJoin([navigationEnd$, profileLoaded$])
        .pipe(
          debounceTime(1000),
          finalize(() => this.hidePreloaderIfExists()),
        )
        .subscribe();
    } else {
      this.hidePreloaderIfExists();
    }
  }

  protected hidePreloaderIfExists(): void {
    const preloader = this.document.getElementById('preloader');

    if (preloader) {
      preloader.remove();
    }

    this.document.body.classList.remove('_preloading');
  }
}
