import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ArrayEnumPipe } from './array-enum.pipe';
import { ArrayNestedFieldPipe } from './array-nested-field.pipe';
import { ArrayPartsPipe } from './array-parts.pipe';
import { IncludesPipe } from './includes.pipe';
import { IterateRangePipe } from './iterate-range.pipe';
import { JoinPipe } from './join.pipe';

@NgModule({
  declarations: [IncludesPipe, ArrayPartsPipe, ArrayEnumPipe, JoinPipe, ArrayNestedFieldPipe, IterateRangePipe],
  exports: [IncludesPipe, ArrayPartsPipe, ArrayEnumPipe, JoinPipe, ArrayNestedFieldPipe, IterateRangePipe],
  imports: [CommonModule],
})
export class ArrayPipesModule {}
