import { EnvironmentInterface } from './environment.interface';

export const environment: EnvironmentInterface = {
  apiUrl: 'https://api.new-time.dev.tes.store',
  production: false,
  isLocal: false,
  firebaseConfig: {
    apiKey: 'AIzaSyDFW60-ULMgTDulwUwcaADYL1iQVT4MaUs',
    authDomain: 'tes-store-83616.firebaseapp.com',
    projectId: 'tes-store-83616',
    storageBucket: 'tes-store-83616.appspot.com',
    messagingSenderId: '52291429504',
    appId: '1:52291429504:web:1dc886cc9affdfcc1ff265',
    measurementId: 'G-3LTKX3HLZE',
    vapidKey: 'BK5QDpS5thIBWwH9AN9Wl2h8LYkgRfMntZM6aZNye2HaJR-5iHh0FiUJIxwE9GzKcjADv4ZVL18tpef6B3GUOKI',
  },
};
