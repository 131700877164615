<div class="dialog">
  <button app-icon-button class="close-btn" type="button" (click)="close()">
    <svg-icon src="close"></svg-icon>
  </button>
  <div class="dialog__body">
    <ng-container *ngIf="sent$ | async; else resendForm">
      <div class="dialog__title">Отправлено</div>
      <div>На адрес {{ email }} отправлена ссылка для подтверждения электронной почты</div>
      <div class="actions">
        <button type="button" app-flat-button color="primary" size="small" (click)="close()">Вернуться к цели</button>
      </div>
    </ng-container>
  </div>
</div>

<ng-template #resendForm>
  <div class="dialog__title">Подтвердите почту</div>
  <div>Для продолжения необходимо подтвердить вашу почту</div>
  <div class="actions">
    <button
      type="button"
      app-flat-button
      color="primary"
      size="small"
      [disabled]="this | isLoading | async"
      (click)="resend()"
    >
      Отправить письмо заново
    </button>
  </div>
</ng-template>
