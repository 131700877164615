/* eslint-disable @typescript-eslint/ban-ts-comment, @typescript-eslint/no-explicit-any */
import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { ProfileCurrentDto } from '@app/core/api/profile';
import { AuthService } from '@app/core/auth';
import { filter, map, Observable, tap, timer } from 'rxjs';
import { take } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class SupportChatWidgetService {
  protected threadsWidget: any;

  protected chatInited = false;

  constructor(
    private readonly authService: AuthService,
    @Inject(DOCUMENT) private readonly document: Document,
  ) {
    this.watchChatIsReady()
      .pipe(tap(() => this.startWatchProfile()))
      .subscribe();
  }

  protected watchChatIsReady(): Observable<boolean> {
    return timer(100).pipe(
      // @ts-ignore
      map(() => window.ThreadsWidget),
      tap((threadsWidget) => (this.threadsWidget = threadsWidget)),
      filter(Boolean),
      take(1),
      map(() => true),
    );
  }

  protected startWatchProfile(): void {
    this.authService.profile$.pipe(tap((profile) => this.initChat(profile))).subscribe();
  }

  protected initChat(profile: ProfileCurrentDto | null): void {
    if (!profile) {
      if (this.chatInited) {
        this.threadsWidget.unInit();
        this.chatInited = false;
      }

      return;
    }

    const clientData = {
      project: 'new-time',
      name: profile.fullName || `${profile.lastName || ''} ${profile.firstName || ''}`.trim(),
      email: profile.email,
      phone: profile.contactPhone,
      userId: profile.userId,
    };

    if (this.chatInited) {
      this.threadsWidget.reInit({
        clientId: `new-time-${profile.userId}`,
        clientData: JSON.stringify(clientData),
      });
    } else {
      this.threadsWidget.init({
        configurationFile: `/chat-settings.json?t=${Date.now()}`,
        clientId: `new-time-${profile.userId}`,
        clientData: JSON.stringify(clientData),
      });
    }

    this.chatInited = true;
  }
}
