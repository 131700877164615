<footer class="footer">
  <div class="footer__inner" [class.container]="container" [class._large]="container">
    <div class="footer-nav">
      <div class="footer-nav-col">
        <div class="footer-subtitle">Меню</div>
        <div class="footer-nav-list">
          <a [routerLink]="['/about']" class="footer-nav-link">О платформе</a>
          <a [routerLink]="['/faq']" class="footer-nav-link">Вопросы и ответы</a>
          <a [routerLink]="['/docs']" class="footer-nav-link d-md-none">Юридические Документы</a>
        </div>
      </div>
      <div class="footer-nav-col d-md-none"></div>
      <div class="footer-nav-col">
        <button class="footer-subtitle _link" type="button" (click)="openFeedbackDialog()">Свяжитесь с нами</button>
        <div class="footer-nav-list d-none d-md-flex">
          <a [routerLink]="['/docs']" class="footer-nav-link">Юридические Документы</a>
        </div>
      </div>
      <div class="footer-nav-col _socials">
        <div class="footer-subtitle">Соц.сети</div>
        <div class="footer-socials">
          <a href="https://t.me/tcelirf" target="_blank" class="footer-socials-link">
            <svg-icon src="tg"></svg-icon>
          </a>
        </div>
      </div>
    </div>
    <div class="footer-description">
      Вся представленная на сайте информация носит информационный характер и не является публичной офертой, определяемой
      положениями ст.437(2) ГК РФ. Все цены, указанные на данном сайте, носят исключительно информационный характер. Для
      получения подробной информации просьба обращаться в Службу поддержки. Опубликованная на данном сайте информация
      может быть изменена в любое время без предварительного уведомления.
    </div>
    <div class="footer-copyright">©{{ year }}</div>
  </div>
</footer>
