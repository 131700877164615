import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'join',
})
export class JoinPipe implements PipeTransform {
  public transform<T>(value: T[] | null | undefined, separator = ', '): string {
    return Array.isArray(value) ? value.join(separator) : '';
  }
}
