import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import {
  AuthApiService,
  AuthExternalLoginResponseDto,
  AuthExternalProviderEnum,
  ImpersonateRequestDto,
  LoginRequestDto,
  QuickRegisterRequestDto,
  RegisterRequestDto,
} from '@app/core/api/auth';
import { ProfileApiService, ProfileDto, ProfileUpdateRequestDto } from '@app/core/api/profile';
import { NotificationsMessagingProvider } from '@app/core/notifications/notifications-messaging.provider';
import { AppHostUrlProvider } from '@app/core/tokens';
import { Store } from '@ngxs/store';
import { EMPTY, filter, map, Observable, shareReplay, switchMap, tap } from 'rxjs';
import { catchError, take } from 'rxjs/operators';

import { AuthProfileModel } from '../models';
import { AuthState, AuthStateActions } from '../states';

@Injectable({ providedIn: 'root' })
export class AuthService {
  profile$: Observable<AuthProfileModel | null>;

  initialized$: Observable<boolean>;

  authenticated$: Observable<boolean>;

  public get authenticated(): boolean {
    return !!this.store.selectSnapshot(AuthState.profile);
  }

  public get profile(): AuthProfileModel | null {
    return this.store.selectSnapshot(AuthState.profile);
  }

  constructor(
    @Inject(PLATFORM_ID) private readonly platformId: string,
    private readonly store: Store,
    private readonly authApi: AuthApiService,
    private readonly profileApi: ProfileApiService,
    private readonly notificationsMessagingProvider: NotificationsMessagingProvider,
    private readonly hostUrlProvider: AppHostUrlProvider,
  ) {
    this.initialized$ = this.store.select(AuthState.initialized).pipe(filter(Boolean), take(1));

    this.profile$ = this.initialized$.pipe(
      switchMap(() => this.store.select(AuthState.profile)),
      shareReplay(1),
    );

    this.authenticated$ = this.profile$.pipe(map(Boolean));
  }

  public init(): void {
    this.reloadProfile();
  }

  public login(request: LoginRequestDto): Observable<unknown> {
    return this.authApi.login(request).pipe(tap(() => this.reloadProfile()));
  }

  public register(request: RegisterRequestDto): Observable<unknown> {
    return this.authApi.register(request);
  }

  public logout(): Observable<unknown> {
    return this.authApi.logout().pipe(
      switchMap(() => this.notificationsMessagingProvider.deleteToken().pipe(catchError(() => EMPTY))),
      tap(() => this.store.dispatch(new AuthStateActions.ClearProfile())),
      tap(() => {
        if (isPlatformBrowser(this.platformId)) {
          window.location.reload();
        }
      }),
    );
  }

  public updateProfile(request: ProfileUpdateRequestDto): Observable<ProfileDto> {
    return this.profileApi.update(request).pipe(
      map((response) => response.data),
      tap((profile) => this.store.dispatch(new AuthStateActions.SetProfile(profile))),
    );
  }

  public impersonate(request: ImpersonateRequestDto): Observable<unknown> {
    this.store.dispatch(new AuthStateActions.ClearProfile());

    return this.notificationsMessagingProvider.deleteToken().pipe(
      catchError(() => EMPTY),
      switchMap(() => this.authApi.impersonate(request)),
      switchMap(() => this.profileApi.getProfile()),
      tap((response) => this.store.dispatch(new AuthStateActions.SetProfile(response.data))),
    );
  }

  public quickRegistration(request: QuickRegisterRequestDto): Observable<AuthProfileModel> {
    return this.authApi.quickRegister(request).pipe(
      switchMap(() => this.profileApi.getProfile()),
      map((response) => response.data),
      tap((profile) => this.store.dispatch(new AuthStateActions.SetProfile(profile))),
    );
  }

  public externalLoginStart(provider: AuthExternalProviderEnum, appBackUrl?: string | null): Observable<unknown> {
    const authRedirectUrl = this.hostUrlProvider.getFullRouteUrl(['oauth', 'login', provider]);

    return this.authApi
      .externalAuthStart(provider, { authRedirectUrl, appBackUrl })
      .pipe(tap((response) => window.open(response.data.url, '_self')));
  }

  public externalRegisterStart(provider: AuthExternalProviderEnum): Observable<unknown> {
    const authRedirectUrl = this.hostUrlProvider.getFullRouteUrl(['oauth', 'sign-up', provider]);

    return this.authApi
      .externalAuthStart(provider, { authRedirectUrl })
      .pipe(tap((response) => window.open(response.data.url, '_self')));
  }

  public externalLogin(
    provider: AuthExternalProviderEnum,
    redirectQuery: object,
  ): Observable<AuthExternalLoginResponseDto> {
    return this.authApi.externalLogin(provider, redirectQuery).pipe(
      map((response) => response.data),
      tap(() => this.reloadProfile()),
    );
  }

  public externalRegister(provider: AuthExternalProviderEnum, redirectQuery: object): Observable<unknown> {
    return this.authApi.externalRegister(provider, redirectQuery).pipe(tap(() => this.reloadProfile()));
  }

  protected reloadProfile(): void {
    this.store.dispatch(new AuthStateActions.LoadProfile());
  }
}
