import { ChangeDetectionStrategy, Component, Input, numberAttribute, ViewEncapsulation } from '@angular/core';
import { FileDto } from '@app/core/api/file';

@Component({
  selector: '[appUserAvatar]',
  templateUrl: './user-avatar.component.html',
  styleUrls: ['./user-avatar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'user-avatar',
    '[class._empty]': '!avatar',
  },
})
export class UserAvatarComponent {
  @Input({ required: true }) avatar?: FileDto | null;

  @Input({ transform: numberAttribute }) resizeWidth = 90;

  @Input({ transform: numberAttribute }) resizeHeight = 90;
}
