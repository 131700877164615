import {
  NotificationTransportEnum,
  NotificationUserPreferencesTransportDto,
} from './notification-user-preferences.dto';

export type NotificationUserPreferencesTransportsUpdateDto = Partial<
  Record<NotificationTransportEnum, NotificationUserPreferencesTransportDto>
>;

export interface NotificationUserPreferencesUpdateRequestDto {
  transports: NotificationUserPreferencesTransportsUpdateDto;
}
