import { Dialog, DialogRef } from '@angular/cdk/dialog';
import { ChangeDetectionStrategy, Component, Self } from '@angular/core';
import { SupportApiService } from '@app/core/api/support';
import { LoaderService } from '@app/core/loader';
import { LandingFeedbackSectionComponent } from '@app/pages/landing-page/landing-feedback-section/landing-feedback-section.component';
import { SnackBarService } from '@app/shared/components/snack-bar';
import { DOC_URLS } from '@app/shared/consts';
import { DestroyService } from '@app/shared/utils';

@Component({
  selector: 'app-support-feedback-dialog',
  templateUrl: './support-feedback-dialog.component.html',
  styleUrls: ['./support-feedback-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DestroyService],
})
export class SupportFeedbackDialogComponent extends LandingFeedbackSectionComponent {
  constructor(
    private readonly dialogRef: DialogRef<void>,
    supportApi: SupportApiService,
    loaderService: LoaderService,
    snackBar: SnackBarService,
    @Self() destroy$: DestroyService,
  ) {
    super(supportApi, loaderService, snackBar, destroy$);
  }

  public static open(dialog: Dialog): DialogRef<void> {
    return dialog.open<void>(SupportFeedbackDialogComponent);
  }

  public close(): void {
    this.dialogRef.close();
  }

  protected readonly DOC_URLS = DOC_URLS;
}
