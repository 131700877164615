import { SelectionModel } from '@angular/cdk/collections';
import { ConnectedPosition } from '@angular/cdk/overlay';
import { booleanAttribute, ChangeDetectionStrategy, Component, Input, OnInit, Self } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AuthService } from '@app/core/auth';
import { DestroyService } from '@app/shared/utils';
import { filter, takeUntil, tap } from 'rxjs';

export interface HeaderMenuItems {
  title: string;
  route: string[];
  children?: HeaderMenuItems[];
}

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DestroyService],
})
export class HeaderComponent implements OnInit {
  @Input({ transform: booleanAttribute }) hideShadow = false;

  @Input({ transform: booleanAttribute }) transparent = false;

  @Input({ transform: booleanAttribute }) container = false;

  @Input({ transform: booleanAttribute }) hideCreateGoalButton = false;

  @Input({ transform: booleanAttribute }) scrolled = false;

  profile$ = this.authService.profile$;

  menuItems: HeaderMenuItems[] = [
    {
      title: 'Профессионалы',
      route: ['/catalog'],
    },
    {
      title: 'Цели',
      route: ['/catalog', 'goals'],
    },
    {
      title: 'Лента',
      route: ['/catalog', 'news'],
    },
    {
      title: 'О платформе',
      route: ['/about'],
      children: [
        {
          title: 'О платформе',
          route: ['/about'],
        },
        {
          title: 'Вопросы и ответы',
          route: ['/faq'],
        },
      ],
    },
  ];

  openedDropdownMenuIndexes = new SelectionModel<number>();

  dropdownMenuPositions: ConnectedPosition[] = [
    {
      originX: 'center',
      originY: 'bottom',
      overlayX: 'center',
      overlayY: 'top',
    },
  ];

  constructor(
    private readonly authService: AuthService,
    private readonly router: Router,
    @Self() private readonly destroy$: DestroyService,
  ) {}

  public ngOnInit(): void {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        tap(() => this.openedDropdownMenuIndexes.clear()),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  public clickLogo(): void {
    if (window) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  }

  public logout(): void {
    this.authService.logout().subscribe();
  }
}
