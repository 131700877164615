import { Injectable } from '@angular/core';
import { CategoryApiService, CategoryDto } from '@app/core/api/category';
import { CategoryStateActions } from '@app/features/category/category-store/category.state.actions';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { catchError, EMPTY, map, Observable, tap } from 'rxjs';

type CategoryStateModel = CategoryDto[];

const EMPTY_STATE: CategoryStateModel = [];

@State<CategoryStateModel>({
  name: 'category',
  defaults: { ...EMPTY_STATE },
})
@Injectable()
export class CategoryState {
  @Selector()
  public static categories(state: CategoryStateModel): CategoryDto[] {
    return state;
  }

  constructor(private readonly categoryApi: CategoryApiService) {}

  @Action(CategoryStateActions.Load, { cancelUncompleted: true })
  public load({ setState }: StateContext<CategoryStateModel>): Observable<CategoryDto[]> {
    return this.categoryApi
      .find({
        onPage: 200,
        page: 0,
      })
      .pipe(
        map((response) => response.data.rows),
        tap((categories) => setState(categories)),
        catchError(() => EMPTY),
      );
  }
}
