import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiPaginatedResponseDto } from '@app/core/api/common-dtos/api-paginated-response.dto';
import { ApiResponseDto } from '@app/core/api/common-dtos/api-response.dto';
import { ProfileShortRequestDto } from '@app/core/api/profile/dtos/profile-short.request.dto';
import { buildHttpParams } from '@app/shared/utils';
import { Observable } from 'rxjs';

import {
  IsSubscribedRequestDto,
  IsSubscribedResponseDto,
  ProfileCurrentDto,
  ProfileDto,
  ProfileShortDto,
  ProfileStatisticsDto,
  ProfileUpdateRequestDto,
  SubscribeRequestDto,
  SubscribeResponseDto,
  SubscriptionDto,
  SubscriptionsRequestDto,
  SupportedGoalDto,
  SupportedGoalsRequestDto,
  SupportedUsersRequestDto,
} from './dtos';
import { ProfileCheckSlugRequestDto } from './dtos/profile-check-slug.request.dto';
import { ProfileCheckSlugResponseDto } from './dtos/profile-check-slug.response.dto';

@Injectable({ providedIn: 'root' })
export class ProfileApiService {
  constructor(private readonly http: HttpClient) {}

  public getProfile(): Observable<ApiResponseDto<ProfileCurrentDto>> {
    return this.http.get<ApiResponseDto<ProfileCurrentDto>>('/api/profile', {
      params: buildHttpParams({ t: Date.now() }),
    });
  }

  public update(request: ProfileUpdateRequestDto): Observable<ApiResponseDto<ProfileCurrentDto>> {
    return this.http.put<ApiResponseDto<ProfileCurrentDto>>('/api/profile', request);
  }

  public getSubscriptions(
    request: SubscriptionsRequestDto,
    bypassCache?: boolean,
  ): Observable<ApiResponseDto<ApiPaginatedResponseDto<SubscriptionDto>>> {
    let params = buildHttpParams(request);

    if (bypassCache) {
      params = params?.append('t', Date.now());
    }

    return this.http.get<ApiResponseDto<ApiPaginatedResponseDto<SubscriptionDto>>>('/api/profile/subscriptions', {
      params,
    });
  }

  public getFollowers(
    request: SubscriptionsRequestDto,
  ): Observable<ApiResponseDto<ApiPaginatedResponseDto<SubscriptionDto>>> {
    return this.http.get<ApiResponseDto<ApiPaginatedResponseDto<SubscriptionDto>>>('/api/profile/followers', {
      params: buildHttpParams(request),
    });
  }

  public isSubscribed(request: IsSubscribedRequestDto): Observable<ApiResponseDto<IsSubscribedResponseDto[]>> {
    return this.http.post<ApiResponseDto<IsSubscribedResponseDto[]>>('/api/profile/is-subscribed', request);
  }

  public subscribe(request: SubscribeRequestDto): Observable<ApiResponseDto<SubscribeResponseDto>> {
    return this.http.post<ApiResponseDto<SubscribeResponseDto>>('/api/profile/subscribe', request);
  }

  public unsubscribe(request: SubscribeRequestDto): Observable<ApiResponseDto<SubscribeResponseDto>> {
    return this.http.post<ApiResponseDto<SubscribeResponseDto>>('/api/profile/unsubscribe', request);
  }

  public getSupportedGoals(
    request: SupportedGoalsRequestDto,
  ): Observable<ApiResponseDto<ApiPaginatedResponseDto<SupportedGoalDto>>> {
    return this.http.get<ApiResponseDto<ApiPaginatedResponseDto<SupportedGoalDto>>>('/api/profile/supported/goals', {
      params: buildHttpParams(request),
    });
  }

  public getSupportedUsers(
    request: SupportedUsersRequestDto,
  ): Observable<ApiResponseDto<ApiPaginatedResponseDto<ProfileDto>>> {
    return this.http.get<ApiResponseDto<ApiPaginatedResponseDto<ProfileDto>>>('/api/profile/supported/users', {
      params: buildHttpParams(request),
    });
  }

  public getShortProfiles(
    request: ProfileShortRequestDto,
  ): Observable<ApiResponseDto<ApiPaginatedResponseDto<ProfileShortDto>>> {
    return this.http.get<ApiResponseDto<ApiPaginatedResponseDto<ProfileShortDto>>>('/api/catalog/short-profiles', {
      params: buildHttpParams(request),
    });
  }

  public getStatistics(): Observable<ApiResponseDto<ProfileStatisticsDto>> {
    return this.http.get<ApiResponseDto<ProfileStatisticsDto>>('/api/profile/statistics');
  }

  public checkSlug(request: ProfileCheckSlugRequestDto): Observable<ApiResponseDto<ProfileCheckSlugResponseDto>> {
    return this.http.post<ApiResponseDto<ProfileCheckSlugResponseDto>>('/api/profile/check-slug', request);
  }
}
