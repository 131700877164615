<section appFileDrop (filesDropped)="addAttachments($event)">
  <div class="feedback container">
    <div class="feedback-row">
      <div class="feedback-col">
        <div class="feedback-content">
          <h3 class="feedback-title heading">
            Нужна помощь или <br />
            остались вопросы?
          </h3>
          <div class="feedback-subtitle">Заполните форму и мы вам перезвоним.</div>

          <div class="d-none d-md-block">
            <ng-container [ngTemplateOutlet]="socials"></ng-container>
          </div>
        </div>
      </div>
      <div class="feedback-col">
        <form [formGroup]="form" (ngSubmit)="submit()">
          <div class="controls">
            <app-form-field>
              <app-form-field-label>Имя</app-form-field-label>
              <input appInputTextControl type="text" name="name" autocomplete="name" formControlName="name" />
            </app-form-field>

            <app-form-field>
              <app-form-field-label>Телефон</app-form-field-label>
              <input
                appInputTextControl
                type="text"
                name="phone"
                formControlName="phone"
                autocomplete="phone"
                inputmode="tel"
                [imask]="phoneMask"
                [unmask]="true"
              />

              <app-form-field-error *ngIf="form.controls.phone.hasError('pattern')">
                Некорректный номер
              </app-form-field-error>
            </app-form-field>

            <app-form-field>
              <app-form-field-label>Email</app-form-field-label>
              <input
                appInputTextControl
                type="email"
                name="email"
                autocomplete="email"
                formControlName="email"
                inputmode="email"
              />

              <app-form-field-error
                *ngIf="form.controls.email.hasError('email') || form.controls.email.hasError('pattern')"
              >
                Некорректная почта
              </app-form-field-error>
            </app-form-field>

            <app-form-field>
              <app-form-field-label>Комментарий</app-form-field-label>
              <textarea
                appInputTextControl
                name="comment"
                formControlName="comment"
                cdkTextareaAutosize
                [cdkAutosizeMinRows]="3"
              ></textarea>
            </app-form-field>

            <div class="attachment-list">
              <ng-container *ngIf="form.controls.attachments.value as files">
                <div class="attachment" *ngFor="let file of files; let i = index">
                  <svg-icon src="attachment"></svg-icon>
                  <span>{{ file.name }}</span>
                  <button app-icon-button type="button" (click)="removeAttachment(i)">
                    <svg-icon src="close"></svg-icon>
                  </button>
                </div>
              </ng-container>
              <div class="form-errors" *ngIf="form.controls.attachments.hasError('payloadTooLarge'); else attachButton">
                <app-form-field-error>Суммарный объем вложенных файлов превышает 10 МБ</app-form-field-error>
              </div>

              <ng-template #attachButton>
                <button class="attachment" app-link-button type="button" (click)="fileRef.click()">
                  <svg-icon src="attachment"></svg-icon>
                  <span>Прикрепить файл. Суммарный объем вложенных файлов не должен превышать 10 МБ</span>
                </button>

                <input #fileRef type="file" (change)="handleAttachmentInput($event)" multiple />
              </ng-template>
            </div>
            <div>
              <app-checkbox formControlName="policy">
                Я принимаю условия <a [href]="docUrls.userAgreement" target="_blank">пользовательского соглашения</a> и
                даю
                <a [href]="docUrls.personalDataProcessingConsent" target="_blank">
                  согласие на обработку <br class="d-none d-md-block" />
                  персональных данных
                </a>
              </app-checkbox>
            </div>
            <div>
              <button
                *ngIf="(sent$ | async) !== true; else sentSuccessful"
                app-flat-button
                color="primary"
                [disabled]="(this | isLoading | async) || form.invalid"
              >
                Отправить
              </button>
            </div>
          </div>
        </form>
      </div>
      <div class="feedback-col d-md-none">
        <ng-container [ngTemplateOutlet]="socials"></ng-container>
      </div>
    </div>
  </div>
</section>

<ng-template #socials>
  <div class="feedback-socials">
    <div class="feedback-socials__title">Или свяжись с нами через соц. сети</div>
    <div class="feedback-socials__list">
      <a href="https://t.me/tcelirf" target="_blank" class="feedback-socials__item">
        <svg-icon src="tg"></svg-icon>
      </a>
    </div>
  </div>
</ng-template>

<ng-template #sentSuccessful>
  <button type="submit" app-flat-button disabled color="primary">Успешно отправлено</button>
</ng-template>
