import { TextFieldModule } from '@angular/cdk/text-field';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { LoaderModule } from '@app/core/loader';
import { ButtonsModule } from '@app/shared/components/buttons/buttons.module';
import { CheckboxModule } from '@app/shared/components/forms/checkbox/checkbox.module';
import { FormFieldModule } from '@app/shared/components/forms/form-field/form-field.module';
import { FormSharedModule } from '@app/shared/components/forms/form-shared';
import { InputTextControlModule } from '@app/shared/components/forms/input-text-control/input-text-control.module';
import { IMaskDirective } from 'angular-imask';
import { SvgIconComponent } from 'angular-svg-icon';

import { SupportFeedbackDialogComponent } from './support-feedback-dialog.component';

@NgModule({
  declarations: [SupportFeedbackDialogComponent],
  imports: [
    CommonModule,
    ButtonsModule,
    SvgIconComponent,
    ReactiveFormsModule,
    FormFieldModule,
    FormSharedModule,
    InputTextControlModule,
    TextFieldModule,
    IMaskDirective,
    CheckboxModule,
    LoaderModule,
  ],
})
export class SupportFeedbackDialogModule {}
