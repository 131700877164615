import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { UserAvatarModule } from '@app/features/user/user-avatar/user-avatar.module';
import { UserPipesModule } from '@app/features/user/user-pipes/user-pipes.module';

import { NotificationPreviewItemComponent } from './notification-preview-item.component';

@NgModule({
  declarations: [NotificationPreviewItemComponent],
  imports: [CommonModule, UserAvatarModule, RouterModule, UserPipesModule],
  exports: [NotificationPreviewItemComponent],
})
export class NotificationPreviewItemModule {}
