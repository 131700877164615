<header
  class="header"
  [class._hide-shadow]="hideShadow"
  [class._transparent]="!scrolled && transparent"
  [class._scrolled]="scrolled"
  *appLet="profile$ | async as profile"
  cdk-overlay-origin
  #profileOverlayOrigin="cdkOverlayOrigin"
>
  <div class="header__inner" [class.container]="container" [class._large]="container">
    <a class="logo" [routerLink]="['/']" (click)="clickLogo()">
      <img class="logo__img" src="/assets/icons/logo.svg" alt="" />
      <span class="logo__content">
        <svg-icon class="logo__title" src="logo-text-bold" />
      </span>
    </a>

    <div class="header-menu">
      <ng-container *ngFor="let item of menuItems; let itemIndex = index">
        <ng-container *ngIf="item.children as children; else simpleMenuItem">
          <button
            class="header-dropdown-button header-button"
            type="button"
            #dropdownButton
            (click)="openedDropdownMenuIndexes.select(itemIndex)"
          >
            <span class="header-button">{{ item.title }}</span>
            <div
              class="header-dropdown-button__trigger"
              [class._opened]="openedDropdownMenuIndexes.isSelected(itemIndex)"
            >
              <svg-icon src="dropdown-arrow"></svg-icon>
            </div>
          </button>

          <ng-template
            cdk-connected-overlay
            cdkConnectedOverlayLockPosition
            cdkConnectedOverlayHasBackdrop
            cdkConnectedOverlayBackdropClass="cdk-overlay-transparent-backdrop"
            [cdkConnectedOverlayOrigin]="dropdownButton"
            [cdkConnectedOverlayOpen]="openedDropdownMenuIndexes.isSelected(itemIndex)"
            [cdkConnectedOverlayPositions]="dropdownMenuPositions"
            (backdropClick)="openedDropdownMenuIndexes.deselect(itemIndex)"
            (detach)="openedDropdownMenuIndexes.deselect(itemIndex)"
          >
            <div class="header-dropdown-menu">
              <a
                class="header-button header-dropdown-menu__item"
                (click)="openedDropdownMenuIndexes.deselect(itemIndex)"
                *ngFor="let child of children"
                [routerLink]="child.route"
                >{{ child.title }}</a
              >
            </div>
          </ng-template>
        </ng-container>

        <ng-template #simpleMenuItem>
          <a
            class="header-button"
            [class._active]="rla.isActive"
            [routerLink]="item.route"
            #rla="routerLinkActive"
            routerLinkActive
            [routerLinkActiveOptions]="{ exact: true }"
          >
            {{ item.title }}
          </a>
        </ng-template>
      </ng-container>
    </div>

    <div class="actions">
      <ng-container *appMediaShowDown="'sm'">
        <app-header-mobile-menu
          [profile]="profile"
          [cdkOverlayOrigin]="profileOverlayOrigin"
          [menuItems]="menuItems"
          (logoutSelected)="logout()"
        ></app-header-mobile-menu>
      </ng-container>
      <ng-container *appMediaShowUp="'md'">
        <a
          *ngIf="!hideCreateGoalButton && profile"
          app-flat-button
          type="button"
          color="secondary-outlined"
          size="small"
          [routerLink]="['/goal', 'wizard']"
        >
          Создать цель
        </a>

        <app-header-notifications *ngIf="profile"></app-header-notifications>

        <app-header-profile
          *ngIf="profile; else loginButton"
          [profile]="profile"
          [cdkOverlayOrigin]="profileOverlayOrigin"
          (logoutSelected)="logout()"
        ></app-header-profile>

        <ng-template #loginButton>
          <a
            [routerLink]="['/', 'auth']"
            class="_auth"
            app-flat-button
            type="button"
            color="secondary-outlined"
            size="small"
            >Вход | Регистрация</a
          >
        </ng-template>
      </ng-container>
    </div>
  </div>
</header>
