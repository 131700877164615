export const DOC_URLS = {
  /** Политика конфиденциальности персональных данных */
  privacyPolicy: '/assets/docs/privacy-policy.pdf',
  /** Реквизиты компании */
  companyRequisites: '/assets/docs/company-requisites.pdf',
  /** Пользовательское соглашение */
  userAgreement: '/assets/docs/user-agreement.pdf?v=2',
  /** Соглашение об использовании платформы привлечения коллективного финансирования */
  platformUseAgreement: '/assets/docs/platform-use-agreement.pdf?v=2',
  /** Согласие на получение рекламных рассылок */
  advertisingConsent: '/assets/docs/advertising-consent.pdf',
  /** Согласие на обработку персональных данных */
  personalDataProcessingConsent: '/assets/docs/personal-data-processing-consent.pdf',
};
