<ng-container *ngIf="headerParams$ | async as headerParams">
  <app-header
    *ngIf="!headerParams.hidden"
    [class._fixed]="headerParams.fixed"
    [class._scrolled]="scrolled"
    [hideShadow]="headerParams.hideShadow"
    [transparent]="headerParams.transparent"
    [container]="headerParams.container"
    [hideCreateGoalButton]="headerParams.hideCreateGoalButton"
    [scrolled]="headerParams.fixed && scrolled"
  ></app-header>
</ng-container>
<div class="page">
  <router-outlet></router-outlet>
</div>
<ng-container *ngIf="footerParams$ | async as footerParams">
  <app-footer *ngIf="!footerParams.hidden" [container]="footerParams.container"></app-footer>
</ng-container>
