/* eslint-disable @typescript-eslint/dot-notation */
import {
  ChangeDetectorRef,
  ComponentRef,
  Directive,
  ElementRef,
  Input,
  OnChanges,
  SimpleChanges,
  ViewContainerRef,
} from '@angular/core';

import { LoaderSpinnerComponent } from './loader-spinner/loader-spinner.component';

@Directive({
  selector: '[appLoader]',
})
export class LoaderDirective implements OnChanges {
  @Input('appLoader')
  isLoading: boolean | null = false;

  protected hostElement!: HTMLElement;

  protected spinnerElement?: HTMLElement | null;

  protected spinnerComponentRef?: ComponentRef<unknown> | null;

  constructor(
    protected readonly elementRef: ElementRef<HTMLElement>,
    protected readonly changeDetectorRef: ChangeDetectorRef,
    protected readonly viewContainerRef: ViewContainerRef,
  ) {
    this.hostElement = this.elementRef.nativeElement;
    this.hostElement.style.position = 'relative';
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['isLoading']) {
      const isLoadingValue = changes['isLoading'].currentValue;

      if (isLoadingValue) {
        this.addLoadingIndicator();
      } else {
        this.removeLoadingIndicator();
      }

      this.changeDetectorRef.markForCheck();
    }
  }

  protected addLoadingIndicator(): void {
    if (this.spinnerComponentRef) {
      this.spinnerComponentRef.destroy();
    }

    if (this.spinnerElement) {
      this.elementRef.nativeElement.removeChild(this.spinnerElement);
      this.spinnerElement.remove();
      this.spinnerElement = null;
    }

    this.spinnerComponentRef = this.viewContainerRef.createComponent(LoaderSpinnerComponent);
    const spinnerElement = this.spinnerComponentRef.location.nativeElement;

    if (spinnerElement) {
      this.hostElement.append(spinnerElement);
      this.spinnerElement = spinnerElement;
    }
  }

  protected removeLoadingIndicator(): void {
    if (this.spinnerComponentRef) {
      this.spinnerComponentRef.destroy();
      this.spinnerComponentRef = null;
    }

    if (this.spinnerElement) {
      this.hostElement.removeChild(this.spinnerElement);
      this.spinnerElement.remove();
      this.spinnerElement = null;
    }

    this.viewContainerRef.clear();
  }
}
