import { Injectable } from '@angular/core';

import { CookieOptions, CookieOptionsBuilder } from './cookie-options';
import { CookieRepository } from './repository';

@Injectable({ providedIn: 'root' })
export class CookieManager {
  constructor(private readonly cookieRepository: CookieRepository) {}

  public getItem(key: string): string | null {
    if (!key) {
      return null;
    }

    return (
      decodeURIComponent(
        this.cookieRepository.cookie.replace(
          new RegExp(
            '(?:(?:^|.*;)\\s*' + encodeURIComponent(key).replace(/[-.+*]/g, '\\$&') + '\\s*\\=\\s*([^;]*).*$)|^.*$',
          ),
          '$1',
        ),
      ) || null
    );
  }

  public setItem(key: string, value: string | number | boolean, options: CookieOptions): void {
    if (!key || /^(?:expires|max-age|path|domain|secure)$/i.test(key)) {
      return;
    }

    const cookieValue = `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;

    const cookieOptions = new CookieOptionsBuilder()
      .setDate('expires', options.expires)
      .setString('domain', options.domain)
      .setString('path', options.path)
      .setBoolean('secure', options.secure)
      .setNumber('maxAge', options.maxAge)
      .setString('SameSite', options.sameSite)
      .build();

    this.cookieRepository.cookie = [cookieValue, cookieOptions].filter(Boolean).join('; ');
  }

  public removeItem(key: string, options?: Omit<CookieOptions, 'expires'>): void {
    if (!this.hasItem(key)) {
      return;
    }

    this.setItem(key, '', {
      ...options,
      expires: 'Thu, 01 Jan 1970 00:00:00 GMT',
    });
  }

  public hasItem(key: string): boolean {
    if (!key || /^(?:expires|max-age|path|domain|secure)$/i.test(key)) {
      return false;
    }

    const regexp = new RegExp('(?:^|;\\s*)' + encodeURIComponent(key).replace(/[-.+*]/g, '\\$&') + '\\s*\\=');

    return regexp.test(this.cookieRepository.cookie);
  }

  public keys(): string[] {
    const keys = this.cookieRepository.cookie
      // eslint-disable-next-line no-useless-backreference
      .replace(/((?:^|\s*;)[^=]+)(?=;|$)|^\s*|\s*(?:=[^;]*)?(?:\1|$)/g, '')
      .split(/\s*(?:=[^;]*)?;\s*/);

    return keys.map((value) => decodeURIComponent(value));
  }
}
