export interface ScoringPassportDto {
  userId: string;
  scoringId: string;
  lastName: string | null;
  firstName: string | null;
  patronymic: string | null;
  birthdate: string | null;
  birthplace: string | null;
  series: string | null;
  number: string | null;
  issuingAuthority: string | null;
  issuedAt: string | null;
  subdivisionCode: string | null;
  registrationAddress: string | null;
  createdAt: Date;
  updatedAt: Date;
}
