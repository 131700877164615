import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';

@Injectable()
export class ResetScrollOnNavigationService {
  constructor(@Inject(PLATFORM_ID) private readonly platformId: string) {
    if (isPlatformBrowser(platformId)) {
      window.scrollTo({
        top: 0,
      });
    }
  }
}
