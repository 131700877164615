import { Injectable, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { NavigationHistoryService } from './navigation-history.service';

@Injectable()
export class NavigationBackService implements OnInit {
  protected prevHistoryUrl: string | null = null;

  constructor(
    private readonly navigationService: NavigationHistoryService,
    private readonly router: Router,
  ) {}

  public ngOnInit(): void {
    this.remember();
  }

  public remember(): void {
    this.prevHistoryUrl = this.navigationService.getPrevHistoryUrl();
  }

  public back(defaultRoute: string[] = ['/']): void {
    if (this.prevHistoryUrl) {
      this.router.navigateByUrl(this.prevHistoryUrl);
    } else {
      this.router.navigate(defaultRoute);
    }
  }

  public getPrevHistoryUrl(): string | null {
    return this.prevHistoryUrl;
  }
}
