import { FocusOrigin } from '@angular/cdk/a11y';
import { ENTER, hasModifierKey, SPACE } from '@angular/cdk/keycodes';
import {
  AfterViewChecked,
  booleanAttribute,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';

let _uniqueIdCounter = 0;

export class OptionSelectionChange<T = unknown> {
  constructor(
    public source: OptionComponent<T>,
    public isUserInput = false,
  ) {}
}

@Component({
  selector: 'app-option',
  exportAs: 'appOption',
  templateUrl: './option.component.html',
  styleUrls: ['./option.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: {
    role: 'option',
    '[class._selected]': 'selected',
    '[class._active]': 'active',
    '[class._disabled]': 'disabled',
    '[id]': 'id',
    '[attr.aria-selected]': 'selected',
    '[attr.aria-disabled]': 'disabled.toString()',
    '(click)': 'selectViaInteraction()',
    '(keydown)': 'handleKeydown($event)',
    class: 'option dropdown-item',
  },
})
export class OptionComponent<T = unknown> implements AfterViewChecked {
  @Input() value!: T;

  @Input() id = `option-${_uniqueIdCounter++}`;

  @Input({ transform: booleanAttribute }) disabled = false;

  @Input()
  public set viewValue(value: string | null) {
    this._viewValue = value;
  }

  public get viewValue(): string {
    return this._viewValue || (this.textRef?.nativeElement.textContent || '').trim();
  }

  @Output() readonly onSelectionChange = new EventEmitter<OptionSelectionChange<T>>();

  @Output() readonly onSelected = new EventEmitter<void>();

  @Output() readonly onDeselected = new EventEmitter<void>();

  @Output() readonly stateChanges = new EventEmitter<void>();

  public get selected(): boolean {
    return this._selected;
  }

  protected _selected = false;

  protected active = false;

  protected mostRecentViewValue = '';

  @ViewChild('text', { static: true }) protected textRef?: ElementRef<HTMLElement>;

  protected _viewValue: string | null = null;

  constructor(
    private readonly elementRef: ElementRef<HTMLElement>,
    public readonly changeDetectorRef: ChangeDetectorRef,
  ) {}

  public select(emitEvent = false): void {
    if (!this.selected) {
      this._selected = true;
      this.changeDetectorRef.markForCheck();

      if (emitEvent) {
        this.emitSelectionChangeEvent();
        this.onSelected.emit();
      }
    }
  }

  public deselect(emitEvent = false): void {
    if (this.selected) {
      this._selected = false;
      this.changeDetectorRef.markForCheck();

      if (emitEvent) {
        this.emitSelectionChangeEvent();
        this.onDeselected.emit();
      }
    }
  }

  public focus(_origin?: FocusOrigin, options?: FocusOptions): void {
    const element = this.getHostElement();

    if (typeof element.focus === 'function') {
      element.focus(options);
    }
  }

  public setActiveStyles(): void {
    if (!this.active) {
      this.active = true;
      this.changeDetectorRef.markForCheck();
    }
  }

  public setInactiveStyles(): void {
    if (this.active) {
      this.active = false;
      this.changeDetectorRef.markForCheck();
    }
  }

  public getLabel(): string {
    return this.viewValue;
  }

  public handleKeydown(event: KeyboardEvent): void {
    if ((event.keyCode === ENTER || event.keyCode === SPACE) && !hasModifierKey(event)) {
      this.selectViaInteraction();

      event.preventDefault();
    }
  }

  public selectViaInteraction(): void {
    if (!this.disabled) {
      this._selected = true;
      this.changeDetectorRef.markForCheck();
      this.emitSelectionChangeEvent(true);
      this.onSelected.emit();
    }
  }

  public getHostElement(): HTMLElement {
    return this.elementRef.nativeElement;
  }

  protected getTabIndex(): string {
    return this.disabled ? '-1' : '0';
  }

  public ngAfterViewChecked(): void {
    if (this.selected) {
      const viewValue = this.viewValue;

      if (viewValue !== this.mostRecentViewValue) {
        this.mostRecentViewValue = viewValue;
      }
    }
  }

  private emitSelectionChangeEvent(isUserInput = false): void {
    this.onSelectionChange.emit(new OptionSelectionChange<T>(this, isUserInput));
  }
}

export function getOptionScrollPosition(
  optionOffset: number,
  optionHeight: number,
  currentScrollPosition: number,
  panelHeight: number,
): number {
  if (optionOffset < currentScrollPosition) {
    return optionOffset;
  }

  if (optionOffset + optionHeight > currentScrollPosition + panelHeight) {
    return Math.max(0, optionOffset - panelHeight + optionHeight);
  }

  return currentScrollPosition;
}
