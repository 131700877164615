import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MediaPipesModule } from '@app/features/media/media-pipes/media-pipes.module';
import { SvgIconComponent } from 'angular-svg-icon';

import { UserAvatarComponent } from './user-avatar.component';

@NgModule({
  declarations: [UserAvatarComponent],
  imports: [CommonModule, SvgIconComponent, MediaPipesModule],
  exports: [UserAvatarComponent],
})
export class UserAvatarModule {}
