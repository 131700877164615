import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';

import { CookieRepository } from './cookie.repository';

@Injectable({ providedIn: 'root' })
export class CookieRepositoryBrowser implements CookieRepository {
  public get cookie(): string {
    return this.document.cookie;
  }

  public set cookie(value: string) {
    this.document.cookie = value;
  }

  constructor(
    @Inject(PLATFORM_ID) private readonly platformId: string,
    @Inject(DOCUMENT) private readonly document: Document,
  ) {
    if (!isPlatformBrowser(platformId)) {
      console.warn(`CookieRepositoryBrowser incorrect platform`);
    }
  }
}
