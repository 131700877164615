import { FactoryOpts } from 'imask';

export const PHONE_IMASK: FactoryOpts = {
  mask: '{+7} 000 000 00 00',
  prepare: (appended: string, masked: { unmaskedValue: string; value: string }): string => {
    const cleanedValue = appended.replace(/\D/, '');

    if (cleanedValue.length === 11 && cleanedValue.startsWith('8')) {
      return cleanedValue.substring(1);
    }

    if (appended === '8' && !masked.unmaskedValue) {
      return '+7';
    }

    return appended;
  },
};

export const PHONE_REGEXP = /^\+7(\d){10}$/;
