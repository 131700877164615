import { booleanAttribute, ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'button[app-tab-button]',
  templateUrl: './tab-button.component.html',
  styleUrls: ['./tab-button.component.scss'],
  exportAs: 'appTabButton',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'app-tab-button',
  },
})
export class TabButtonComponent {}

@Component({
  selector: 'a[app-tab-button]',
  templateUrl: './tab-button.component.html',
  styleUrls: ['./tab-button.component.scss'],
  exportAs: 'appTabButton',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'app-tab-button',
    '[class._disabled]': 'disabled',
  },
})
export class TabButtonAnchorComponent {
  @Input({ transform: booleanAttribute }) disabled: boolean | null = null;
}
