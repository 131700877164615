import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { AppNotificationDto, NotificationAuthorTypeEnum } from '@app/core/api/notifications';
import { NotificationsService } from '@app/core/notifications/notifications.service';
import { AppHostUrlProvider } from '@app/core/tokens';
import { SYSTEM_USER_AVATAR_FILE, SYSTEM_USER_NAME } from '@app/shared/consts';

@Component({
  selector: 'app-notification-preview-item',
  templateUrl: './notification-preview-item.component.html',
  styleUrls: ['./notification-preview-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationPreviewItemComponent {
  @Input({ required: true }) notification!: AppNotificationDto;

  systemAvatar = SYSTEM_USER_AVATAR_FILE;

  systemName = SYSTEM_USER_NAME;

  authorTypeEnum = NotificationAuthorTypeEnum;

  constructor(
    private readonly notificationsService: NotificationsService,
    private readonly hostUrlProvider: AppHostUrlProvider,
    private readonly router: Router,
  ) {}

  protected handleNotificationClick(): void {
    this.read();

    if (this.notification.link) {
      if (this.hostUrlProvider.isHostUrl(this.notification.link)) {
        const url = this.hostUrlProvider.fullHostUrlToRelative(this.notification.link);
        this.router.navigateByUrl(url);
      } else {
        window.open(this.notification.link, '_target');
      }
    }
  }

  protected read(): void {
    if (!this.notification.isSeen) {
      this.notificationsService.read([this.notification.notificationId]);
    }
  }
}
