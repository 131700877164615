export interface ApiMetaBaseResponseDto {
  success: boolean;
}

export interface ApiMetaSuccessResponseDto extends ApiMetaBaseResponseDto {
  success: true;
}

export interface ApiMetaErrorResponseDto extends ApiMetaBaseResponseDto {
  success: false;
  code: string;
  status: number;
  message: string;
  requestId?: string;
}

export type ApiMetaResponseDto = ApiMetaSuccessResponseDto | ApiMetaErrorResponseDto;

export interface ApiResponseDto<T = unknown, M = ApiMetaResponseDto> {
  data: T;
  meta: M;
}
