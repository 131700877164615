import { APP_INITIALIZER, ModuleWithProviders, NgModule } from '@angular/core';

import { AnalyticsService } from './analytics.service';

@NgModule({})
export class AnalyticsModule {
  public static forRoot(): ModuleWithProviders<AnalyticsModule> {
    return {
      ngModule: AnalyticsModule,
      providers: [
        {
          provide: APP_INITIALIZER,
          useFactory: (service: AnalyticsService) => () => service.init(),
          deps: [AnalyticsService],
          multi: true,
        },
      ],
    };
  }
}
