export enum ProfileApiExceptionCodeEnum {
  NotFound = 'profile.NotFound',
  AvatarNotFound = 'profile.AvatarNotFound',
  MemberNotFound = 'profile.BusinessMemberNotFound',
  FileNotFound = 'profile.FileNotFound',
  FileAlreadyExists = 'profile.FileAlreadyExists',
  CityNotFound = 'profile.CityNotFound',
  CannotSubscribeToSelf = 'profile.CannotSubscribeToSelf',
  SlugConflict = 'profile.SlugConflict',
}
