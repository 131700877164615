import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MediaResizedImagePipe } from './media-resized-image.pipe';

@NgModule({
  declarations: [MediaResizedImagePipe],
  exports: [MediaResizedImagePipe],
  imports: [CommonModule],
})
export class MediaPipesModule {}
