/* eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */
import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { Platform } from '@angular/cdk/platform';
import { AutofillMonitor } from '@angular/cdk/text-field';
import {
  AfterViewInit,
  booleanAttribute,
  Directive,
  DoCheck,
  ElementRef,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  Optional,
  Self,
} from '@angular/core';
import { FormGroupDirective, NgControl, NgForm, Validators } from '@angular/forms';
import { ErrorStateMatcher, ErrorStateTracker, FormFieldControl } from '@app/shared/components/forms/form-shared';
import { DestroyService } from '@app/shared/utils';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

let uniqueId = 0;

@Directive({
  selector: 'input[appInputTextControl], textarea[appInputTextControl]',
  exportAs: 'appInputText',
  host: {
    class: 'input-control',
    '[id]': 'id',
    '[disabled]': 'disabled',
    '[required]': 'required',
    '[attr.name]': 'name || null',
    '[attr.readonly]': 'readonly || null',
    '[attr.aria-invalid]': '(empty && required) ? null : errorState',
    '[attr.aria-required]': 'required',
    '[attr.id]': 'id',
    '(focus)': 'handleFocusChanged(true)',
    '(blur)': 'handleFocusChanged(false)',
    '(input)': 'handleInputStub()',
  },
  providers: [{ provide: FormFieldControl, useExisting: InputTextControlDirective }, DestroyService],
})
export class InputTextControlDirective implements FormFieldControl<any>, AfterViewInit, OnChanges, OnDestroy, DoCheck {
  @Input()
  public get id(): string {
    return this._id;
  }

  public set id(value: string | null) {
    this._id = value || this.uid;
  }

  @Input()
  public get disabled(): boolean {
    return this._disabled;
  }

  public set disabled(value: BooleanInput) {
    this._disabled = coerceBooleanProperty(value);

    if (this.focused) {
      this.focused = false;
      this.stateChanges.next();
    }
  }

  @Input() placeholder?: string;

  @Input() name?: string;

  @Input({ transform: booleanAttribute })
  public get required(): boolean {
    return this._required ?? this.ngControl?.control?.hasValidator(Validators.required) ?? false;
  }

  public set required(value: boolean) {
    this._required = value;
  }

  @Input()
  public get errorStateMatcher(): ErrorStateMatcher | null {
    return this.errorStateTracker.matcher;
  }

  public set errorStateMatcher(value: ErrorStateMatcher) {
    this.errorStateTracker.matcher = value;
  }

  @Input('aria-describedby') userAriaDescribedBy?: string;

  @Input() public get readonly(): boolean {
    return this._readonly;
  }

  public set readonly(value: BooleanInput) {
    this._readonly = coerceBooleanProperty(value);
  }

  @Input()
  public get value(): string {
    return this.inputValueAccessor.value;
  }

  public set value(value: any) {
    if (value !== this.value) {
      this.inputValueAccessor.value = value;
      this.stateChanges.next();
    }
  }

  public get errorState(): boolean {
    return this.errorStateTracker.errorState;
  }

  public set errorState(value: boolean) {
    this.errorStateTracker.errorState = value;
  }

  controlType = 'input-text';

  public get empty(): boolean {
    return !this.elementRef.nativeElement.value && !this.isBadInput() && !this.autofilled;
  }

  focused = false;

  public get shouldLabelFloat(): boolean {
    return this.focused || !this.empty;
  }

  readonly stateChanges = new Subject<void>();

  protected uid = `app-input-text-control_${++uniqueId}`;

  protected _id = this.uid;

  protected _disabled = false;

  protected _readonly = false;

  protected _required?: boolean;

  protected previousNativeValue: any;

  protected isTextarea = false;

  protected autofilled = false;

  private readonly errorStateTracker: ErrorStateTracker;

  private readonly inputValueAccessor: { value: string };

  private previousPlaceholder: string | null = null;

  // eslint-disable-next-line max-params
  constructor(
    protected elementRef: ElementRef<HTMLInputElement | HTMLTextAreaElement>,
    private readonly platform: Platform,
    @Optional() @Self() public ngControl: NgControl | null,
    @Optional() parentForm: NgForm,
    @Optional() parentFormGroup: FormGroupDirective,
    defaultErrorStateMatcher: ErrorStateMatcher,
    ngZone: NgZone,
    private autofillMonitor: AutofillMonitor,
    @Self() private readonly destroy$: DestroyService,
  ) {
    const element = this.elementRef.nativeElement;
    const nodeName = element.nodeName.toLowerCase();

    this.inputValueAccessor = element;

    this.previousNativeValue = this.value;

    if (platform.IOS) {
      ngZone.runOutsideAngular(() => {
        elementRef.nativeElement.addEventListener('keyup', this._iOSKeyupListener);
      });
    }

    this.errorStateTracker = new ErrorStateTracker(
      defaultErrorStateMatcher,
      ngControl,
      parentFormGroup,
      parentForm,
      this.stateChanges,
    );

    this.isTextarea = nodeName === 'textarea';
  }

  public ngAfterViewInit(): void {
    if (this.platform.isBrowser) {
      this.autofillMonitor
        .monitor(this.elementRef.nativeElement)
        .pipe(takeUntil(this.destroy$))
        .subscribe((event) => {
          this.autofilled = event.isAutofilled;
          this.stateChanges.next();
        });
    }
  }

  public ngOnChanges(): void {
    this.stateChanges.next();
  }

  public ngOnDestroy(): void {
    this.stateChanges.complete();

    if (this.platform.isBrowser) {
      this.autofillMonitor.stopMonitoring(this.elementRef.nativeElement);
    }

    if (this.platform.IOS) {
      this.elementRef.nativeElement.removeEventListener('keyup', this._iOSKeyupListener);
    }
  }

  public ngDoCheck(): void {
    if (this.ngControl) {
      this.updateErrorState();

      if (this.ngControl.disabled !== null && this.ngControl.disabled !== this.disabled) {
        this.disabled = this.ngControl.disabled;
        this.stateChanges.next();
      }
    }

    this.dirtyCheckNativeValue();
    this.dirtyCheckPlaceholder();
  }

  public onContainerClick(): void {
    if (!this.focused) {
      this.focus();
    }
  }

  public onContainerKeydown(): void {
    if (!this.focused) {
      this.focus();
    }
  }

  public setDescribedByIds(ids: string[]): void {
    if (ids.length) {
      this.elementRef.nativeElement.setAttribute('aria-describedby', ids.join(' '));
    } else {
      this.elementRef.nativeElement.removeAttribute('aria-describedby');
    }
  }

  protected focus(options?: FocusOptions): void {
    this.elementRef.nativeElement.focus(options);
  }

  protected updateErrorState(): void {
    this.errorStateTracker.updateErrorState();
  }

  protected handleFocusChanged(isFocused: boolean): void {
    if (isFocused !== this.focused) {
      this.focused = isFocused;
      this.stateChanges.next();
    }
  }

  protected handleInputStub(): void {}

  protected isBadInput(): boolean {
    const validity = (this.elementRef.nativeElement as HTMLInputElement).validity;
    return validity && validity.badInput;
  }

  protected dirtyCheckNativeValue(): void {
    const newValue = this.elementRef.nativeElement.value;

    if (this.previousNativeValue !== newValue) {
      this.previousNativeValue = newValue;
      this.stateChanges.next();
    }
  }

  private dirtyCheckPlaceholder(): void {
    const placeholder = this.placeholder || null;

    if (placeholder !== this.previousPlaceholder) {
      const element = this.elementRef.nativeElement;
      this.previousPlaceholder = placeholder;

      if (placeholder) {
        element.setAttribute('placeholder', placeholder);
      } else {
        element.removeAttribute('placeholder');
      }
    }
  }

  private _iOSKeyupListener = (event: Event): void => {
    const el = event.target as HTMLInputElement;

    // Note: We specifically check for 0, rather than `!el.selectionStart`, because the two
    // indicate different things. If the value is 0, it means that the caret is at the start
    // of the input, whereas a value of `null` means that the input doesn't support
    // manipulating the selection range. Inputs that don't support setting the selection range
    // will throw an error so we want to avoid calling `setSelectionRange` on them. See:
    // https://html.spec.whatwg.org/multipage/input.html#do-not-apply
    if (!el.value && el.selectionStart === 0 && el.selectionEnd === 0) {
      // Note: Just setting `0, 0` doesn't fix the issue. Setting
      // `1, 1` fixes it for the first time that you type text and
      // then hold delete. Toggling to `1, 1` and then back to
      // `0, 0` seems to completely fix it.
      el.setSelectionRange(1, 1);
      el.setSelectionRange(0, 0);
    }
  };
}
