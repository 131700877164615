import { CdkOverlayOrigin, ConnectedPosition, ViewportRuler } from '@angular/cdk/overlay';
import { isPlatformBrowser } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  PLATFORM_ID,
  Self,
} from '@angular/core';
import { AuthProfileModel } from '@app/core/auth';
import { MediaObserverService } from '@app/core/media-observer';
import { DestroyService } from '@app/shared/utils';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-header-profile',
  templateUrl: './header-profile.component.html',
  styleUrls: ['./header-profile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DestroyService],
})
export class HeaderProfileComponent implements OnInit {
  @Input({ required: true }) profile!: AuthProfileModel;

  @Input({ required: true }) cdkOverlayOrigin!: CdkOverlayOrigin;

  @Output() logoutSelected = new EventEmitter<void>();

  positions: ConnectedPosition[] = [
    {
      originX: 'end',
      originY: 'bottom',
      overlayX: 'end',
      overlayY: 'top',
    },
  ];

  protected panelOpen = false;

  protected overlayWidth?: string;

  constructor(
    private readonly viewportRuler: ViewportRuler,
    private readonly elementRef: ElementRef<HTMLElement>,
    @Inject(PLATFORM_ID) private readonly platformId: string,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly mediaObserver: MediaObserverService,
    @Self() private readonly destroy$: DestroyService,
  ) {}

  public ngOnInit(): void {
    this.viewportRuler
      .change()
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        if (this.panelOpen) {
          this.overlayWidth = this.getOverlayWidth();
          this.changeDetectorRef.detectChanges();
        }
      });
  }

  public toggle(): void {
    if (this.panelOpen) {
      this.close();
    } else {
      this.open();
    }
  }

  public open(): void {
    this.overlayWidth = this.getOverlayWidth();

    this.panelOpen = true;
    this.changeDetectorRef.markForCheck();
  }

  public close(): void {
    if (this.panelOpen) {
      this.panelOpen = false;
      this.changeDetectorRef.markForCheck();
    }
  }

  protected getOverlayWidth(): string {
    if (!isPlatformBrowser(this.platformId)) {
      return 'auto';
    }

    if (this.mediaObserver.isMobile) {
      return '100vw';
    }

    const element = this.elementRef.nativeElement;
    const rect = element.getBoundingClientRect();
    const width = window.innerWidth;

    const overlayWidth = width - rect.left;
    return `${overlayWidth}px`;
  }
}
