import { AbstractControl, FormGroupDirective, NgControl, NgForm } from '@angular/forms';
import { Subject } from 'rxjs';

import { ErrorStateMatcher } from './error-state.matcher';

export interface CanUpdateErrorState {
  updateErrorState(): void;
  errorState: boolean;
  errorStateMatcher: ErrorStateMatcher | null;
}

export interface HasErrorState {
  parentFormGroup: FormGroupDirective;
  parentForm: NgForm;
  defaultErrorStateMatcher: ErrorStateMatcher;

  ngControl: NgControl;
  stateChanges: Subject<void>;
}

export class ErrorStateTracker {
  errorState = false;

  matcher: ErrorStateMatcher | null = null;

  constructor(
    private defaultMatcher: ErrorStateMatcher | null,
    public ngControl: NgControl | null,
    private parentFormGroup: FormGroupDirective | null,
    private parentForm: NgForm | null,
    private stateChanges: Subject<void>,
  ) {}

  public updateErrorState(): void {
    const oldState = this.errorState;
    const parent = this.parentFormGroup || this.parentForm;
    const matcher = this.matcher || this.defaultMatcher;
    const control = this.ngControl ? (this.ngControl.control as AbstractControl) : null;
    const newState = typeof matcher?.isErrorState === 'function' ? matcher.isErrorState(control, parent) : false;

    if (newState !== oldState) {
      this.errorState = newState;
      this.stateChanges.next();
    }
  }
}
