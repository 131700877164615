import { booleanAttribute, ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FormOnChangeType, FormOnTouchedType } from '@app/shared/components/forms/types';

type VerticalAlign = 'center' | 'top';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
  host: {
    class: 'app-checkbox',
    '[class._checked]': 'checked',
    '[class._disabled]': 'disabled',
    '[class._indeterminate]': 'indeterminate',
  },
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: CheckboxComponent,
      multi: true,
    },
  ],
})
export class CheckboxComponent implements ControlValueAccessor {
  @Input() id = CheckboxComponent.getId();

  @Input({ transform: booleanAttribute }) checked = false;

  @Input({ transform: booleanAttribute }) indeterminate = false;

  @Input({ transform: booleanAttribute }) disabled = false;

  @Input() verticalAlign: VerticalAlign = 'center';

  @Output() changed = new EventEmitter<boolean>();

  protected accessorOnChange: FormOnChangeType<boolean> = () => {};

  protected accessorOnTouched: FormOnTouchedType = () => {};

  static checkboxIndex = 0;

  public static getId(): string {
    return `checkbox_${CheckboxComponent.checkboxIndex++}`;
  }

  public stopPropagation(event: Event): void {
    event.stopPropagation();
  }

  public change(event: Event): void {
    const input = event.currentTarget as HTMLInputElement;
    this.changed.emit(input.checked);
    this.accessorOnChange(input.checked);
    this.accessorOnTouched();
  }

  public registerOnChange(fn: FormOnChangeType<boolean>): void {
    this.accessorOnChange = fn;
  }

  public registerOnTouched(fn: FormOnTouchedType): void {
    this.accessorOnTouched = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  public writeValue(obj: unknown): void {
    this.checked = Boolean(obj);
  }
}
