import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { FormFieldErrorDirective } from './form-field-error.directive';
import { FormFieldHintDirective } from './form-field-hint.directive';
import { FormFieldLabelDirective } from './form-field-label.directive';
import { FormFieldPrefixDirective } from './form-field-prefix.directive';
import { FormFieldSuffixDirective } from './form-field-suffix.directive';

@NgModule({
  declarations: [
    FormFieldPrefixDirective,
    FormFieldSuffixDirective,
    FormFieldErrorDirective,
    FormFieldLabelDirective,
    FormFieldHintDirective,
  ],
  imports: [CommonModule],
  exports: [
    FormFieldPrefixDirective,
    FormFieldSuffixDirective,
    FormFieldErrorDirective,
    FormFieldLabelDirective,
    FormFieldHintDirective,
  ],
})
export class FormSharedModule {}
