export interface FooterParams {
  hidden: boolean;
  container: boolean;
}

export const FOOTER_DEFAULT_PARAMS: FooterParams = {
  hidden: false,
  container: false,
};

export function mergeFooterParams<T extends Partial<FooterParams>>(parent: T, current: Partial<FooterParams>): T {
  return Object.assign(parent, current);
}

export function provideFooterData(data: Partial<FooterParams>): Record<string, unknown> {
  return { footer: data };
}
