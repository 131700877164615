import { ChangeDetectorRef, Directive, Input, OnInit, Self, TemplateRef, ViewContainerRef } from '@angular/core';
import { MediaObserverService } from '@app/core/media-observer/media-observer.service';
import { DestroyService } from '@app/shared/utils';
import { map } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Directive({
  selector: '[appMediaShowBetween]',
  providers: [DestroyService],
})
export class MediaShowBetweenDirective implements OnInit {
  @Input({ required: true }) breakpointFrom!: string;

  @Input({ required: true }) breakpointTo!: string;

  @Input('appMediaShowBetweenIncludeEquals') includeEquals = true;

  protected hasView = false;

  constructor(
    private readonly templateRef: TemplateRef<never>,
    private readonly viewContainer: ViewContainerRef,
    private readonly mediaObserver: MediaObserverService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    @Self() private readonly destroy$: DestroyService,
  ) {}

  public ngOnInit(): void {
    this.mediaObserver.breakpoint$
      .pipe(
        map(
          (breakpoint) =>
            this.mediaObserver.isBreakpointUp(breakpoint, this.breakpointFrom, this.includeEquals) &&
            this.mediaObserver.isBreakpointDown(breakpoint, this.breakpointTo, this.includeEquals),
        ),
        takeUntil(this.destroy$),
      )
      .subscribe((shouldShow) => {
        if (shouldShow && !this.hasView) {
          this.viewContainer.createEmbeddedView(this.templateRef);
          this.hasView = true;
          this.changeDetectorRef.markForCheck();
        } else if (!shouldShow && this.hasView) {
          this.viewContainer.clear();
          this.hasView = false;
          this.changeDetectorRef.markForCheck();
        }
      });
  }
}
