import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LetDirective } from '@app/shared/directives/let/let.directive';

@NgModule({
  declarations: [LetDirective],
  imports: [CommonModule],
  exports: [LetDirective],
})
export class LetModule {}
