import { Directive } from '@angular/core';

@Directive({
  selector: '[appFormFieldPrefix], app-form-field-prefix',
  exportAs: 'appFormFieldPrefix',
  host: {
    class: 'form-field-prefix',
  },
})
export class FormFieldPrefixDirective {}
