import { APP_INITIALIZER, ModuleWithProviders, NgModule, Provider } from '@angular/core';
import { NgxsModule } from '@ngxs/store';

import { NotificationsService } from './notifications.service';
import { NotificationsState } from './states/notifications.state';

@NgModule({
  imports: [NgxsModule.forFeature([NotificationsState])],
})
export class NotificationsMessagingModule {
  public static forRoot(options: { messagingProvider: Provider }): ModuleWithProviders<NotificationsMessagingModule> {
    return {
      ngModule: NotificationsMessagingModule,
      providers: [
        options.messagingProvider,
        {
          provide: APP_INITIALIZER,
          useFactory: (service: NotificationsService) => () => service.init(),
          deps: [NotificationsService],
          multi: true,
        },
      ],
    };
  }
}
