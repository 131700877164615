import { FileIdDto } from '@app/core/api/file';

import { CityIdDto } from '../../catalog';
import { GoalContentDto, GoalStatusEnum } from './goal.dto';

export interface GoalContentUpdateDto extends Partial<Omit<GoalContentDto, 'mainImage'>> {
  mainImage?: FileIdDto | null;
}

export interface GoalCreateRequestDto {
  gallery?: FileIdDto[];
  title?: string | null;
  subtitle?: string | null;
  goalAmount?: number | null;
  activeUntil?: string | null;
  status?: GoalStatusEnum;
  content?: GoalContentUpdateDto;
  city?: CityIdDto | null;
  scoringId?: string | null;
  catalogImage?: FileIdDto | null;
}
