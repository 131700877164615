<div class="header-mobile-menu" [class._opened]="panelOpen">
  <a
    class="header-mobile-menu__avatar"
    [routerLink]="['/', 'profile']"
    appUserAvatar
    [avatar]="profile?.avatar"
    (click)="$event.stopPropagation()"
  ></a>
  <button type="button" app-icon-button class="header-mobile-menu__trigger" (click)="toggle()">
    <svg-icon src="burger"></svg-icon>
    <div *ngIf="unreadCount$ | async" class="notifications-badge"></div>
  </button>
</div>

<div *ngIf="panelOpen" class="header-mobile-menu-panel" role="menu">
  <div class="header-mobile-menu-panel__group">
    <a
      class="header-mobile-menu-panel__item"
      role="menuitem"
      *ngFor="let item of menuItems"
      [routerLink]="item.route"
      (click)="close()"
    >
      {{ item.title }}
    </a>
  </div>
  <div class="header-mobile-menu-panel__group">
    <a
      *ngIf="profile"
      class="header-mobile-menu-panel__item"
      role="menuitem"
      [routerLink]="['/profile']"
      (click)="close()"
    >
      Профиль
    </a>
    <a
      *ngIf="!profile"
      class="header-mobile-menu-panel__item"
      role="menuitem"
      [routerLink]="['/auth']"
      (click)="close()"
    >
      Вход | Регистрация
    </a>
    <a
      *ngIf="profile"
      class="header-mobile-menu-panel__item"
      role="menuitem"
      [routerLink]="['/finance']"
      (click)="close()"
    >
      Финансы
    </a>
    <a
      *ngIf="!hideCreateGoalButton"
      class="header-mobile-menu-panel__item"
      role="menuitem"
      [routerLink]="['/goal', 'wizard']"
      (click)="close()"
    >
      Создать цель
    </a>
    <a
      *ngIf="profile"
      class="header-mobile-menu-panel__item"
      role="menuitem"
      [routerLink]="['/notifications']"
      (click)="close()"
    >
      <span>Уведомления</span>
      <span class="header-mobile-menu-unread-badge" *ngIf="unreadCount$ | async as unreadCount">{{
        unreadCount > 99 ? 99 : unreadCount
      }}</span>
    </a>
    <a
      *ngIf="profile"
      class="header-mobile-menu-panel__item"
      role="menuitem"
      [routerLink]="['/settings']"
      (click)="close()"
    >
      Настройки
    </a>
  </div>
  <div class="header-mobile-menu-panel__group">
    <button
      *ngIf="profile"
      class="header-mobile-menu-panel__item _muted"
      role="menuitem"
      (click)="logoutSelected.emit(); close()"
    >
      Выход
    </button>
  </div>
</div>
