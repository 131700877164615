import { inject, Injectable } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from '@app/core/auth';
import { ProfileWizardProgressService } from '@app/features/profile/services/profile-wizard-progress.service';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
class ProfileCompetedGuard {
  constructor(
    private readonly authService: AuthService,
    private readonly router: Router,
    private readonly profileWizardProgressService: ProfileWizardProgressService,
  ) {}

  public checkCompleted(): Observable<boolean> {
    return this.profileWizardProgressService.profileCompleted$.pipe(
      map((completed) => {
        if (!completed) {
          this.router.navigate(['/profile', 'wizard']);
        }

        return completed;
      }),
    );
  }
}

export const canActivateProfileCompleted: CanActivateFn = () => inject(ProfileCompetedGuard).checkCompleted();
