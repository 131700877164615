import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, Self, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SupportApiService } from '@app/core/api/support';
import { LoaderService } from '@app/core/loader';
import { snackBarApiErrorFallback, SnackBarService } from '@app/shared/components/snack-bar';
import { DOC_URLS, EMAIL_REGEXP, PHONE_IMASK, PHONE_VALIDATION_REGEXP } from '@app/shared/consts';
import { DestroyService } from '@app/shared/utils';
import { BehaviorSubject, catchError, EMPTY, tap, throwError } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-landing-feedback-section',
  templateUrl: './landing-feedback-section.component.html',
  styleUrls: ['./landing-feedback-section.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [DestroyService],
})
export class LandingFeedbackSectionComponent {
  form = new FormGroup({
    name: new FormControl<string>('', [Validators.required]),
    phone: new FormControl<string>('', [Validators.pattern(PHONE_VALIDATION_REGEXP)]),
    email: new FormControl<string>('', [Validators.required, Validators.email, Validators.pattern(EMAIL_REGEXP)]),
    comment: new FormControl<string | null>(null, [Validators.required]),
    attachments: new FormControl<File[]>([]),
    policy: new FormControl<boolean>(false, [Validators.requiredTrue]),
  });

  phoneMask = PHONE_IMASK;

  sent$ = new BehaviorSubject<boolean>(false);

  docUrls = DOC_URLS;

  constructor(
    protected readonly supportApi: SupportApiService,
    protected readonly loaderService: LoaderService,
    protected readonly snackBar: SnackBarService,
    @Self() protected readonly destroy$: DestroyService,
  ) {}

  public submit(): void {
    if (this.form.valid) {
      const value = this.form.value;

      const source$ = this.supportApi.sendFeedback({
        email: value.email || '',
        clientName: value.name || '',
        clientPhone: value.phone,
        message: value.comment || '',
        files: value.attachments || [],
      });

      this.loaderService
        .doLoading(source$, this, 'SEND_REQUEST')
        .pipe(
          tap(() => this.sent$.next(true)),
          catchError((error) => {
            if (
              error instanceof HttpErrorResponse &&
              error.status === HttpStatusCode.PayloadTooLarge &&
              value.attachments?.length
            ) {
              this.form.controls.attachments.setErrors({
                payloadTooLarge: true,
              });

              return EMPTY;
            }

            return throwError(error);
          }),
          snackBarApiErrorFallback(this.snackBar, 'Ошибка при отправке'),
          takeUntil(this.destroy$),
        )
        .subscribe();
    } else {
      this.form.markAllAsTouched();
      this.form.updateValueAndValidity();
    }
  }

  public handleAttachmentInput(event: Event): void {
    const input = event.currentTarget as HTMLInputElement;
    this.addAttachments(input.files);
    input.value = '';
  }

  public addAttachments(fileList?: FileList | null): void {
    if (!fileList || !fileList.length) {
      return;
    }

    const value = [...(this.form.controls.attachments.value || []), ...Array.from(fileList)];
    this.form.controls.attachments.patchValue(value);
  }

  public removeAttachment(index: number): void {
    const files = this.form.controls.attachments.value || [];
    files?.splice(index, 1);
    this.form.controls.attachments.patchValue(files);
  }
}
