import { Directive } from '@angular/core';

@Directive({
  selector: '[appFormFieldSuffix], app-form-field-suffix',
  exportAs: 'appFormFieldSuffix',
  host: {
    class: 'form-field-suffix',
  },
})
export class FormFieldSuffixDirective {}
